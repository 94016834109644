import React from 'react';
import SimpleBar from 'simplebar-react';
import { color } from '#theme';
import { rgba } from 'polished';

const maskHeight = 16;
const mask = `linear-gradient(
  to bottom,
  ${rgba(color.black, 0)} 0,
  ${rgba(color.black, 1)} ${maskHeight}px,
  ${rgba(color.black, 1)} calc(100% - ${maskHeight}px),
  ${rgba(color.black, 0)} 100%
)`;
export const Scrollbars = ({
  children,
  style,
  hasMask = false,
  ...restProps
}) => {
  const styles = {
    height: '100%',
    width: '100%',
    ...style,
  };
  const stylesWithMasks = {
    ...styles,
    maskImage: mask,
    WebkitMaskImage: mask,
  };
  return (
    <SimpleBar
      style={!hasMask ? styles : stylesWithMasks}
      {...restProps}
    >
      {children}
    </SimpleBar>
  );
};
