import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link, withRouter } from 'react-router-dom';

import { color, defTransition, bp } from '#theme';

const List = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color.lightestGrey};
  border-radius: 32px;
  padding: 4px;
  font-weight: bold;
`;

const StyledLink = ({ title, pageId, className }) => (
  <Link to={"/" + pageId} className={className}>
    {title}
  </Link>
);

const NavLink = styled(StyledLink)`
  text-decoration: none;
  color: ${props => props.isActive ? color.red : color.black};
  padding: 0 8px;
  height: 24px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  font-size: 0.75rem;
  overflow: hidden;
  transition: color ${defTransition};
  @media ${bp.m} {
    padding: 0 16px;
    height: 32px;
    font-size: 0.875rem;
  }
  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${color.white};
    border-radius: 32px;
    transition: transform ${defTransition};
    transform: translateX(${({ isActive, isNext }) => isActive
      ? 0
      : `calc(${isNext ? '100% + ' : '-100% - '}1px)`
    });
  }
`;

NavLink.propTypes = {
  pageId: PropTypes.string,
  title: PropTypes.string,
};

const NavLinkOuterPropTypes = {
  pageId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

NavLink.propTypes = {
  ...NavLinkOuterPropTypes,
  isActive: PropTypes.bool.isRequired,
};

const getActiveNavIndex = (nav, pageId) => nav.findIndex(navItem => navItem.pageId === pageId);

class NavComponent extends React.Component {
  constructor(props) {
    super(props);
    const { pageId } = props.match && (props.match.params || props.nav[0].pageId);
    this.state = {
      pageId: pageId,
      activeNavIndex: getActiveNavIndex(props.nav, pageId),
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (!props.match || !props.match.params || !props.match.params.pageId) {
      return null;
    }
    const { pageId } = props.match.params;
    const activeNavIndex = getActiveNavIndex(props.nav, pageId);
    if (activeNavIndex === undefined) {
      return null;
    }
    if (pageId !== state.pageId) {
      return {
        pageId: pageId,
        activeNavIndex: activeNavIndex,
      };
    }
    return null;
  }
  renderNav = (item, key) => {
    const { activeNavIndex } = this.state;
    return (
      <NavLink
        key={key}
        {...item}
        isActive={activeNavIndex === key}
        isNext={activeNavIndex > key}
      />
    );
  }
  render () {
    const { nav } = this.props;
    return (
      <List>
        {nav.map(this.renderNav)}
      </List>
    );
  };
}

NavComponent.propTypes = {
  nav: PropTypes.arrayOf(PropTypes.shape(NavLinkOuterPropTypes)).isRequired,
};

export const Nav = withRouter(NavComponent);
