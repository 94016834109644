import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import useComponentSize from '@rehooks/component-size';

import styled, {  } from 'styled-components/macro';
import { clearListItem, clearList, SvgArrow, color, xz, defTransition, clearFix, bp } from '#theme';
import { H3, Grid, Col, Wrapper, Headline } from '#ui';
import { contextThemeTypes, ThemeProvider, contextLevelTypes } from '#helpers';
import { sectionWrapper } from '../../hocs/sectionWrapper';

const Container = styled(Wrapper)`
`;

const List = styled.ul`
  ${clearList}
  border-bottom: 1px solid ${color.lightGrey};
`;
const Item = styled.li`
  ${clearListItem}
  border-top: 1px solid ${color.lightGrey};
`;
const StyledHead = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${xz(3)} 0;
`;
const Icon = styled(SvgArrow)`
  display: block;
  width: 16px;
  height: 16px;
  fill: ${color.middleGrey};
  transition: fill ${defTransition};
  ${StyledHead}:hover & {
    fill: ${color.red};
  }
  @media ${bp.p} {
    width: 20px;
    height: 20px;
  }
  @media ${bp.d} {
    width: 24px;
    height: 24px;
  }
`;
const IconContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  transition: transform ${defTransition};
  ${({ isOpened }) => isOpened && `
    transform: rotate(90deg);
  `}
  @media ${bp.m} {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media ${bp.p} {
    padding: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const Label = styled.div`
  color: ${color.textGrey};
  flex: 1 0 0%;
  transition: color ${defTransition};
  ${StyledHead}:hover & {
    color: ${color.red};
  }
`;
const Body = styled.div`
  height: ${({ height, isOpened}) => isOpened ? `${height}px` : 0};
  overflow: hidden;
  transition: height ${defTransition};
`;
const Content = styled.div`
  padding-bottom: ${xz(3)};
  ${clearFix}
`;

const AccordionListItemPropsExternal = {
  label: PropTypes.string.isRequired,
  components: PropTypes.any.isRequired,
}
const AccordionListItemPropsInternal = {
  isOpened: PropTypes.bool.isRequired,
  handleSetIndex: PropTypes.func.isRequired,
  itemKey: PropTypes.number.isRequired,
};

const Head = ({ children, isOpened, onClick }) => {
  const isPhablet = useMediaQuery({ query: bp.p });
  const mobile = (
    <React.Fragment>
      <IconContainer isOpened={isOpened}>
        <Icon />
      </IconContainer>
      <Label>
        <H3 className="textGrey">{children}</H3>
      </Label>
    </React.Fragment>
  );
  const phablet = (
    <Grid columns={12}>
      <Col>
        <IconContainer isOpened={isOpened}>
          <Icon />
        </IconContainer>
      </Col>
      <Col width={11}>
        <Label><H3 className="textGrey">{children}</H3></Label>
      </Col>
    </Grid>
  );
  return (
    <StyledHead onClick={onClick}>
      {!isPhablet ? mobile : phablet}
    </StyledHead>
  );
};

const ListItem = ({
  label,
  components,
  isOpened,
  handleSetIndex,
  itemKey,
  bg,
}) => {
  const handleClick = () => handleSetIndex(itemKey);
  const ref = useRef(null);
  const size = useComponentSize(ref);
  const content = sectionWrapper({ list: components, isInside: true });
  return (
    <Item>
      <Head onClick={handleClick} isOpened={isOpened}>{label}</Head>
      <Body isOpened={isOpened} height={size.height}>
        <Content ref={ref}>
          <ThemeProvider theme={{
            level: contextLevelTypes.inside,
            bg: bg
          }}>
            {content}
          </ThemeProvider>
        </Content>
      </Body>
    </Item>
  );
};

ListItem.propTypes = {
  ...AccordionListItemPropsExternal,
  ...AccordionListItemPropsInternal
};

export const Accordion = ({
  headline,
  list,
  defaultIndex = -1,
  bg = contextThemeTypes.default,
}) => {
  const [index, setIndex] = useState(defaultIndex);
  const handleSetIndex = (itemKey) => setIndex(itemKey === index ? -1 : itemKey);
  return (
    <Container>
      {headline && <Headline>{headline}</Headline>}
      <List>
        {list.map((item, key) => (
          <ListItem
            {...item}
            isOpened={index === key}
            itemKey={key}
            handleSetIndex={handleSetIndex}
            key={key}
            bg={bg}
          />
        ))}
      </List>
    </Container>
  )
};

Accordion.propTypes = {
  headline: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape(AccordionListItemPropsExternal)
  ).isRequired,
};