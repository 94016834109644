import { bp, clearBtn, color, defTransition, SvgSliderArrow, xz } from '#theme';
import { rgba } from 'polished';
import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled from "styled-components/macro";

const Container = styled.div`
  height: 100%;
  padding: ${xz(2)} 0 ${xz(4)};
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  flex: 1 0 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${xz(4)};
  padding: 0 ${xz(2)};
`;

const Stars = styled.div`
  margin-bottom: ${xz(2)};
`;
const StarsSvg = styled.svg`
  height: 40px;
  width: 240px;
  display: block;
`;
const Rating = styled.div`
  display: flex;
`;
const Score = styled.div`
  font-size: 4.5rem;
  line-height: 1;
  color: ${color.gold};
  flex: 1 0 0%;
`;
const Label = styled.div`
  color: ${color.middleGrey};
  font-size: 0.875rem;
  line-height: 1.28;
  margin-top: ${xz(1)};
  font-weight: bold;
  flex: 1 0 0%;
  margin-left: 16px;
  padding-top: 18px;
`;
const Count = styled.div`
  font-size: 1.5rem;
  line-height: 1;
  font-weight: normal;
`;
const Address = styled.div`
  color: ${color.middleGrey};
  font-size: 0.875rem;
  line-height: 1.28;
  text-align: center;
`;
const Caption = styled.div`
  font-size: 0.875rem;
  line-height: 1.28;
  margin-top: ${xz(2)};
  border-top: 2px solid ${rgba(color.middleGrey, 0.25)};
  padding-top: ${xz(2)};
  color: ${color.white};
  text-align: center;
`;
const Reviews = styled.div`
  
`;

const Header = styled.div`
  padding: ${xz(2)} ${xz(2)};
  display: flex;
  align-items: center;
`;
const ArrowPrev = styled(SvgSliderArrow)`
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  fill: ${color.middleGrey};
`;
const ArrowNext = styled(ArrowPrev)`
  transform: rotate(0deg);
`;

const Btn = styled.button`
  ${clearBtn}
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background-color ${defTransition};
  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
  &:not(:disabled):hover {
    background-color: ${color.white};
  }
`;
const Headline = styled.div`
  flex: 1 0 0%;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-weight: bold;
`;
const Title = styled.span`
  color: ${color.middleGrey};
`;
const Footer = styled.div`
  padding: 0 ${xz(3)};
  @media ${bp.m} {
    padding-left: ${xz(6)};
    padding-right: ${xz(6)};
  }
`;
const Review = styled.div`
  
`;
const ReviewHead = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 1px;
`;
const ReviewScore = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
`;
const ReviewStars = styled.div``;
const ReviewStarsSvg = styled.svg`
  width: 80px;
  height: 14px;
`;
const ReviewDate = styled.div`
  margin-left: ${xz(1)};
  margin-top: ${xz(1)};
  font-size: 0.75rem;
  line-height: 1.17;
  color: ${color.middleGrey};
  white-space: nowrap;
`;
const ReviewLabel = styled.div`
  background-color: ${color.red};
  padding: 4px 8px;
  font-weight: bold;
  border-radius: 3px;
  font-size: 0.75rem;
  line-height: 1.17;
  color: ${color.white};
`;
const ReviewBody = styled.div`
  margin-top: ${xz(2)};
  color: ${color.white};
  font-size: 1rem;
  line-height: 1.25;
`;

const Mask = styled.mask``;

export const WidgetScore = () => {
  const score = 4.8;
  const reviews = [0,1,2];
  const [page, setPage] = useState(0);
  const starWidth = 40;
  const starGap = 8;
  const handleNext = () => {
    const nextPage = page + 1;
    setPage(nextPage >= reviews.length ? 0 : nextPage);
  };
  const handlePrev = () => {
    const nextPage = page - 1;
    setPage(nextPage < 0 ? reviews.length - 1 : nextPage);
  };
  const getStars = (scr) =>{
    const stars = [];
    for (let i = 0; i < 5; i++) {
      const percents = Math.round((scr - i) * 100);
      const width = percents > 100 ? 100 : percents;
      stars.push((
        <svg x={i * (starWidth + starGap)} width={starWidth} key={`str-${i}`}>
          <Mask id="starMask">
            <rect x="0" y="0" width="100%" height="100%" fill="black" />
            <use xlinkHref="#svgStar" fill="white" x="0" y="0" />
          </Mask>
          <use xlinkHref="#svgStar" fill="none" />
          <rect width={`${width}%`} height="100%" fill={color.gold} mask="url(#starMask)" />
        </svg>
      ))
    }
    return stars;
  };
  const starsViewBox = `0 0 ${(starWidth + starGap) * 5 - starGap} ${starWidth}`;
  const starsSvgDefs = (
    <defs>
      <symbol id="svgStar" x="0" y="0" width="40" height="40" viewBox="0 0 40 40">
        <path d="M30.781 37.5a1.254 1.254 0 0 1-.734-.234L20 29.982 9.952 37.266a1.247 1.247 0 0 1-1.475-.005 1.247 1.247 0 0 1-.443-1.407l3.919-11.607-10.156-6.966a1.253 1.253 0 0 1-.037-2.037A1.26 1.26 0 0 1 2.5 15h12.53l3.781-11.637a1.25 1.25 0 0 1 2.378.001l3.781 11.641H37.5a1.247 1.247 0 0 1 1.195.88 1.256 1.256 0 0 1-.487 1.402l-10.16 6.961 3.916 11.604a1.245 1.245 0 0 1-.168 1.128 1.242 1.242 0 0 1-1.015.52z" strokeWidth="2" stroke="#ffd70a"/>
      </symbol>
    </defs>
  );
  return (
    <Container>
      <Body>
        <Stars>
          <StarsSvg xmlns="http://www.w3.org/2000/svg" viewBox={starsViewBox}>
            {starsSvgDefs}
            {getStars(score)}
          </StarsSvg>
        </Stars>
        <Rating>
          <Score>{score}</Score>
          <Label>
            <Count>54</Count> Rezensionen
          </Label>
        </Rating>
        <Caption>
          Vodafone Premium Fachhändler <br />
          Düsseldorf Rathaus
        </Caption>
        <Address>Marienplatz 23, 12345 Düsseldorf</Address>
      </Body>
      <Reviews>
        <Header>
            <Btn onClick={handlePrev}>
              <ArrowPrev />
            </Btn>
            <Headline>
              <Title>LETZTE REZENSIONEN</Title>
            </Headline>
            <Btn onClick={handleNext}>
              <ArrowNext />
            </Btn>
          </Header>
          <Footer>
            <SwipeableViews index={page}>
              {reviews.map((review, key) => (
                <Review key={key}>
                  <ReviewHead>
                    <ReviewScore>
                      <ReviewStars>
                        <ReviewStarsSvg xmlns="http://www.w3.org/2000/svg" viewBox={starsViewBox}>
                          {starsSvgDefs}
                          {getStars(4)}
                        </ReviewStarsSvg>
                      </ReviewStars>
                      <ReviewDate>vor einer Woche</ReviewDate>
                    </ReviewScore>
                    <ReviewLabel>NEU</ReviewLabel>
                  </ReviewHead>
                  <ReviewBody>
                    Super Laden und gut beratendes Personal. Ein Problemfall konnte super schnell gelöst werden! Manchmal ist es besser, einen Ansprechpartner mit Gesicht zu haben :)
                  </ReviewBody>
                </Review>
              ))}
            </SwipeableViews>
          </Footer>
      </Reviews>
    </Container>
  )
};