import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import { color, xz } from "#theme";
import { contextThemeTypes, themeKeys } from '#helpers';


export const Body = styled.main`
  position: relative;
  padding-bottom: ${xz(20)};
  ${theme(themeKeys.bg, {
    [contextThemeTypes.lightestGrey]: css`
      /* padding-top: ${xz(3)}; */
      background-color: ${color.lightestGrey};
    `
  })}
`;