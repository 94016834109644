import React, { useState, useRef, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import theme from 'styled-theming';
import styled, { css, ThemeProvider } from 'styled-components/macro';
import useComponentSize from '@rehooks/component-size';
import anime from 'animejs/lib/anime.es.js';

import { color, xz, defTransition, bp, clearBtn, gradient } from '#theme';
import { Wrapper, Headline } from '#ui';
import { contextThemeTypes, contextLevelTypes, themeKeys } from '#helpers';
import { sectionWrapper } from '../../hocs/sectionWrapper';

const Head = styled.div`
  overflow: hidden;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 8%;
    right: 0;
    bottom: 1px;
    background-image: ${gradient.whiteToRight};
    z-index: 2;
    pointer-events: none;
    @media ${bp.m} {
      width: 5%;
    }
  }
  ${theme(themeKeys.bg, {
    [contextThemeTypes.red]: css`
      &::after {
        background-image: ${gradient.redToRight};
      }
    `,
    [contextThemeTypes.lightestGrey]: css`
      &::after {
        background-image: ${gradient.lightestGreyToRight};
      }
    `,
  })}
`;
const HeadScroller = styled.div`
  overflow-x: scroll;
  padding-bottom: 17px;
  margin-bottom: -17px;
  display: flex;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: 17px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${color.lightGrey};
  }
  ${theme(themeKeys.bg, {
    [contextThemeTypes.red]: css`
      &::before{
        background-color: ${color.maroon};
      }
    `,
  })}
`;
const TabList = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 0 8%;
  border-bottom: 1px solid ${color.lightGrey};
  @media ${bp.m} {
    padding: 0 5%;
  }
  ${theme(themeKeys.bg, {
    [contextThemeTypes.red]: css`
      border-bottom-color: ${color.maroon};
    `,
  })}
`;
const TabListItem = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: -1px;
  padding: 0 ${xz(1.5)};
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }
  @media ${bp.m} {
    padding: 0 ${xz(2)};
  }
  @media ${bp.p} {
    padding: 0 ${xz(4)};
  }
`;
const Button = styled.button`
  ${clearBtn}
  font-weight: bold;
  display: inline-block;
  @media ${bp.p} {
    font-size: 18px;
  }
  @media ${bp.d} {
    font-size: 20px;
  }
`;
const Label = styled.span`
  padding: ${xz(3)} 0 ${xz(2)};
  color: ${({ isActive }) => isActive ? color.red : color.textGrey};
  transition: color ${defTransition};
  display: flex;
  flex-direction: column;
  position: relative;
  ${theme(themeKeys.bg, {
    [contextThemeTypes.red]: css`
      color: ${color.white};
    `,
  })}
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({isActive}) => isActive ? color.red : 'transparent'};
    transition: background-color ${defTransition};
    ${({ isActive }) => isActive && theme(themeKeys.bg, {
      [contextThemeTypes.red]: css`
        background-color: ${color.white};
      `,
    })}
  }
`;
const Body = styled.div`
  
`;
const Content = styled.div`
  padding: ${xz(3)} 0;
  @media ${bp.p} {
    padding: ${xz(4)} 0;
  }
`;

const Tab = ({ children, index, tabClick, itemKey, themeStyles, themeStylesLine, setCurrentTabRef }) => {
  const isActive = index === itemKey;
  const ref = useRef(null);
  const handleClick = () => tabClick(itemKey);
  useEffect(() => {
    if (isActive) {
      setCurrentTabRef(ref);
    }
  });
  return (
    <TabListItem ref={ref}>
      <Button
        onClick={handleClick}
      >
        <Label
          isActive={isActive}
          isNext={index > itemKey}
          themeStyles={themeStyles}
          themeStylesLine={themeStylesLine}
        >
          {children}
        </Label>
      </Button>
    </TabListItem>
  );
};

let updateHeight;

export const Tabs = ({
  list,
  defaultIndex,
  headline,
}) => {
  const [index, setIndex] = useState(defaultIndex);
  const [currentTabRef, setCurrentTabRef] = useState();
  const scrollerRef = useRef(null);
  const scrollerCurrent = scrollerRef.current;
  const scrollerSize = useComponentSize(scrollerRef);
  const animateScroll = (scrollLeft) => {
    anime({
      targets: scrollerCurrent,
      scrollLeft: scrollLeft,
      duration: 250,
      delay: 250,
      easing: 'easeInOutQuad',
    });
  };
  const handleSetIndex = (itemKey) => {
    if (itemKey !== index) {
       setIndex(itemKey);
       updateHeight();
    }
  };
  useEffect(() => {
    if (!currentTabRef) return;
    const tabRefElement = currentTabRef.current;
    const scrollLeft = tabRefElement.offsetLeft - (scrollerSize.width - tabRefElement.getBoundingClientRect().width) / 2;
    animateScroll(scrollLeft);
    updateHeight();
  });

  const renderItem = ({ components }, key) => (
    <Content key={key}>
      {sectionWrapper({ list: components, isInside: true })}
    </Content>
  );
  
  return (
    <React.Fragment>
      <Wrapper>
        {headline && <Headline>{headline}</Headline>}
      </Wrapper>
      <Head>
        <HeadScroller ref={scrollerRef}>
          <TabList>
            {list.map(({ label }, key) => (
              <Tab
                key={key}
                itemKey={key}
                tabClick={handleSetIndex}
                setCurrentTabRef={setCurrentTabRef}
                index={index}
              >
                {label}
              </Tab>
            ))}
          </TabList>
        </HeadScroller>
      </Head>
      <Body>
        <Wrapper>
          <ThemeProvider theme={{
            level: contextLevelTypes.inside,
          }}>
            <SwipeableViews
              action={actions => {
                updateHeight = actions.updateHeight;
              }}
              index={index}
              onChangeIndex={handleSetIndex}
            >
              {list.map(renderItem)}
            </SwipeableViews>
          </ThemeProvider>
        </Wrapper>
      </Body>
    </React.Fragment>
  )
};

// Tabs.propTypes = {
//   headline: PropTypes.string,
//   list: PropTypes.arrayOf(
//     PropTypes.shape(TabsListItemPropsExternal)
//   ).isRequired,
// };