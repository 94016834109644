import React, { useState } from 'react';
import styled from 'styled-components/macro';
// import SwipeableViews from 'react-swipeable-views';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Slider from "react-slick";

// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";

import { Wrapper, Grid, Col, ButtonDefault } from '#ui';
import { color, bp, fadeIn, fadeOut } from '#theme';
import { rgba } from 'polished';

export const heroThemes = {
  double: "DOUBLE",
}
const Container = styled.div`
  overflow: hidden;
`;
const ContainerInner = styled.div`
  position: relative;
  ${({ isThemeDouble }) => isThemeDouble && `
    @media ${bp.m} {
      margin: 0 -6px;
    }
    @media ${bp.t} {
      margin: 0 -12px;
    }
  `}
`;

const Image = styled.div`
  background-position: center;
  background-size: cover;
  background-image: url(${({ src }) => src});
  height: 360px;
  position: relative;
  display: block !important;
  width: auto !important;
  @media ${bp.m} {
    height: 0;
    padding-bottom: ${({ isThemeDouble }) => !isThemeDouble ? 37.5 : 75 }%;
    ${({ isThemeDouble }) => isThemeDouble && `
      margin: 0 6px;
    `}
  }
  @media ${bp.t} {
    padding-bottom: ${({ isThemeDouble }) => !isThemeDouble ? 30 : 60 }%;
    ${({ isThemeDouble }) => isThemeDouble && `
      margin: 0 12px;
    `}
  }
`;

const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  ${({ isThemeDouble }) => isThemeDouble && `
    @media ${bp.m} {
      padding-left: 10%;
    }
    @media ${bp.d} {
      padding-left: 0;
    }
  `}
`;
const Inner = styled(Wrapper)`
  flex: 1 0 0%;
`;

const Content = styled.div`
  flex: 1 0 100%;
  display: flex;
  padding-top: 56px;
  padding-bottom: 72px;
  height: 100%;
  @media ${bp.m} {
    ${({ isThemeDouble }) => isThemeDouble && `
      
      // padding-right: 64px;
      padding-bottom: 52px;
    `}
  }
  @media ${bp.d} {
    padding-top: 64px;
    padding-bottom: 64px;
    ${({ isThemeDouble }) => isThemeDouble && `
      padding-left: 82px;
      padding-right: 82px;
    `}
  }
`;
const Nav = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 18px;
  @media ${bp.m} {
    ${({isThemeDouble}) => isThemeDouble && `
      display: none;
    `}
  }
`;
const NavList = styled.ul`
  display: flex;
  margin: 0 -6px;
  padding: 0;
  @media ${bp.m} {
    justify-content: center;
  }
`;
const NavItem = styled.li`
  display: block;
  padding: 6px;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border: 1px solid ${color.white};
    background-color: ${props => props.isActive ? color.white : 'transparent' };
    border-radius: 50%;
    width: 12px;
    height: 12px;
  }
`;
const HeroLink = styled(Link)`
  text-decoration: none;
  display: flex;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
`;
const Date = styled.span`
  display: block;
  color: ${rgba(color.white, 0.5)};
  margin-bottom: 8px;
`;

const Title = styled.h1`
  flex: 1 0 0%;
  margin: 0;
  font-size: 32px;
  line-height: 36px;
  font-weight: 300;
  color: ${color.white};
  
  ${({ isThemeDouble }) => isThemeDouble && `
    flex: none;
  `}
  @media ${bp.m} {
    align-items: flex-start;
    font-weight: 300;
    ${({ isThemeDouble }) => isThemeDouble && `
      align-items: flex-end;
      max-width: 512px;
    `}
  }
  @media ${bp.p} {
    font-size: 44px;
    line-height: 48px;
    /* ${({ isThemeDouble }) => isThemeDouble && `
      font-size: 32px;
      line-height: 36px;
    `} */
  }
  @media ${bp.t} {
    ${({ isThemeDouble }) => !isThemeDouble && `margin-left: 24px`};
  }
  @media ${bp.d} {
    font-size: 80px;
    line-height: 84px;
    ${({ isThemeDouble }) => isThemeDouble && `
      font-size: 48px;
      line-height: 52px;
    `}
  }
`;
const Arrow = styled(ButtonDefault)`
  width: 36px;
  height: 72px;
  background-color: ${rgba(color.textGrey, 0.5)};
  display: none;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  ${({ isNext, isThemeDouble }) => isNext
    ? `right: ${isThemeDouble ? 6 : 0}px;` 
    : `left: ${isThemeDouble ? 6 : 0}px;`
  }
  ${fadeOut}
  ${Container}:hover & {
    ${fadeIn}
  }
  @media ${bp.m} {
    display: flex;
  }
  @media ${bp.t} {
    ${({ isNext, isThemeDouble }) => isNext
      ? `right: ${isThemeDouble ? 28 : 16}px;` 
      : `left: ${isThemeDouble ? 28 : 16}px;`
    }
  }
  &::after {
    content: "";
    display: block;
    border: 12px solid transparent;
    border-left-width: 0;
    border-right-color: ${color.white};
    ${props => props.isNext && 'transform: rotate(180deg);'}
  }
`;

export const Hero = ({ theme, slides }) => {
  const [index, setIndex] = useState(0);
  const isPhabletPortrait = useMediaQuery({ query: bp.m });
  const isPhablet = useMediaQuery({ query: bp.p });
  const isTablet = useMediaQuery({ query: bp.t });
  const contentWidth = !isPhabletPortrait
    ? 4
    : !isPhablet
      ? 6
      : !isTablet
        ? 5
        : 6;
  const doubleContentWidth = 5;
  const slider = React.createRef();
  const handleIndex = (nextIndex) => {
    slider.current.slickGoTo(nextIndex);
  };
  const handleNext = () => {
    slider.current.slickNext();
  }
  const handlePrev = () => {
    slider.current.slickPrev();
  }
  const isThemeDouble = theme === heroThemes.double;
  const Entry = ({ title, date }) => (
    <Text>
      <Date>{date}</Date>
      <Title isThemeDouble={isThemeDouble}>{title}</Title>
    </Text>
  );
  return (
    <Container>
      {/* <SwipeableViews
        index={index}
        onChangeIndex={(i) => setIndex(i)}
        // slideStyle={HalfSlide}
      > */}
      <ContainerInner isThemeDouble={isThemeDouble}>
        <Slider
          dots={false}
          speed={500}
          slidesToShow={isThemeDouble && isPhabletPortrait ? 2 : 1}
          arrows={false}
          draggable={false}
          ref={slider}
          beforeChange={(_, next) => setIndex(next)}
        >
          {slides.map((post, key) => (
            <Image src={post.image} key={key} isThemeDouble={isThemeDouble}>
              <Box isThemeDouble={isThemeDouble}>
                {isThemeDouble && isPhabletPortrait ? (
                  <Content isThemeDouble={isThemeDouble}>
                    <HeroLink to={post.path}>
                      <Grid columns={6}>
                        <Col width={doubleContentWidth}>
                          <Entry title={post.title} date={post.date} />
                        </Col>
                      </Grid>
                    </HeroLink>
                  </Content>
                ) : (
                  <Inner>
                    <Content>
                      <HeroLink to={post.path}>
                        <Grid>
                          <Col width={contentWidth}>
                            <Entry title={post.title} date={post.date} />
                          </Col>
                        </Grid>
                      </HeroLink>
                    </Content>
                  </Inner>
                )}
              </Box>
            </Image>
          ))}
        </Slider>
        {/* </SwipeableViews> */}
        <Nav isThemeDouble={isThemeDouble}>
          <Wrapper>
            <NavList>
              {slides.map((_, key) => (
                <NavItem key={key} isActive={key === index} onClick={() => handleIndex(key)} />
              ))}
            </NavList>
          </Wrapper>
        </Nav>
        <React.Fragment>
          <Arrow isThemeDouble={isThemeDouble} onClick={() => handlePrev()} />
          <Arrow isThemeDouble={isThemeDouble} onClick={() => handleNext()} isNext />
        </React.Fragment>
      </ContainerInner>
    </Container>
  );
};
