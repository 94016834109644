import React, { useEffect, useState } from 'react';
import styled from "styled-components/macro";
import { rgba } from 'polished';

import { color, expander, xz } from '#theme';

const Container = styled.div`
  width: 168px;
  height: 168px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  svg {
    ${expander}
  }
`;

const Value = styled.div`
  font-size: 4.5rem;
  line-height: 1;
  color: ${color.red};
`;
const Label = styled.div`
  color: ${color.middleGrey};
  font-size: 1.125rem;
  line-height: 1.22;
  margin-top: ${xz(1.5)};
  font-weight: bold;
`;

const CountdownChart = ({ percents }) => {
  const r = 84;
  const stroke = 6;
  const xy = -stroke/2;
	const xy2 = (r + stroke/2) * 2;
  const trackRad = (0.5 - 0.02 * percents) * Math.PI;

	const trackDx = r + Math.cos(trackRad) * r;
	const trackDy = r - Math.sin(trackRad) * r;

  const angleCoef = percents >= 50 ? 1 : 0;
  const d = (dx, dy) => [
    "M", r, 0, 
    "A", r, r, 0, angleCoef, 1, dx, dy
  ].join(" ");
  return (
    <svg data-chart="chart1" viewBox={`${xy} ${xy} ${xy2} ${xy2}`}>
      <circle fill="none" stroke={rgba(color.white, 0.5)} strokeWidth="2" cx={r} cy={r} r={r + 2} />
      <path fill="none" stroke="red"  strokeWidth="6" d={d(trackDx, trackDy)} />
    </svg>
  );
};

export const Countdown = ({
  startDate,
  endDate,
}) => {
  const calculateTimeLeft = () => {
    const start = +new Date(startDate);
    const end = +new Date(endDate);
    const now = +new Date();
    const difference = end - now;
    const percents = Math.round((end - now)/(end - start) * 100);
    
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
        percents,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const t = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => {
      clearTimeout(t);
    };
  });

  const countdownData = timeLeft.days > 0
    ? { value: timeLeft.days, label: timeLeft.days > 1 ? "Tagen" : "Tag" }
    : timeLeft.hours > 0
      ? { value: timeLeft.hours, label: timeLeft.hours > 1 ? "Hours" : "Hour" }
      : timeLeft.minutes > 0
        ? { value: timeLeft.minutes, label: timeLeft.minutes > 1 ? "Minutes" : "Minute" }
        : { value: timeLeft.seconds, label: timeLeft.seconds > 1 ? "Seconds" : "Second" };
  return (
    <Container>
      <CountdownChart percents={timeLeft.percents} />
      <Value>{countdownData.value || 0}</Value>
      <Label>{countdownData.label}</Label>
    </Container>
  );
};