import React from 'react';
import theme from 'styled-theming';
import styled, { css } from 'styled-components/macro';
import { contextThemeTypes } from '#helpers';
import { color, bp } from '#theme';
import useFitText from "use-fit-text";

const Container = styled.div`
  background-color: ${color.white};
  color: ${color.red};
  width: 80px;
  height: 80px;
  border-radius: 50%;
  font-weight: bold;
  line-height: 1;
  font-size: 1.125rem;
  display: flex;
  justify-content: center;
  padding: 12px;
  @media ${bp.m} {
    width: 96px;
    height: 96px;
    padding: 16px;
    font-size: 1.25rem;
  }
  @media ${bp.p} {
    width: 128px;
    height: 128px;
    padding: 20px;
    font-size: 1.375rem;
  }
  @media ${bp.d} {
    width: 144px;
    height: 144px;
    font-size: 1.5rem;
  }
  ${theme('badgeTheme', {
    [contextThemeTypes.red]: css`
      background-color: ${color.red};
      color: ${color.white};
    `,
  })}
`;
const Inner = styled.div`
  flex: 1 0 0%;
  max-width: 100%;
`;
const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-size: ${({ fontSize }) => fontSize};
`;

export const Badge = ({
  children,
}) => {
  const { fontSize, ref } = useFitText({ recalcOnResize: true });
  
  return (
    <Container>
      <Inner ref={ref}>
        <Text fontSize={fontSize}>
          {children}
        </Text>
      </Inner>
    </Container>
  );
};