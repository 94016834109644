import React from 'react';
import styled, { css } from 'styled-components/macro';

import { Grid, Wrapper } from '#ui';
import { bp, color } from '#theme';
import { Teaser, headlineTypes } from '../Teaser/Teaser';
import { rgba } from 'polished';

const Item = styled.div`
  grid-column-end: 4 span;
  @media ${bp.m} {
    grid-column-end: 2 span;
  }
  @media ${bp.p} {
    grid-column-end: 4 span;
  }
  ${({ isFirst }) => isFirst && css`
    @media ${bp.m} {
      grid-row-end: 4 span;
    }
    @media ${bp.p} {
      grid-row-end: 2 span;
    }
  `}
`;
const Container = styled(Wrapper)`
  
`;
const GridBox = styled(Grid)`
  background: linear-gradient(180deg, ${color.lightestGrey} 0%, ${rgba(color.lightestGrey, 0)} 100%);
`;

export const TeaserListCombo = ({
  list,  
}) => {
  return (
    <Container>
      <GridBox rowGap={"0"}>
        {list.map((item, key) => {
          const isFirst = key === 0;
          return (
            <Item key={key} isFirst={isFirst}>
              <Teaser
                {...item}
                headline={{
                  type: isFirst ? headlineTypes.h2 : headlineTypes.h3,
                  text: item.headline.text,
                }} />
            </Item>
          );
        })}
      </GridBox>
    </Container>
  )
};

