import React from 'react';
import styled from 'styled-components/macro';
import { color } from '#theme';

const Container = styled.span`
  width: 24px;
  height: 23px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Icon = styled.span`
  position: relative;
  height: 1px;
  display: block;
  background-color: ${color.white};
  &::before, &::after {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    background-color: ${color.white};
  }
  &::before {
    transform: translateY(-7px);
  }
  &::after {
    transform: translateY(7px);
  }
`;

export const Hamburger = () => (
  <Container>
    <Icon />
  </Container>
);