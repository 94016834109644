import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import styled, { ThemeContext } from 'styled-components/macro';
import { xz, bp } from '#theme';
import { Grid, Col, Wrapper } from '#ui';
import { Rte } from '../Rte/Rte';
import { contextLevelTypes } from '#helpers';

export const textMediaLayoutTypes = {
  rows: "rows",
  columns: "columns",
};
export const textMediaItemLayoutTypes = {
  imageRight: "imageRight",
  imageRightMultiple: "imageRightMultiple",
  imageLeft: "imageLeft",
  imageLeftMultiple: "imageLeftMultiple",
  imageTop: "imageTop",
  imageTopMultiple: "imageTopMultiple",
};
export const textMediaImageSizeTypes = {
  s: "s",
  l: "l",
}
export const textMediaLayouts = {
  [textMediaLayoutTypes.rows]: {},
  [textMediaLayoutTypes.columns]: {
    Text: `
      @media ${bp.p} {
        padding-top: ${xz(3)};
        padding-right: ${xz(4)};
      }
    `
  },
}
const textMediaItemLayouts = {
  [textMediaItemLayoutTypes.imageRight]: {},
  [textMediaItemLayoutTypes.imageLeft]: {},
  [textMediaItemLayoutTypes.imageTop]: {},
  [textMediaItemLayoutTypes.imageRightMultiple]: {
    Image: `
      padding-bottom: 100%;
    `,
    SquareImage: `
      @media ${bp.p} {
        padding-bottom: 100%;
      }
    `,
  },
  [textMediaItemLayoutTypes.imageLeftMultiple]: {
    Image: `
      padding-bottom: 100%;
    `,
    SquareImage: `
      @media ${bp.p} {
        padding-bottom: 100%;
      }
    `,
  },
  [textMediaItemLayoutTypes.imageTop]: {
    Text: `
      @media ${bp.p} {
        padding-top: ${xz(3)};
        padding-right: ${xz(4)};
      }
    `
  },
  [textMediaItemLayoutTypes.imageTopMultiple]: {
    Text: `
      @media ${bp.p} {
        padding-top: ${xz(3)};
        padding-right: ${xz(4)};
      }
    `,
    SquareImage: `
      @media ${bp.p} {
        padding-bottom: 100%;
      }
    `,
  },
}
const imageSizes = {
  [textMediaImageSizeTypes.s]: {
    width: 8,
    textLeftPosition: {
      [textMediaItemLayoutTypes.imageLeft]: 10,
      [textMediaItemLayoutTypes.imageLeftMultiple]: 10,
      [textMediaItemLayoutTypes.imageRight]: 1,
      [textMediaItemLayoutTypes.imageRightMultiple]: 1,
    },
    imageLeftPosition: {
      [textMediaItemLayoutTypes.imageLeft]: 1,
      [textMediaItemLayoutTypes.imageLeftMultiple]: 1,
      [textMediaItemLayoutTypes.imageRight]: 17,
      [textMediaItemLayoutTypes.imageRightMultiple]: 17,
    }
  },
  [textMediaImageSizeTypes.l]: {
    width: 12,
    textLeftPosition: {
      [textMediaItemLayoutTypes.imageLeft]: 14,
      [textMediaItemLayoutTypes.imageLeftMultiple]: 14,
      [textMediaItemLayoutTypes.imageRight]: 1,
      [textMediaItemLayoutTypes.imageRightMultiple]: 1,
    },
    imageLeftPosition: {
      [textMediaItemLayoutTypes.imageLeft]: 1,
      [textMediaItemLayoutTypes.imageLeftMultiple]: 1,
      [textMediaItemLayoutTypes.imageRight]: 13,
      [textMediaItemLayoutTypes.imageRightMultiple]: 13,
    }
  },
};

const Body = styled.div`
`;
const Item = styled.div`
  & + & {
    margin-top: ${xz(8)};
  }
  ${({ layoutStyles }) => layoutStyles}
`;

const Image = styled.div`
  height: 0;
  padding-bottom: 56.25%;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  ${({ itemLayoutStyles }) => itemLayoutStyles}
`;
const Text = styled.div`
  padding-top: ${xz(3)};
  @media ${bp.p} {
    padding-top: 0;
  }
  ${({ parentLayoutStyles }) => parentLayoutStyles}
  ${({ itemLayoutStyles }) => itemLayoutStyles}
`;

const ListItem = ({
  images,
  text,
  layout = textMediaItemLayoutTypes.imageRight,
  parentLayout = textMediaLayoutTypes.rows,
  imageSize = textMediaImageSizeTypes.s,
}) => {
  const columns = 24;
  const isPhablet = useMediaQuery({ query: bp.p });
  const imageWidth = !isPhablet
    ? 4
    : imageSizes[imageSize].width;
  const textWidth = !isPhablet
    ? 4
    : columns - imageWidth - 1;
  
  const isImageTop = layout === textMediaItemLayoutTypes.imageTop;
  const isImageTopMultiple = layout === textMediaItemLayoutTypes.imageTopMultiple;
  const isSquareImage = layout === textMediaItemLayoutTypes.imageRightMultiple || (isImageTopMultiple && images.length >=4);
  
  const bodytext = (
    <Text
      key={0}
      level={contextLevelTypes.inside}
      parentLayoutStyles={textMediaLayouts[parentLayout].Text}
      itemLayoutStyles={textMediaItemLayouts[layout].Text}
    >
      <Rte level={contextLevelTypes.inside}>
        {text}
      </Rte>
    </Text>
  );
  const bodyimage = (
     <Grid columns={isPhablet ? images.length : 1} key={1}>
      {images.map((image, key) => (
        <Col key={key}>
          <Image
            image={image}
            itemLayoutStyles={isSquareImage && textMediaItemLayouts[layout].SquareImage}
            count={images.length}
          />
        </Col>
      ))}
    </Grid>
  );
  return (
    <Item>
      {!isPhablet || isImageTop || isImageTopMultiple
        ? [bodyimage, bodytext]
        : (
          <Grid columns={columns} flow="row dense">
            <Col width={imageWidth} left={imageSizes[imageSize].imageLeftPosition[layout]}>
              {bodyimage}
            </Col>
            <Col width={textWidth} left={imageSizes[imageSize].textLeftPosition[layout]}>
              {bodytext}
            </Col>
          </Grid>
        )
      }
    </Item>
  );
};

ListItem.propTypes = {
  images: PropTypes.array.isRequired,
  text: PropTypes.string,
  layout: PropTypes.oneOf(Object.values(textMediaItemLayoutTypes)),
  imageSize: PropTypes.oneOf(Object.values(textMediaImageSizeTypes)),
};

export const TextMedia = ({
  list,
  // layout = textMediaLayoutTypes.rows
}) => {
  const {
    layout = textMediaLayoutTypes.rows
  } = useContext(ThemeContext);
  const isPhablet = useMediaQuery({ query: bp.p });
  const renderCols = (item, key) => (
    <Col key={key}>
      <ListItem {...item} parentLayout={layout} />
    </Col>
  );
  const renderRows = (item, key) => (
    <ListItem key={key} {...item} parentLayout={layout} />
  );
  const body = isPhablet && layout === textMediaLayoutTypes.columns
    ? (
      <Grid columns={list.length}>
        {list.map(renderCols)}
      </Grid>
    ) : list.map(renderRows);
  return (
    <Wrapper>
      <Body>
        {body}
      </Body>
    </Wrapper>
  );
};

TextMedia.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape(ListItem.propTypes)
  ).isRequired,
  // theme: PropTypes.oneOf(Object.values(contextThemeTypes)),
};
