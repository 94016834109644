import React from 'react';
import styled from 'styled-components/macro';
import { gradient, expander } from '#theme';

const Container = styled.div`
  height: 0;
  padding-bottom: 33%;
  background-image: ${gradient.red};
  position: relative;
`;
const Text = styled.span`
  ${expander}
  color: white;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Placeholder = () => (
  <Container>
    <Text>Placeholder</Text>
  </Container>
);