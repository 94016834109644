import * as images from './images';
import * as svg from './svg';
import * as video from './video';
import { iconTypes } from '#ui';
import { userMenuComponentsTypes } from '../components/UserMenu/UserMenu';

export { images };
export { svg };
export { video };

export const site = {
  title: "vodafone ",
  location: "Infowelt",
};

export const common = {
  download: "Download",
  learnmore: "Learn more",
}

export const search = {
  trending: [
    "dezember",
    "produkte",
    "promotions",
    "preise",
    "mobi",
    "redhome",
    "callya",
    "youth",
    "gigakombi",
    "iphone",
    "ipad",
    "samsung",
    "google",
    "pos",
    "xplain",
    "academy",
    "university",
    "gigacube",
    "soho",
    "gigacube",
    "iot"
  ],
  suggestions: [
    "gigakombi",
    "gigacube",
    "gigabitrepublik unitymedia",
    "gigabitrepublik vodafone",
    "gigabitrepublik führungskräfte",
    "3 Monate unlimitiertes Highspeed-Datenvolumen",
    "Mit Speed ins GIGA RALLYE-Incentive",
    "Giga-Surfen mit zu 1000 Mbit/s",
  ]
};


export const posts = {
  filters: {
    theme: [
      {
        label: "Alle Themen",
        value: "all",
      },
      {
        label: "Credit",
        value: "credit",
      },
      {
        label: "Debit",
        value: "debit",
      },
      {
        label: "RediHome",
        value: "redihome",
      },
      {
        label: "Hardware & Zubehör",
        value: "hardware-n-zubehor",
      },
      {
        label: "Bestandskunden",
        value: "bestandskunden",
      },
      {
        label: "GigaKombi",
        value: "gigakombi",
      },
      {
        label: "POS",
        value: "pos",
      },
      {
        label: "Vodafone",
        value: "vodafone",
      },
    ],
    period: [
      {
        label: "Beliebiger Zeitraum",
        value: "beliebiger-zeitraum",
      },
      {
        label: "Letzte 24 Stunden",
        value: "letzte-24-stunden",
      },
      {
        label: "Letzte Woche",
        value: "letzte-woche",
      },
      {
        label: "Letzter Monat",
        value: "letzter-monat",
      },
      {
        label: "Letztes Jahr",
        value: "letztes-jahr",
      },
    ],
  },
  new: [
    {
      title: "Fit für den Start – #GigabitRepublik",
      category: "Category",
      date: "12.09.2019",
      image: images.timeline1,
      isUpdated: true,
      isBookmarked: false,
      isViewed: true,
      path: "#post1",
    },
    {
      title: "KIP Winback Aktion – Überzeuge Deine KIP Kunden zu bleiben",
      category: "Category",
      date: "12.09.2019",
      image: images.timeline2,
      isUpdated: false,
      isBookmarked: true,
      isViewed: true,
      path: "#post2",
    },
    {
      title: "Verlängerung: Bis zu 100 € CashBack – HUAWEI-Aktion exklusiv nur bei Vodafone",
      category: "Category",
      date: "12.09.2019",
      image: images.timeline3,
      isUpdated: true,
      isBookmarked: false,
      isViewed: true,
      path: "#post3",
    },
    {
      title: "5G für Red, Young und Black",
      category: "Category",
      date: "12.09.2019",
      image: images.timeline4,
      isUpdated: false,
      isBookmarked: false,
      isViewed: false,
      path: "#post4",
    },
    {
      title: "Wir schreiben die Gigabit-Geschichte fort",
      category: "Category",
      date: "12.09.2019",
      image: images.timeline5,
      isUpdated: false,
      isBookmarked: false,
      isViewed: false,
      path: "#post5",
    },
  ],
  old: [
    {
      title: "Positive Beratungseinwilligung (BEW): Voraussetzung für die erfolgreiche Kundenbindung",
      category: "Category",
      date: "12.09.2019",
      image: images.timeline6,
      isUpdated: false,
      isBookmarked: false,
      isViewed: true,
      path: "#post6",
    },
    {
      title: "Die neuen iPhone Modelle sind da! Vorverkaufsstart am 13.09.2019",
      category: "Category",
      date: "12.09.2019",
      image: images.timeline7,
      imageIsIcon: true,
      isUpdated: true,
      isBookmarked: false,
      isViewed: true,
      path: "#post7",
    },
    {
      title: "NEU: SONY Xperia 5 zum Start mit kostenlosen SONY Kopfhörern",
      category: "Category",
      date: "28.08.2019",
      image: images.timeline8,
      isUpdated: false,
      isBookmarked: false,
      isViewed: true,
      path: "#post8",
    },
    {
      title: "Neuer Entertainment Partner „Hatch“ – Mehr als 100 Premium-Games in einer App",
      category: "Category",
      date: "01.08.2019",
      image: images.timeline9,
      isUpdated: false,
      isBookmarked: false,
      isViewed: true,
      path: "#post8",
    },
    {
      title: "Das neue Samsung Galaxy A90 5G – Die fünfte Generation kommt",
      category: "Category",
      date: "29.07.2019",
      image: images.timeline10,
      isUpdated: false,
      isBookmarked: false,
      isViewed: true,
      path: "#post8",
    },
    {
      title: "Überzeugende Goodies für Deine Neukunden",
      category: "Category",
      date: "28.07.2019",
      image: images.timeline11,
      isUpdated: true,
      isBookmarked: false,
      isViewed: false,
      path: "#post8",
    },
    {
      title: "Verlängerung HUAWEI-Aktion: Bis zu 100 € CashBack",
      category: "Category",
      date: "27.08.2019",
      image: images.timeline12,
      isUpdated: false,
      isBookmarked: false,
      isViewed: false,
      path: "#post8",
    },
  ],
  hero2: [
    {
      title: "Fit für den Start – #GigabitRepublik",
      category: "Category",
      date: "12.09.2019",
      image: images.magazin1,
      isUpdated: true,
      isBookmarked: false,
      isViewed: false,
      path: "#post3",
    },
    {
      title: "Wir schreiben die Gigabit-Geschichte fort",
      category: "Category",
      date: "02.09.2019",
      image: images.magazin2,
      isUpdated: true,
      isBookmarked: false,
      isViewed: false,
      path: "#post2",
    },
    {
      title: "Unitymedia und Vodafone: Die GigabitCompany",
      category: "Category",
      date: "01.08.2019",
      image: images.magazin3,
      isUpdated: false,
      isBookmarked: false,
      isViewed: false,
      path: "#post4",
    },
    {
      title: "Q2 Incentive: Achterbahn-Spaß mit Deinem Team!",
      category: "Category",
      date: "01.08.2019",
      image: images.magazin4,
      isUpdated: false,
      isBookmarked: false,
      isViewed: false,
      path: "#post4",
    },
  ],
  teasers: [
    {
      title: "KIP Winback Aktion – Überzeuge Deine KIP Kunden zu bleiben",
      category: "Category",
      date: "10.09.2019",
      image: images.magazin5,
      isUpdated: false,
      isBookmarked: false,
      isViewed: false,
      path: "#post4",
    },
    {
      title: "Verlängerung: Bis zu 100 € CashBack – HUAWEI-Aktion exklusiv nur bei Vodafone",
      category: "Category",
      date: "10.09.2019",
      image: images.magazin6,
      isUpdated: false,
      isBookmarked: false,
      isViewed: false,
      path: "#post4",
    },
    {
      title: "5G für Red, Young und Black",
      category: "Category",
      date: "09.09.2019",
      image: images.magazin7,
      isUpdated: false,
      isBookmarked: false,
      isViewed: false,
      path: "#post4",
    },
    {
      title: "Neuer Entertainment Partner „Hatch“ – Mehr als 100 Premium-Games in einer App",
      category: "Category",
      date: "15.08.2019",
      image: images.magazin8,
      isUpdated: false,
      isBookmarked: false,
      isViewed: false,
      path: "#post4",
    },
    {
      title: "DSL-Promotions bis zum 04.11.2019",
      category: "Category",
      date: "10.08.2019",
      image: images.magazin9,
      isUpdated: false,
      isBookmarked: false,
      isViewed: false,
      path: "#post4",
    },
    {
      title: "Verlängerung: Bis zu 100 € CashBack – HUAWEI-Aktion exklusiv nur bei Vodafone",
      category: "Category",
      date: "09.08.2019",
      image: images.magazin10,
      isUpdated: false,
      isBookmarked: false,
      isViewed: false,
      path: "#post4",
    },
    {
      title: "Video-on-Demand wird am 23.09.2019 eingestellt",
      category: "Category",
      date: "08.08.2019",
      image: images.magazin11,
      isUpdated: false,
      isBookmarked: false,
      isViewed: false,
      path: "#post4",
    },
  ]
};

export const user = {
  tabs: [
    {
      iconType: iconTypes.bookmark,
      component: userMenuComponentsTypes.bookmarks,
      props: {
        list: [
          {
            path: "#fav1",
            title: "Verkaufspreise",
            isUpdated: true,
          },{
            path: "#fav2",
            title: "Surfen ohne Grenzen mit dem neuen Young XXL",
            isUpdated: true,
          },{
            path: "#fav3",
            title: "Ein Tablet für alle Tools – Richte es ein und biete Ein Tablet für alle Tools – Richte es ein und biete ",
            isUpdated: true,
          },{
            path: "#fav4",
            title: "Das neue iPad Pro: ab 07.11. ",
          },{
            path: "#fav5",
            title: "Datenboost – noch mehr Datenfreiheit",
          },{
            path: "#fav6",
            title: "Top-Angebote für gekündigte KIP-Kunden",
          },{
            path: "#fav7",
            title: "Bis zu 120 € Rabatt und 1 GB mehr Datenvolumen",
          }
        ],
      },
    },
    {
      iconType: iconTypes.user,
      component: userMenuComponentsTypes.userSettings,
      props: {
        list: [
          {
            path: "/user/settings",
            title: "Settings",
          },{
            path: "/logout",
            title: "Logout",
          }
        ],
      },
    }
  ],
};

