import { Timeline } from './Timeline/Timeline';
import { Magazine } from './Magazine/Magazine';
export { Timeline };
export { Magazine };
export { Components } from './Components/Components';
export { HomeComponents } from './HomeComponents/HomeComponents';

export const nav = [
  {
    pageId: "magazine",
    title: "Magazin",
    component: Magazine,
  },
  {
    pageId: "timeline",
    title: "Timeline",
    component: Timeline,
  }
];