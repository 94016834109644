export { ThemeProvider, ThemeContext } from 'styled-components';

export const contextThemeTypes = {
  default: "default",
  red: "red",
  red45: "red45",
  lightestGrey: "lightestGrey",
  image: "image",
  imageAlt: "imageAlt",
  textGrey: "textGrey",
  black: "black",
};

export const contextSizeTypes = {
  l: "l",
  m: "m",
  s: "s",
};
export const contextLevelTypes = {
  root: "root",
  inside: "inside",
};

export const themeKeys = {
  bg: "bg",
  boxBg: "boxBg",
  level: "level",
  layout: "layout",
  wrapper: "wrapper",
}