import React, { useMemo } from 'react';
import { Table, Link, linkTypes } from '#components';
import { Text } from '#ui';
import { Icon, iconTypes } from '#ui';
import { contextThemeTypes } from '#helpers';
import { color } from '#theme';

export const TableExample3 = () => {
  const data = useMemo(
    () => ({
      thead: [
        [
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.red,
              },
              as: "th",
            },
            innerProps: {
              style: {
                alignItems: 'flex-end',
                textAlign: 'right',
              }
            },
            children: "Hardware",
          },
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.red,
              },
            },
          },
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.red,
              },
            },
          },
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.red,
              },
            },
          },
        ],
      ],
      tbody: [
        [
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
              as: "th",
            },
            innerProps: {
              style: {
                alignItems: 'flex-end',
                textAlign: 'right',
              }
            },
            props: {
              className: 'textGrey',
            },
            children: "Kalbel-Router",
          },
          {
            component: Icon,
            props: {
              type: iconTypes.close,
              color: color.red,
            },
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
            innerProps: {
              style: {
                alignItems: 'center',
                textAlign: 'center',
              },
            },
          },
          {
            component: Icon,
            props: {
              type: iconTypes.check,
              color: color.green,
            },
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
            innerProps: {
              style: {
                alignItems: 'center',
                textAlign: 'center',
              },
            },
          },
          {
            component: Icon,
            props: {
              type: iconTypes.check,
              color: color.green,
            },
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
            innerProps: {
              style: {
                alignItems: 'center',
                textAlign: 'center',
              },
            },
          },
        ],
        [
          {
            component: Text,
            cellProps: {
              as: "th",
            },
            innerProps: {
              style: {
                alignItems: 'flex-end',
                textAlign: 'right',
              }
            },
            props: {
              className: 'textGrey',
            },
            children: "Fritzbox",
          },
          {
            component: Icon,
            props: {
              type: iconTypes.close,
              color: color.red,
            },
            innerProps: {
              style: {
                alignItems: 'center',
                textAlign: 'center',
              },
            },
          },
          {
            component: Icon,
            props: {
              type: iconTypes.close,
              color: color.red,
            },
            innerProps: {
              style: {
                alignItems: 'center',
                textAlign: 'center',
              },
            },
          },
          {
            component: Icon,
            props: {
              type: iconTypes.check,
              color: color.green,
            },
            innerProps: {
              style: {
                alignItems: 'center',
                textAlign: 'center',
              },
            },
          },
        ],
        [
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
              as: "th",
            },
            innerProps: {
              style: {
                alignItems: 'flex-end',
                textAlign: 'right',
              }
            },
            props: {
              className: 'textGrey',
            },
            children: "Vodafone Station",
          },
          {
            component: Icon,
            props: {
              type: iconTypes.check,
              color: color.green,
            },
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
            innerProps: {
              style: {
                alignItems: 'center',
                textAlign: 'center',
              },
            },
          },
          {
            component: Icon,
            props: {
              type: iconTypes.check,
              color: color.green,
            },
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
            innerProps: {
              style: {
                alignItems: 'center',
                textAlign: 'center',
              },
            },
          },
          {
            component: Icon,
            props: {
              type: iconTypes.close,
              color: color.red,
            },
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
            innerProps: {
              style: {
                alignItems: 'center',
                textAlign: 'center',
              },
            },
          },
        ],
        [
          {},
          {
            component: Link,
            props: {
              type: linkTypes.pdf,
              label: "Preisliste 2019",
              href: "#link-to-pdf",
            }
          },
          {
            component: Link,
            props: {
              type: linkTypes.external,
              label: "Visit website",
              href: "/link-to-pdf",
            }
          },
          {
            component: Link,
            props: {
              type: linkTypes.pdf,
              label: "Preisliste 2019",
              href: "#link-to-pdf",
            }
          },
        ],
      ]
    }),
    []
  );
  return (
    <Table data={data} />
  );
};