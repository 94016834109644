import React from 'react';
import styled from 'styled-components/macro';
import { bp } from '#theme';
import { H1 } from '../H1/H1';

export const StyledH2 = styled(H1)`
  line-height: 1.15;
  font-size: 1.5rem;
  @media ${bp.p} {
    font-size: 2rem;
  }
  @media ${bp.d} {
    font-size: 2.125rem;
  }
`;

export const H2 = ({ children, ...restProps }) => {
  return (
    <StyledH2 as="h2" {...restProps}>{children}</StyledH2>
  );
};
