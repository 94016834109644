export const teaserThemes = {
  text: "text",
  combo: "combo",
  image: "image",
  fullImage: "fullimage",
};
export const teaserVisualTypes = {
  image: "image",
  icon: "icon",
};

export const teaserLayoutTypes = {
  three: "three",
  four: "four",
}