import React from 'react';
import PropTypes from 'prop-types';

import { useMediaQuery } from 'react-responsive';

import styled from 'styled-components/macro';
import { xz, bp } from '#theme';
import { Headline, H1, H3, Grid, Col, Wrapper } from '#ui';
import { getGridListChunk } from '#utils';
import { contextThemeTypes } from '#helpers';

const Value = styled(H1)`
  font-size: 2.25rem;
  @media ${bp.p} {
    font-size: 2.875rem;
  }
  @media ${bp.d} {
    font-size: 3rem;
  }
`;

const Body = styled.div`
  max-width: 360px;
  margin: 0 auto;
  padding-bottom: ${xz(2)};
  @media ${bp.m} {
    max-width: none;
  }
`;
const Item = styled.div`
  text-align: center;
  padding: ${xz(4)} 12px ${xz(4)};
  @media ${bp.p} {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media ${bp.d} {
    padding: ${xz(4)} 24px ${xz(6)};
  }
`;
const Caption = styled(H3)`
  @media ${bp.d} {
    margin: ${xz(4)} 0;
  }
  ${({themeStyles}) => themeStyles}
`;
const Text = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  @media ${bp.p} {
    font-size: 1.125rem;
  }
  @media ${bp.d} {
    font-size: 1.25rem;
  }
`;

const ListItem = ({
  value,
  caption,
  text,
  theme = contextThemeTypes.default,
}) => {
  return (
    <Item>
      <Value>{value}</Value>
      <Caption className="textGrey">{caption}</Caption>
      {text && (
        <Text>
          {text}
        </Text>
      )}
    </Item>
  );
};

ListItem.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  caption: PropTypes.string.isRequired,
  text: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(contextThemeTypes)),
};

export const KeyFigures = ({
  headline,
  list,
}) => {
  const isMobile = useMediaQuery({ query: bp.m });
  const isPhablet = useMediaQuery({ query: bp.p });
  const isDesktop = useMediaQuery({ query: bp.d });

  const itemsInChunk = !isMobile
    ? 1
    : !isPhablet
      ? 2
      : !isDesktop
        ? 3
        : 4;
  const chunkedList = getGridListChunk(list, itemsInChunk);
  const gridColumns = itemsInChunk * 2;
  return (
    <Wrapper>
      {headline && <Headline>{headline}</Headline>}
      <Body>
        <Grid columns={gridColumns} rowGap={"0px"}>
          {chunkedList.map((row, rowKey) => {
            const left = (gridColumns - row.length * 2) / 2;
            const width = gridColumns - left * 2;
            return (
              <Col key={rowKey} left={left + 1} width={width}>
                <Grid columns={row.length}>
                  {row.map((item, key) => (
                    <Col key={key}>
                      <ListItem {...item} />
                    </Col>
                  ))}
                </Grid>
              </Col>
            );
          })}
        </Grid>
      </Body>
    </Wrapper>
  );
};

KeyFigures.propTypes = {
  headline: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape(ListItem.propTypes)
  ).isRequired,
};