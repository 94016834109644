import { color, defTransition } from '#theme';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { ButtonIcon } from '../ButtonIcon/ButtonIcon';
import { iconTypes } from '../Icon/Icon';

const List = styled.div`

`;

const Item = styled(Link)`
  display: flex;
  align-items: center;
  line-height: 1.14;
  font-size: 0.875rem;
  padding: 0 24px;
  text-decoration: none;
  position: relative;
  margin-top: -1px;
  transition: background-color ${defTransition};
  &:hover {
    background-color: ${color.lightestGrey};
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 24px;
    right: 24px;
    border-bottom: 1px solid ${color.lightestGrey};
  }
`;

const Box =styled.div`
  flex: 1 0 0%;
  overflow: hidden;
`;
const Inner = styled.div`
  display: flex;
  transition: transform ${defTransition};
  transform: translateX(${({ isEditModeEnabled, isEditable }) => isEditable && !isEditModeEnabled ? '-36px' : 0});
`;
const Text = styled.span`
  flex: 1 0 0%;
  padding: 12px 0;
`;

const Status = styled.span`
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ isUpdated }) => isUpdated && css`
    &::after {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${color.red};
    }
  `}
`;

const ButtonDelete = styled(ButtonIcon)`
  fill: ${color.red};
  margin-right: 4px;
`;

export const UserMenuList = ({ list,
  handleDelete,
  isEditable = false,
  isEditModeEnabled = false,
}) => {
  const handleClick = (e) => {
    if (isEditModeEnabled) {
      e.preventDefault();
    }
  };
  return (
    <List>
      {list.map(({ path, title, isUpdated }, key) => {
        const handleDeleteClick = () => handleDelete(path);
        return (
          <Item key={key} to={path} onClick={handleClick}>
            <Box>
              <Inner isEditModeEnabled={isEditModeEnabled} isEditable={isEditable}>
                {isEditable && (
                  <ButtonDelete type={iconTypes.delete} onClick={handleDeleteClick} isActive={isEditable} />
                )}
                <Text>{title}</Text>
              </Inner>
            </Box>
            <Status isUpdated={isUpdated}/>
          </Item>
        )
      })}
    </List>
  );
};