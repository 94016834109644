import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';
import { xz, bp } from '#theme';
import { Headline, H3, Grid, Col, Wrapper } from '#ui';
import { getGridListChunk } from '#utils';

const IconContainer = styled.svg`
  width: 96px;
  height: 96px;
  fill: none;
  @media ${bp.p} {
    width: 128px;
    height: 128px;
  }
`;

const Body = styled.div`
  max-width: 360px;
  margin: 0 auto;
  @media ${bp.m} {
    max-width: none;
  }
`;
const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${xz(3)} 12px ${xz(6)};
  @media ${bp.p} {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media ${bp.d} {
    padding: ${xz(4)} 24px ${xz(6)};
  }
`;
const Caption = styled(H3)`
  @media ${bp.d} {
    margin: ${xz(4)} 0;
  }
`;
const Text = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  @media ${bp.p} {
    font-size: 1.125rem;
  }
  @media ${bp.d} {
    font-size: 1.25rem;
  }
`;

const ListItem = ({ Icon, caption, text }) => {
  return (
    <Item>
      <IconContainer as={Icon} />
      <Caption>{caption}</Caption>
      {text && (
        <Text>
          {text}
        </Text>
      )}
    </Item>
  );
};

ListItem.propTypes = {
  Icon: PropTypes.object.isRequired,
  caption: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export const Iconbox = ({ headline, list }) => {
  const isMobile = useMediaQuery({ query: bp.m });
  const isPhablet = useMediaQuery({ query: bp.p });
  const isDesktop = useMediaQuery({ query: bp.d });
  const itemsInChunk = !isMobile
    ? 1
    : !isPhablet
      ? 2
      : !isDesktop
        ? 3
        : 4;
  const chunkedList = getGridListChunk(list, itemsInChunk);
  const gridColumns = itemsInChunk * 2;
  return (
    <Wrapper>
      {headline && <Headline>{headline}</Headline>}
      <Body>
        <Grid columns={gridColumns} rowGap={"0px"}>
          {chunkedList.map((row, rowKey) => {
            const left = (gridColumns - row.length * 2) / 2;
            const width = gridColumns - left * 2;
            return (
              <Col key={rowKey} left={left + 1} width={width}>
                <Grid columns={row.length}>
                  {row.map((item, key) => (
                    <Col key={key}>
                      <ListItem {...item} />
                    </Col>
                  ))}
                </Grid>
              </Col>
            );
          })}
        </Grid>
      </Body>
    </Wrapper>
  );
};

Iconbox.propTypes = {
  headline: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape(ListItem.propTypes)
  ).isRequired,
};