import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useKeyPress } from '#hooks';

import { color, expander, fadeIn, fadeOut, SvgPlus, defTransition } from '#theme';
import { ButtonDefault } from '../ButtonDefault/ButtonDefault';
import { rgba } from 'polished';

const Container = styled.div`
  ${expander}
  position: fixed;
  z-index: 999;
  background-color: ${rgba(color.black, 0.95)};
  ${({ isMount }) => isMount ? fadeIn : fadeOut}
`;
const CloseButton = styled(ButtonDefault)`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 5;
`;
const CloseIcon = styled(SvgPlus)`
  width: 32px;
  height: 32px;
  transform: rotate(45deg);
  fill: ${color.white};
  transition: fill ${defTransition};
  ${CloseButton}:hover & {
    fill: ${color.red};
  }
`;

export const Modal = ({ children, hideModal }) => {
  const [isMount, setMount] = useState(false);
  useEffect(() => {
    if (!isMount) {
      setMount(true);
    }
  }, [ isMount ]);
  useKeyPress('Escape', { onPressUp: hideModal });
  return (
    <Container isMount={isMount}>
      <CloseButton onClick={hideModal}>
        <CloseIcon />
      </CloseButton>
      {children}
    </Container>
  );
};

export const getLightboxComponent = (children, hideModal) => (
  <Modal hideModal={hideModal}>
    {children}
  </Modal>
)

