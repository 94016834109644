import React, { useContext } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';

import { color, xz, defTransition } from '#theme';
import { H1, StyledH2, StyledH3, Wrapper } from '#ui';
import { contextThemeTypes, contextLevelTypes, ThemeContext } from '#helpers';

const tick = (c) => `"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cpath d='M6 12.417L1.758 8.175a.999.999 0 1 1 1.414-1.414L6 9.589l7.071-7.071a.999.999 0 1 1 1.414 1.414L6 12.417z' fill='${encodeURIComponent(c)}'/%3E%3C/svg%3E"`;

const rteStylesWhite = css`
  color: ${color.white};
  h1, h2, h3, p, a, a:link, a:visited, li::before {
    color: ${color.white};
  }
  a, a:link, a:visited, a:hover, a:active {
    border-bottom-color: ${color.white};
  }
  svg {
    fill: ${color.white};
  }
  .highlight {
    background-color: ${color.white};
    color: ${color.red};
  }
  h1.textGrey, h2.textGrey, h3.textGrey {
    color: ${color.white};
  }
  s, del {
    text-decoration-color: ${color.white};
  }
  ul[type=square] > li::before {
    content: url(${tick(color.white)});
  }
`

const Container = styled.div`
  h1 {
    ${H1.componentStyle.rules.join("")}
  }
  h2 {
    ${StyledH2.componentStyle.rules.join("")}
  }
  h3 {
    ${StyledH3.componentStyle.rules.join("")}
  }
  p {
    margin: 0;
    & + p {
      margin: 1em 0 0;
    }
  }
  a {
    padding-bottom: 1px;
    transition: color ${defTransition}, border-bottom-color ${defTransition};
    &, &:link, &:visited {
      color: ${color.red};
      text-decoration: none;
      border-bottom: 1px solid ${color.red};
    }
    &:hover, &:active {
      color: ${color.maroon};
      border-bottom-color: ${color.maroon};
    }
  }
  s, del {
    position: relative;
    text-decoration-color: ${color.red};
  }
  span {
    padding: 2px 1px;
    margin: 0 -1px;
  }
  svg {
    position: relative;
    bottom: -2px;
    fill: ${color.red};
  }
  ul, ol {
    margin: ${xz(2)} 0;
    padding: 0;
    & + &, ul, ol {
      margin: ${xz(1)} 0 ${xz(1)} 32px;
    }
  }
  ol {
    counter-reset: counter-root;
  }
  li {
    display: block;
    padding-left: 32px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      line-height: 1.75;
      display: inline-flex;
      justify-content: center;
      color: ${color.red};
      box-sizing: border-box;
    }
  }
  ul > li::before {
    content: "•";
  }
  ul[type=square] > li::before {
    content: url(${tick(color.red)});
    line-height: 1.9;
  }
  ol > li {
    counter-increment: counter-root;
    ::before {
      content: counter(counter-root) ".";
      font-weight: bold;
      line-height: 1.5;
      padding-right: 8px;
      justify-content: flex-end;
    }
  }
  .highlight {
    color: ${color.white};
    background-color: ${color.red};
  }
  ${theme('bg', {
    [contextThemeTypes.image]: rteStylesWhite,
    [contextThemeTypes.red]: rteStylesWhite,
  })}
`;

export const Rte = ({ children, level = contextLevelTypes.root }) => {
  const {
    level: contextLevel = contextLevelTypes.root,
  } = useContext(ThemeContext);
  const isInside = level === contextLevelTypes.inside || contextLevel === contextLevelTypes.inside;
  const content = <Container dangerouslySetInnerHTML={{ __html: children }} />
  return isInside
    ? content
    : (
      <Wrapper>
        {content}
      </Wrapper>
    );
};
