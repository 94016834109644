import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import { xz, bp, color } from '#theme';
import { contextThemeTypes } from '#helpers';

export const H1 = styled.h1`
  color: ${color.red};
  margin: ${xz(3)} 0;
  font-weight: normal;
  line-height: 1.1;
  font-size: 1.75rem;
  @media ${bp.p} {
    font-size: 2.25rem;
  }
  @media ${bp.d} {
    font-size: 3rem;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.textGrey {
    color: ${color.textGrey};
  }
  ${theme('bg', {
    [contextThemeTypes.red]: css`
      color: ${color.white};
      &.textGrey {
        color: ${color.white};
      }
    `,
    [contextThemeTypes.red45]: css`
      color: ${color.white};
      &.textGrey {
        color: ${color.white};
      }
    `,
    [contextThemeTypes.image]: css`
      color: ${color.white};
      &.textGrey {
        color: ${color.white};
      }
    `,
  })}
`;
