import React, { useState, useEffect } from 'react';
import styled, { css } from "styled-components/macro";
import { rgba } from 'polished';
import { color, xz, clearBtn, SvgWidgetArrow, defTransition } from '#theme';
import theme from 'styled-theming';
import { themeKeys, contextThemeTypes, ThemeProvider } from '#helpers';
import { WidgetPoll } from '../WidgetPoll/WidgetPoll';
import { WidgetEvent } from '../WidgetEvent/WidgetEvent';
import { WidgetScore } from '../WidgetScore/WidgetScore';
import SwipeableViews from 'react-swipeable-views';

export const widgetTypes = {
  poll: "poll",
  event: "event",
  score: "score",
};

const widgetBody = {
  [widgetTypes.poll]: WidgetPoll,
  [widgetTypes.event]: WidgetEvent,
  [widgetTypes.score]: WidgetScore,
}

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const Main = styled.div`
  background-color: ${color.lightestGrey};
  box-shadow: 0px 24px 48px ${rgba(color.black, 0.5)};
  height: 100%;
  display: flex;
  flex-direction: column;
  ${theme(themeKeys.bg, {
    [contextThemeTypes.black]: css`
      background-color: ${rgba(color.black, 0.5)};
      backdrop-filter: blur(8px);
      box-shadow: 0 24px 48px ${rgba(color.black, 0.75)}, 0 0 0 1px ${color.black} inset;
    `
  })}
`;
const Header = styled.div`
  padding: ${xz(2)} ${xz(2)};
  display: flex;
`;


const ArrowPrev = styled(SvgWidgetArrow)`
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  fill: ${color.middleGrey};
`;
const ArrowNext = styled(ArrowPrev)`
  transform: rotate(0deg);
`;

const Btn = styled.button`
  ${clearBtn}
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background-color ${defTransition};
  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
  &:not(:disabled):hover {
    background-color: ${color.white};
  }
`;
const Headline = styled.div`
  flex: 1 0 0%;
  color: ${color.textGrey};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.03em;
  height: 40px;
  font-weight: bold;
  ${theme(themeKeys.bg, {
    [contextThemeTypes.black]: css`
      color: ${color.white};
    `
  })}
`;
const Title = styled.span`
`;
const Pages = styled.span`
  margin-left: 0.5em;
`;
const PageNumber = styled.span`
  color: ${color.middleGrey};
`;
const Body = styled.div`
  flex: 1 0 0%;
  display: flex;
`;

export const Widget = ({
  title,
  list,
  theme,
}) => {
  const pageNumber = list.length;
  const [page, setPage] = useState(0);
  const [leftDisabled, disableLeft] = useState(false);
  const [rightDisabled, disableRight] = useState(false);

  const handlePrev = () => {
    if (leftDisabled) return;
    setPage(page - 1);
  };
  const handleNext = () => {
    if (rightDisabled) return;
    setPage(page + 1);
  };
  useEffect(() => {
    const getArrowToDisable = () => {
      const leftShouldBeDisabled = page <= 0;
      const rightShouldBeDisabled = page >= list.length - 1;
      return [
        leftShouldBeDisabled,
        rightShouldBeDisabled
      ];
    }
    const handleArrowsDisable = () => {
      const [left, right] = getArrowToDisable();
      disableLeft(left);
      disableRight(right);
    }
    handleArrowsDisable();
  }, [page, list, disableLeft, disableRight]);
  const renderList = ({ type, props }, key) => {
    const Component = widgetBody[type];
    return (
      <Component key={key} {...props} />
    )
  };
  const showArrows = pageNumber > 1;
  return (
    <Container>
      <ThemeProvider theme={theme}>
        <Main>
          <Header>
            {showArrows && (
              <Btn onClick={handlePrev} disabled={leftDisabled}>
                <ArrowPrev />
              </Btn>
            )}
            <Headline>
              <Title>{title}</Title>{pageNumber > 1 && (
                <Pages>
                  {page + 1}<PageNumber>/{pageNumber}</PageNumber>
                </Pages>
              )}
            </Headline>
            {showArrows && (
              <Btn onClick={handleNext} disabled={rightDisabled}>
                <ArrowNext />
              </Btn>
            )}
          </Header>
          <Body>
            <SwipeableViews
              index={page}
              style={{
                flex: '1 0 0%'
              }}
              containerStyle={{
                height: '100%'
              }}
            >
              {list.map(renderList)}
            </SwipeableViews>
          </Body>
        </Main>
      </ThemeProvider>
    </Container>
  )
};