import React from 'react';
import styled from 'styled-components/macro';

export const StyledImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
`;

export const Image = (props) => {
  return (
    <StyledImage {...props} />
  );
};
