import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';

import { xz, color, clearFigure, bp } from '#theme';
import { Headline, Wrapper, Player, playerProportions } from '#ui';

const Container = styled.figure`
  ${clearFigure}
`;
const Caption = styled.figcaption`
  font-size: 0.75rem;
  line-height: 1.25;
  color: ${color.grey};
  margin-top: ${xz(2)};
  text-align: center;
  @media ${bp.p} {
    font-size: 0.875rem;
    margin-top: ${xz(4)};
  }
  @media ${bp.d} {
    font-size: 1rem;
  }
`;

export const Video = ({
  headline,
  src,
  poster,
  caption,
  proportions = playerProportions["16:9"]
}) => {
  const isPhablet = useMediaQuery({ query: bp.p });
  const captionProps = {
    left: !isPhablet ? 1 : 3,
    width: !isPhablet ? 4 : 8,
  }
  const box = (
    <Player
      src={src}
      poster={poster}
      controls={true}
      proportions={proportions}
    />
  );
  return (
    <Container>
      {headline && (
        <Wrapper {...captionProps}>
          <Headline>{headline}</Headline>
        </Wrapper>
      )}
      {!isPhablet
        ? box
        : (
          <Wrapper>
            {box}
          </Wrapper>
        )
      }
      {caption && (
        <Wrapper {...captionProps}>
          <Caption>{caption}</Caption>
        </Wrapper>
      )}
    </Container>
  );
};

Video.propTypes = {
  headline: PropTypes.string,
  src: PropTypes.string.isRequired,
  poster: PropTypes.string,
};