import React from 'react';
import styled from 'styled-components/macro';
import { bp, gridOverlay } from '#theme';
import { Wrapper } from '#ui';
import { rgba } from 'polished';

const GridContainer = styled(Wrapper)`
  opacity: 0.5;
  z-index: 1000;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${props => !props.isActive ? 'none' : 'flex'};
`;
const GridGrid = styled.div`
  height: 100%;
  flex: 1 0 0%;
  display: flex;
`;
const GridInner = styled.div`
  overflow: hidden;
  flex: 1 0 0%;
  display: flex;
`;
const Overlay = styled.div`
  flex: 1 0 0%;
  pointer-events: none;
  background-color: ${rgba("pink", 0.5)};
  ${gridOverlay(12, 4)}
  @media ${bp.p} {
    ${gridOverlay(12)}
  }
  @media ${bp.t} {
    ${gridOverlay(24)}
  }
`;

export class GridOverlay extends React.Component {
  localStorageKey = "gridOverlayIsActive";
  constructor(props) {
    super(props);
    this.state = {
      isActive: this.initIsActiveValue(),
    };
    document.addEventListener("keydown", this.handleKeyUp);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyUp);
  }
  initIsActiveValue = () => {
    const currentLSValue = localStorage.getItem(this.localStorageKey);
    return currentLSValue !== undefined ? Boolean(parseInt(currentLSValue)) : false;
  }
  handleKeyUp = (event) => {
    if((event.ctrlKey || event.metaKey) && event.which === 71) {
      event.preventDefault();
      const isActiveNew = !this.state.isActive;
      this.setState({
        isActive: isActiveNew,
      });
      localStorage.setItem(this.localStorageKey, isActiveNew | 0);
      return false;
    }
  }
  render() {
    return (
      <GridContainer isActive={this.state.isActive}>
        <GridGrid>
          <GridInner>
            <Overlay />
          </GridInner>
        </GridGrid>
      </GridContainer>
    );
  }
}