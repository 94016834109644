import React from 'react';
import styled from 'styled-components/macro';
import useFitText from "use-fit-text";
import { rgba } from 'polished';

import { color } from '#theme';

const Span = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${props => props.isVisible ? "visible": "hidden"};
  background-color: ${rgba(color.black, 0.05)};
  padding: 8px;
  border-radius: 4px;
  font-weight: bold;
  color: ${color.red};
  ${props => props.isCircle && `
    width: 80px;
    height: 80px;
    border-radius: 50%;
  `}
`;
const Inner = styled.div`
  flex: 1 0 0%;
  max-width: 100%;
`;
const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-size: ${({ fontSize }) => fontSize};
`;

export const UpdateStatus = ({ isUpdated, isCircle, children, }) => {
  const { fontSize, ref } = useFitText();
  return (
    <Span isVisible={isUpdated} isCircle={isCircle}>
      <Inner ref={ref}>
        <Text fontSize={fontSize}>
          {children}
        </Text>
      </Inner>
    </Span>
  );
};