import React, { useContext } from 'react';
import theme from 'styled-theming';
import styled, { css, ThemeContext, ThemeProvider } from 'styled-components/macro';
import { useMediaQuery } from 'react-responsive';

import { color, bp, xz, defTransition, overlay } from '#theme';
import { Grid, Col, H2, Wrapper, Headline } from '#ui';
import { Link } from '../Link/Link';
import { contextThemeTypes, themeKeys } from '#helpers';
import { rgba } from 'polished';
import { getGridListChunk } from '#utils';

const Body = styled.div`
  padding-top: ${xz(3)};
  @media ${bp.m} {
    padding-top: ${xz(4)};
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${color.white};
  height: 100%;
  padding-bottom: ${xz(3)};
  transition: box-shadow ${defTransition};
  &:hover {
    box-shadow: 0px 0px 24px ${rgba(color.textGrey, 0.25)};
  }
  @media ${bp.m} {
    padding-bottom: ${xz(4)};
  }
  ${theme(themeKeys.bg, {
    [contextThemeTypes.lightestGrey]: css`
      background-color: ${color.lightestGrey};
    `
  })}
`;

const Image = styled.div`
  padding-bottom: 56.25%;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  ${theme(themeKeys.bg, {
    [contextThemeTypes.default]: css`
      position: relative;
      ${overlay(`
        background-color: ${rgba(color.black, 0.05)};
      `)}
    `
  })}
`;
const IconContainer = styled.div`
  width: 80px;
  height: 80px;
  @media ${bp.p} {
    width: 96px;
    height: 96px;
  }
  @media ${bp.t} {
    width: 112px;
    height: 112px;
  }
`;
const Content = styled.div`
  flex: 1 0 0%;
  padding: ${xz(1)} ${xz(2)} 0;
  margin-top: ${xz(3)};
  @media ${bp.m} {
    padding: ${xz(2)} ${xz(3)} 0;
  }
  @media ${bp.t} {
    padding-left: ${xz(4)};
    padding-right: ${xz(4)};
  }
`;
const Title = styled(H2)`
`;
const Text = styled.div`
  color: ${color.textGrey};
`;

const Links = styled.div`
  margin-top: ${xz(4)};
  padding: 0 ${xz(2)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media ${bp.m} {
    padding: 0 ${xz(3)};
  }
  @media ${bp.t} {
    padding: 0 ${xz(4)};
  }
`;

const boxBgTypes = {
  [contextThemeTypes.default]: contextThemeTypes.lightestGrey,
  [contextThemeTypes.lightestGrey]: contextThemeTypes.default,
  [contextThemeTypes.red]: contextThemeTypes.default,
};

const ListItem = ({
  image,
  Icon,
  title,
  text,
  links,
}) => (
  <Box>
    {image && <Image image={image} />}
    <Content>
      {Icon && (
        <IconContainer>
          <Icon />
        </IconContainer>
      )}
      <Title className="textGrey">{title}</Title>
      <Text>{text}</Text>
    </Content>
    <Links>
      {links.map((linkItem, key) => {
        return (
          <Link key={key} {...linkItem} />
        );
      })}
    </Links>
  </Box>
);

export const MultiTeasers = ({
  headline,
  list,
}) => {
  const {
    bg
  } = useContext(ThemeContext) || {
    bg: contextThemeTypes.default,
  }
  const isMobile = useMediaQuery({ query: bp.m });
  const isPhablet = useMediaQuery({ query: bp.p });
  const itemsInChunk = !isMobile
    ? 1
    : !isPhablet
      ? 2
      : 3;
  const chunkedList = getGridListChunk(list, itemsInChunk);
  const gridColumns = itemsInChunk * 2;
  return (
    <Wrapper>
      {headline && <Headline>{headline}</Headline>}
      <Body>
        <Grid columns={gridColumns} rowGap={"24px"}>
          {chunkedList.map((row, rowKey) => {
            const left = (gridColumns - row.length * 2) / 2;
            const width = gridColumns - left * 2;
            return (
              <Col key={rowKey} left={left + 1} width={width}>
                <Grid columns={row.length}>
                  {row.map((item, key) => (
                    <Col key={key}>
                      <ThemeProvider theme={{
                        bg: boxBgTypes[bg],
                      }}>
                        <ListItem {...item} />
                      </ThemeProvider>
                    </Col>
                  ))}
                </Grid>
              </Col>
            );
          })}
        </Grid>
      </Body>
    </Wrapper>
  );
};