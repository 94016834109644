import React from 'react';
import theme from 'styled-theming';
import styled, { css } from 'styled-components/macro';
import { contextThemeTypes, themeKeys } from '#helpers';
import { color } from '#theme';

export const StyledText = styled.div`
  &.textRed {
    color: ${color.red};
  }
  ${theme(themeKeys.bg, {
    [contextThemeTypes.red]: css`
      &, &.textRed {
        color: ${color.white};
      }
    `,
  })}
`;

export const Text = ({ children, ...props}) => (
  <StyledText {...props}>
    {children}
  </StyledText>
);