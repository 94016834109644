import { Rte, Image } from '#components';
import { images } from '#data';
import { H3, Text } from '#ui';
import { contextThemeTypes } from '#helpers';

export const tableExample2Data = {
  thead: [
    [
      {},
      {
        component: Image,
        innerProps: {
          style: {
            height: 60,
            alignItems: "center",
          },
        },
        props: {
          src: images.tableBosch,
        }
      },
      {
        component: Image,
        innerProps: {
          style: {
            height: 60,
            alignItems: "center",
          },
        },
        props: {
          src: images.tableDevolo,
        }
      },
      {
        component: Image,
        innerProps: {
          style: {
            height: 60,
            alignItems: "center",
          },
        },
        props: {
          src: images.tableGigaset,
        }
      },
      {
        component: Image,
        innerProps: {
          style: {
            height: 60,
            alignItems: "center",
          },
        },
        props: {
          src: images.tableInnogy,
        }
      },
      {
        component: Image,
        innerProps: {
          style: {
            height: 60,
            alignItems: "center",
          },
        },
        props: {
          src: images.tableMedion,
        }
      },
    ],
  ],
  tbody: [
    [
      {
        component: Text,
        cellProps: {
          as: "th",
        },
        innerProps: {
          style: {
            alignItems: "flex-end",
            textAlign: "right",
          },
        },
        props: {
          className: "textGrey",
        },
        children: `Starter Kit`,
      },
      {
        component: Rte,
        cellProps: {
          style: {
            justifyContent: "flex-start",
          },
        },
        children: `1× Hub<br>2× Tür und<br>Fenster sensor<br>1× Camera<br>1× Smarte Steckdose`,
      },
      {
        component: Rte,
        cellProps: {
          style: {
            justifyContent: "flex-start",
          },
        },
        children: `1× Hub<br>2× Tür und<br>Fenster sensor<br>1× Camera<br>1× Smarte Steckdose`,
      },
      {
        component: Rte,
        cellProps: {
          style: {
            justifyContent: "flex-start",
          },
        },
        children: `1× Hub<br>2× Tür und<br>Fenster sensor<br>1× Camera<br>1× Smarte Steckdose`,
      },
      {
        component: Rte,
        cellProps: {
          style: {
            justifyContent: "flex-start",
          },
        },
        children: `1× Hub<br>2× Tür und<br>Fenster sensor<br>1× Camera<br>1× Smarte Steckdose`,
      },
      {
        component: Rte,
        cellProps: {
          style: {
            justifyContent: "flex-start",
          },
        },
        children: `1× Hub<br>2× Tür und<br>Fenster sensor<br>1× Camera<br>1× Smarte Steckdose`,
      },
      
    ],
    [
      {
        component: Text,
        cellProps: {
          theme: contextThemeTypes.lightestGrey,
          as: "th",
        },
        innerProps: {
          style: {
            alignItems: "flex-end",
            textAlign: "right",
          },
        },
        props: {
          className: "textGrey",
        },
        children: `Hardware Kosten`,
      },
      {
        component: H3,
        cellProps: {
          theme: contextThemeTypes.lightestGrey,
        },
        children: "139,99 €",
      },
      {
        component: H3,
        cellProps: {
          theme: contextThemeTypes.lightestGrey,
        },
        children: "239,99 €",
      },
      {
        component: H3,
        cellProps: {
          theme: contextThemeTypes.lightestGrey,
        },
        children: "89,99 €",
      },
      {
        component: H3,
        cellProps: {
          theme: contextThemeTypes.lightestGrey,
        },
        children: "239,99 €",
      },
      {
        component: H3,
        cellProps: {
          theme: contextThemeTypes.lightestGrey,
        },
        children: "139,99 €",
      },
    ],
    [
      {
        component: Text,
        cellProps: {
          removeBorder: true,
          as: "th",
        },
        innerProps: {
          style: {
            alignItems: "flex-end",
            textAlign: "right",
          },
        },
        props: {
          className: "textGrey",
        },
        children: `Service-Kosten`,
      },
      {
        component: Text,
        cellProps: {
          removeBorder: true,
        },
        props: {
          theme: contextThemeTypes.red,
        },
        children: "4.95€ / Monat",
      },
      {
        component: Text,
        cellProps: {
          removeBorder: true,
        },
        props: {
          theme: contextThemeTypes.red,
        },
        children: "4.95€ / Monat",
      },
      {
        component: Text,
        cellProps: {
          removeBorder: true,
        },
        props: {
          theme: contextThemeTypes.red,
        },
        children: "4.95€ / Monat",
      },
      {
        component: Text,
        cellProps: {
          removeBorder: true,
        },
        props: {
          theme: contextThemeTypes.red,
        },
        children: "4.95€ / Monat",
      },
      {
        component: Text,
        cellProps: {
          removeBorder: true,
        },
        props: {
          theme: contextThemeTypes.red,
        },
        children: "4.95€ / Monat",
      },
    ],
    [
      {
        component: undefined,
      },
      {
        component: Rte,
        children: "24 Monate<br>Mindestlaufzeit",
      },
      {
        component: Rte,
        children: "24 Monate<br>Mindestlaufzeit",
      },
      {
        component: Rte,
        children: "24 Monate<br>Mindestlaufzeit",
      },
      {
        component: Rte,
        children: "24 Monate<br>Mindestlaufzeit",
      },
      {
        component: Rte,
        children: "24 Monate<br>Mindestlaufzeit",
      },

    ],
  ]
};