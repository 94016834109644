import { maxWrapperWidth, maxWrapperWidthWide } from "./screen";

export const wrapperProps = {
  paddings: `
    padding-left: 5%;
    padding-right: 5%;
  `,
  maxWidth: `max-width: calc(${maxWrapperWidth}px + 10%);`,
  maxWidthWide: `max-width: calc(${maxWrapperWidthWide}px + 10%);`,
};

export const easeInOutCubic = `cubic-bezier(0.645, 0.045, 0.355, 1)`;

export const defStyle = {
  easing: 'ease-in-out',
  duration: '.25s',
};