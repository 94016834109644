import React, { useEffect, useState } from "react";
import useMeasure from "react-use-measure";
import styled from "styled-components/macro";
import { ResizeObserver } from '@juggle/resize-observer';
import { color, expander, bp, maxWidth, screenSize, fadeIn, fadeOut } from "#theme";
import { Scrollbars } from "#ui";
import { rgba } from "polished";
import { useMediaQuery } from "react-responsive";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  ${({ isOpen }) => isOpen && expander}
`;
export const Page = styled.div`
  ${expander}
  height: 100%;
  background-color: ${color.white};
  overflow: hidden;
  @media ${maxWidth(screenSize.p)} {
    &::after {
      content: "";
      ${expander}
      z-index: 200;
      background-color: ${rgba(color.black, 0.5)};
      ${({ isOpen }) => isOpen ? fadeIn : fadeOut}
    }
  }
  @media ${bp.p} {
    z-index: 2;
    transform-origin: 0 0;
    transition: box-shadow 0.3s ease,
                border-radius 0.3s ease,
                transform 0.3s ease,
                transform-origin 0s linear;
    transition-delay: ${({ isOpen }) => isOpen ? '0s, 0s, 0s, 0s' : `0s, 0s, 0s, 0.3s`};
    box-shadow: 0 0 ${({ isOpen }) => (isOpen ? 20 : 0)}px rgba(0, 0, 0, 0.25);
    border-radius: ${({ isOpen }) => (isOpen ? 8 : 0)}px;
  }
`;
export const Sidebar = styled.div`
  z-index: 200;
  ${expander}
  left: auto;
  width: 320px;
  opacity: ${({ isOpen }) => isOpen ? 1 : 0};
  transform: translateX(${({ isOpen }) => isOpen ? 0 : 100}%);
  transition: opacity 0.3s ease, transform 0.3s ease;
  background-color: ${color.white};
  box-shadow: 0px 24px 48px ${rgba(color.black, 0.5)};
  @media ${bp.p} {
    transform: none;
    box-shadow: none;
    z-index: 1;
  }
  
  
`;
export const SidebarContent = styled.div`
  width: 320px;
`;


function useKeyPress(onPressUp = () => {}) {
  useEffect(() => {
    function upHandler(event) {
      if((event.ctrlKey || event.metaKey) && event.which === 66) {
        onPressUp();
      }
    }
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keyup", upHandler);
    };
  }, [onPressUp]);
}

export const BuilderWrapper = ({
  children
}) => {
  const isPhablet = useMediaQuery({ query: bp.p });
  const measureOptions = {
    scroll: false,
    debounce: {
      resize: 0,
    },
    polyfill: ResizeObserver,
  }
  const [containerRef, { width: containerWidth, height: containerHeight }] = useMeasure(measureOptions);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const paddings = 20 * 2;
  const sidebarWidth = 320;

  useKeyPress(() => {
    setSidebarOpen(!sidebarOpen);
  });
  const newScale = (containerWidth - sidebarWidth - paddings) / containerWidth;
  const scale = sidebarOpen ? newScale : 1;
  const origin = paddings / 2 / (sidebarWidth + paddings) * containerWidth;
  const pageStyle = isPhablet
    ? {
      height: sidebarOpen ? `${(containerHeight - paddings) / scale}px` : `100%`,
      transform: `scale(${scale}) translateZ(0)`,
      transformOrigin: `${origin}px ${origin}px`,
    }
    : {};
  return (
    <Container
      ref={containerRef}
      isOpen={sidebarOpen}
    >
      <Page
        style={pageStyle}
        isOpen={sidebarOpen}
      >
        <Scrollbars>
          {children}
        </Scrollbars>
      </Page>
      <Sidebar isOpen={sidebarOpen}>
        <SidebarContent>sidebar</SidebarContent>
      </Sidebar>
    </Container>
  );
}
