import styled from 'styled-components/macro';

export const ButtonDefault = styled.button`
  padding: 0;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;