import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { color, bp } from '#theme';
import { rgba } from 'polished';
import { Grid, Col } from '#ui';

export const teaserThemes = {
  text: "text",
  combo: "combo",
  image: "image",
  fullImage: "fullimage",
}
export const teaserGradient = `
  background: linear-gradient(180deg, ${rgba(color.black, 0.0627)} 54%, ${rgba(color.black, 0)} 100%);
`;

const Container = styled(({ hasGradient, isThemeFullImage, isThemeCombo, image, isFirst, imageIsIcon, ...rest }) => <Link {...rest} />)`
  display: flex;
  flex-flow: column;
  text-decoration: none;
  height: 100%;
  padding: 24px 24px 0;
  @media ${bp.m} {
    padding: 0;
    flex-flow: row;
  }
  @media ${bp.p} {
    flex-flow: column;
  }
  ${({ hasGradient }) => hasGradient && teaserGradient}
  ${({ isThemeFullImage, isFirst }) => isThemeFullImage && `
    margin: ${isFirst ? 0 : `12px`} 24px 0;
    padding-bottom: 128%;
    height: 0;
    position: relative;
    @media ${bp.m} {
      padding-bottom: 55.55%;
      margin: 0;
    }
  `}
  ${({ imageIsIcon, isThemeCombo }) => imageIsIcon && !isThemeCombo && `
    background-color: ${color.textGrey};
  `}
  ${({ isThemeFullImage, image }) => isThemeFullImage && image && `
    background-image: url(${image});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, ${rgba(color.black, 0)} 0%, ${rgba(color.black, 0.2)} 52%, ${rgba(color.black, 0.65)} 100%);
    }
  `}
`;
const Image = styled.div`
  width: 100%;
  padding-bottom: 66%;
  margin-bottom: 16px;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media ${bp.m} {
    margin-bottom: 0;
    height: 100%;
    background-size: ${({ imageIsIcon }) => imageIsIcon && '72px 72px'};
  }
  @media ${bp.p} {
    height: auto;
  }
  @media ${bp.t} {
    background-size: ${({ imageIsIcon }) => imageIsIcon && '96px 96px'};
  }
  @media ${bp.d} {
    background-size: ${({ imageIsIcon }) => imageIsIcon && '144px 144px'};
  }
`;
const Content = styled.div`
  flex: 1 0 0%;
  display: flex;
  flex-flow: column;
  padding-bottom: 44px;
  @media ${bp.m} {
    padding: 24px 24px 32px;
  }
  @media ${bp.p} {
    padding: 32px 24px 44px;
  }
  ${({ isThemeFullImage, image }) => isThemeFullImage && image && `
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 32px 24px;
    @media ${bp.m} {
      padding-bottom: 32px;
    }
  `}
  ${({ isThemeCombo, isFirst }) => isThemeCombo && `
    border-bottom: 2px solid ${color.white};
    @media ${bp.m} {
      border: 0;
      padding-right: ${isFirst ? 0 : 48}px;
      padding-bottom: 0;
    }
  `}
`;
const Date = styled.span`
  display: block;
  font-size: 16px;
  line-height: 1.5;
  color: ${({ isThemeFullImage, imageIsIcon, isThemeCombo }) => (isThemeFullImage || imageIsIcon) && !isThemeCombo ? rgba(color.white, .9) : color.textGrey};
`;
const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: ${color.textGrey};
  margin: 12px 0 16px;
  flex: 1 0 0%;
  ${({ isThemeCombo }) => isThemeCombo && `
    flex-grow: 0;
  `}
  ${({ isFirst, isThemeCombo }) => isFirst && isThemeCombo && `
    font-size: 28px;
    line-height: 1.125;
  `}
  ${({ isThemeFullImage }) => isThemeFullImage && `
    color: ${color.white};
    font-size: 32px;
    line-height: 1.125;
  `}
  ${({ imageIsIcon, isThemeCombo }) => imageIsIcon && !isThemeCombo && `
    color: ${color.white};
  `}
`;
const Category = styled.span`
  display: block;
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
  color: ${({ isThemeFullImage }) => isThemeFullImage ? color.white : color.red};
`;

export const TeaserOld = ({
  theme = teaserThemes.image,
  image,
  imageIsIcon,
  date,
  title,
  category,
  path,
  isFirst,
}) => {
  const isPhabletPortrait = useMediaQuery({ query: bp.m });
  const isPhablet = useMediaQuery({ query: bp.p });

  const isThemeFullImage = theme === teaserThemes.fullImage;
  const isThemeCombo = theme === teaserThemes.combo;
  const isThemeImage = theme === teaserThemes.image;
  const hasGradient = !isThemeCombo && !isThemeFullImage;
  const content = (
    <Content
      isFirst={isFirst}
      isThemeCombo={isThemeCombo}
      isThemeFullImage={isThemeFullImage}
      image={isThemeFullImage ? image : ''}
    >
      <Date
        isThemeFullImage={isThemeFullImage}
        isThemeCombo={isThemeCombo}
        imageIsIcon={imageIsIcon}
      >{date}</Date>
      <Title
        isFirst={isFirst}
        isThemeCombo={isThemeCombo}
        isThemeFullImage={isThemeFullImage}
        imageIsIcon={imageIsIcon}
      >{title}</Title>
      <Category isThemeFullImage={isThemeFullImage}>{category}</Category>
    </Content>
  );
  return (
    <Container to={path}
      isFirst={isFirst}
      hasGradient={hasGradient}
      isThemeFullImage={isThemeFullImage}
      isThemeCombo={isThemeCombo}
      image={isThemeFullImage ? image : ''}
      imageIsIcon={imageIsIcon}
    >
      {isThemeImage
        ? isPhabletPortrait && !isPhablet
          ? (
            <Grid columns={2}>
              <Col>
                <Image image={image} imageIsIcon={imageIsIcon} />
              </Col>
              <Col>
                {content}
              </Col>
            </Grid>
          )
          : (
            <React.Fragment>
              <Image image={image} imageIsIcon={imageIsIcon} />
              {content}
            </React.Fragment>
          )
         : content
      }
    </Container>  
  );
}