import Vodafone from './fonts/VodafoneRegular.woff';
import Vodafone2 from './fonts/VodafoneRegular.woff2';
import VodafoneBold from './fonts/VodafoneBold.woff';
import VodafoneBold2 from './fonts/VodafoneBold.woff2';
import VodafoneExtraBold from './fonts/VodafoneExtraBold.woff';
import VodafoneExtraBold2 from './fonts/VodafoneExtraBold.woff2';
import VodafoneLight from './fonts/VodafoneLight.woff';
import VodafoneLight2 from './fonts/VodafoneLight.woff2';

export const font = {
  headline: 'Vodafone, sans-serif',
  body: 'Vodafone, sans-serif',
};

export const fontFace = `
  @font-face {
    font-family: 'Vodafone';
    src: url('${VodafoneExtraBold2}') format('woff2'),
         url('${VodafoneExtraBold}') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Vodafone';
    src: url('${VodafoneLight2}') format('woff2'),
         url('${VodafoneLight}') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Vodafone';
    src: url('${VodafoneBold2}') format('woff2'),
         url('${VodafoneBold}') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Vodafone';
    src: url('${Vodafone2}') format('woff2'),
         url('${Vodafone}') format('woff');
    font-weight: normal;
    font-style: normal;
  }
`;