
import { color, defTransition } from '#theme';
import { ButtonDefault, UserMenuList } from '#ui';
import React, { useState } from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`

`;
const Foot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;
const Group = styled.div`
  display: flex;
`;
const FootBtn = styled(ButtonDefault)`
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1;
  color: ${color.lightGrey};
  transition: color ${defTransition};
  &:hover {
    color: ${color.middleGrey};
  }
  & + & {
    margin-left: 12px;
  }
`;
const FootBtnPrimary = styled(FootBtn)`
  color: ${color.red};
  &:hover {
    color: ${color.maroon};
  }
  &[disabled] {
    pointer-events: none;
    color: ${color.lightGrey};
  }
`;

export const Bookmarks = ({ list }) => {
  const [ isEditModeEnabled, setEditModeEnabled ] = useState(false);
  const [ hasBeenEdited, setHasBeenEdited ] = useState(false);
  const [ savedList, setSavedList ] = useState(list);
  const [ tempList, setTempList ] = useState(savedList);
  const handleEditMode = () => !isEditModeEnabled && setEditModeEnabled(true);
  const handleDelete = (path) => {
    const filteredList = tempList.filter((item) => item.path !== path);
    setTempList(filteredList);
    setHasBeenEdited(true);
  };
  const handleReset = () => {
    setTempList(savedList);
    setEditModeEnabled(false);
    setHasBeenEdited(false);
  };
  const handleSave = () => {
    setSavedList(tempList);
    setEditModeEnabled(false);
    setHasBeenEdited(false);
  };
  return (
    <Container>
      <UserMenuList
        list={tempList}
        isEditable={true}
        isEditModeEnabled={isEditModeEnabled}
        handleDelete={handleDelete}
      />
      <Foot>
        <Group>
          <FootBtnPrimary onClick={handleEditMode} disabled={isEditModeEnabled}>Bearbeiten</FootBtnPrimary>
        </Group>
        {isEditModeEnabled && (
          <Group>
            {hasBeenEdited && <FootBtn onClick={handleReset}>Abbrechen</FootBtn>}
            <FootBtnPrimary onClick={handleSave}>Speichern</FootBtnPrimary>
          </Group>
        )}
      </Foot>
    </Container>
  );
};