import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useMediaQuery } from 'react-responsive';

import { bp } from '#theme';
import { Cell } from 'styled-css-grid';

const GridComponent = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: ${({ flow }) => flow};
  grid-gap: 12px;
  ${({ columns }) => columns
    ? css`
      grid-template-columns: repeat(${columns},1fr);
    `
    : css`
      grid-template-columns: repeat(4,1fr);
      @media ${bp.p} {
        grid-template-columns: repeat(12,1fr);
      }
    `
  }
  @media ${bp.t} {
    grid-gap: 24px;
  }
`;

export const Grid = ({ columns, flow, children, ...rest }) => {
  return (
    <GridComponent flow={flow || "row"} columns={columns} {...rest}>
      {children}
    </GridComponent>
  );
};

export const Col = Cell;

export const Row = ({ left, width, children }) => {
  const isPhablet = useMediaQuery({ query: bp.p });
  return (
    <Col width={!isPhablet ? 4 : width || 12} left={left}>{children}</Col>
  );
};
