import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { Nav } from '../Nav/Nav';
import { Logo } from '../Logo/Logo';
import { UserMenu } from '../UserMenu/UserMenu';
import { iconTypes, ButtonIcon } from '#ui';
import { bp, color, maxWidth, screenSize } from '#theme';
import { SearchForm } from '../SearchForm/SearchForm';

const HeaderContainer = styled.header`

`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0 20px 16px;
  @media ${bp.m} {
    padding: 24px 24px 24px 32px;
  }
`;

const Brand = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const BrandLogo = styled.div`
  width: 32px;
  height: 32px;
  @media ${bp.m} {
    width: 48px;
    height: 48px;
  }
`;

const BrandTitle = styled.div`
  font-weight: 300;
  display: none;
  font-size: 1.75rem;
  color: ${color.red};
  margin-left: 16px;
  position: relative;
  top: -4px;
  b {
    font-weight: bold;
  }
  @media ${bp.p} {
    display: block;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Action = styled.div`
  margin-left: 8px;
  @media ${bp.m} {
    margin-left: 16px;
  }
`;

const Group = styled.div`
  flex: 1 0 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${bp.p} {
    flex: none;
    ${({ hasBranding }) => hasBranding && `
      margin-right: auto;
    `}
  }
  &:first-child {
    justify-content: flex-start;
  }
  &:last-child {
    justify-content: flex-end;
  }
`;

const Branding = ({ site }) => (
  <Brand to="/">
    <BrandLogo>
      <Logo />
    </BrandLogo>
    <BrandTitle>
      <b>{site.title}</b>
      {site.location}
    </BrandTitle>
  </Brand>
);

const NavSwitcher = ({ nav, isDesktop, isMobile }) => (
  <NavContainer isDesktop={isDesktop} isMobile={isMobile}>
    <Nav nav={nav} />
  </NavContainer>
);

const StyledSearchButton = styled(Action)`
  @media ${bp.p} {
    display: none;
  }
`;

const SearchButton = ({ onClick, htmlFor }) => (
  <StyledSearchButton onClick={onClick}>
    <ButtonIcon
      htmlFor={htmlFor}
      as="label"
      type={iconTypes.search}
    />
  </StyledSearchButton>
);

const StyledBookmarkButton = styled(Action)`
  @media ${maxWidth(screenSize.m)} {
    display: none;
  }
`;
const BookmarkButton = ({ onClick, isActive }) => (
  <StyledBookmarkButton onClick={onClick} >
    <ButtonIcon type={iconTypes.bookmark} isActive={isActive} />
  </StyledBookmarkButton>
);
const UserMenuButton = ({ onClick, isActive }) => (
  <Action onClick={onClick}>
    <ButtonIcon type={iconTypes.kebab} isActive={isActive} />
  </Action>
);

export const Header = ({
  search,
  user,
  site,
  nav,
}) => {
  const [userMenuIsOpened, setUserMenuIsOpened] = useState(false);
  const [searchIsOpened, setSearchIsOpened] = useState(false);
  const userMenuOpen = () => setUserMenuIsOpened(true);
  const userMenuСlose = () => setUserMenuIsOpened(false);
  const searchOpen = () => setSearchIsOpened(true);
  const searchСlose = () => setSearchIsOpened(false);
  const searchFieldId = "searchfield1";
  return (
    <HeaderContainer>
      <Container>
        <Group hasBranding={true}>
          <Branding site={site} />
        </Group>
        <Group>
          <NavSwitcher nav={nav} />
        </Group>
        <Group>
          <SearchButton onClick={searchOpen} htmlFor={searchFieldId} />
          <BookmarkButton isActive={false} onClick={() => {}} />
          <UserMenuButton onClick={userMenuOpen} isActive={userMenuIsOpened} />
        </Group>
      </Container>
      <SearchForm
        isOpened={searchIsOpened}
        handleOpen={searchOpen}
        handleClose={searchСlose}
        search={search}
        fieldId={searchFieldId}
      />
      <UserMenu
        isOpened={userMenuIsOpened}
        onClick={userMenuOpen}
        handleClose={userMenuСlose}
        user={user}
      />
    </HeaderContainer>
  );
}
