import React, { useMemo } from 'react';
import { Table, Rte } from '#components';
import { Text } from '#ui';
import { contextThemeTypes } from '#helpers';

export const TableExample4 = () => {
  const data = useMemo(
    () => ({
      thead: [
        [
          {
            cellProps: {
              style: {
                width: "128px",
              },
            },
          },
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.red,
              },
              as: "th",
            },
            children: "WLAN-Option",
          },
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.red,
              },
              as: "th",
            },
            children: "Hombebox Option (HB3)",
          },
        ],
      ],
      tbody: [
        [
          {
            component: Text,
            cellProps: {
              style: {
                width: "128px",
              },
            },
            innerProps: {
              style: {
                alignItems: 'flex-end',
                textAlign: 'right',
                fontWeight: 'bold',
              }
            },
            props: {
              className: "textRed",
            },
            children: "Image or Icon",
          },
          {
            component: Rte,
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
            children: "Bestandskunden, die heute die <b>2,00 € WLAN-Option</b> nutzen, bzahlen weiterhin 2€",
          },
          {
            component: Rte,
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
            children: "Bestandskunden ohne HB3 können die HB3 Option für <b>4,99 €</b> bestellen",
          },
        ],
        [
          {
            component: Text,
            cellProps: {
              style: {
                width: "128px",
              },
            },
            innerProps: {
              style: {
                alignItems: 'flex-end',
                textAlign: 'right',
                fontWeight: 'bold',
              }
            },
            props: {
              className: "textRed",
            },
            children: "Image or Icon",
          },
          {
            component: Rte,
            children: "Bestandskunden, die bereits die WLAN-Option für <b>2€ monatl.</b> nutzen und einen Produktwechsel (Upgrade) vornehmen, zahlen weiterhin 2,00€ für die Option",
          },
          {
            component: Rte,
            children: "Bestandskunden, die die HB3 für <b>4,99 €</b> nutzen, behalten bei einem Tarifwechsel (Up/Downgrade) die HB-Option für <b>4,99 €/Monat</b>",
          },
        ],
        [
          {
            component: Text,
            cellProps: {
              style: {
                width: "128px",
              },
            },
            innerProps: {
              style: {
                alignItems: 'flex-end',
                textAlign: 'right',
                fontWeight: 'bold',
              }
            },
            props: {
              className: "textRed",
            },
            children: "Big Type",
          },
          {
            component: Rte,
            children: "Bestandskunden die bereits die WLAN-Option <b>2€ monatl.</b> nutzen und im Rahmen der Wechselgarantie downgraden, zahlen 2,00 €",
          },
          {
            component: Rte,
            children: "Bestandskunden, die die HB3 für 5,00 € nutzen, behalten bei einem Tarifwechsel (Up-/Downgrade) di1e HB-Option für <b>5,00 €/Monat</b>",
          },
        ],
        [
          {
            component: Text,
            cellProps: {
              style: {
                width: "128px",
              },
            },
            innerProps: {
              style: {
                alignItems: 'flex-end',
                textAlign: 'right',
                fontWeight: 'bold',
              }
            },
            props: {
              className: "textRed",
            },
            children: "Headline",
          },
          {
            component: Rte,
            children: "Bestandskunden, die bisher keine <b>2,00 € WLAN-Option</b> nutzen (z.B. nur Router) und die Option nachträglich buchen wollen, erhalten die WLAN-Option für <b>2,99 € /Monat</b>",
          },
          {
            component: undefined,
          },
        ],
        [
          {
            component: Text,
            cellProps: {
              style: {
                minWidth: "1%",
                width: "100px",
                maxWidth: "100px",
              },
            },
            innerProps: {
              style: {
                alignItems: 'flex-end',
                textAlign: 'right',
                fontWeight: 'bold',
              }
            },
            props: {
              className: "textRed",
            },
            children: "Copy Text",
          },
          {
            component: Rte,
            children: "Bestandskunden, die bisher keine <b>2,00 € WLAN-Option</b> nutzen (z.B. nur Router) und die Option nachträglich buchen wollen, erhalten die WLAN-Option für <b>2,99 € /Monat</b>",
          },
          {
            component: undefined,
          },
        ],
        
      ]
    }),
    []
  );
  return (
    <Table data={data} />
  );
};