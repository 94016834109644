import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import styled, { css } from 'styled-components/macro';

import { expander, defTransition, color, clearBtn, SvgPlay, bp } from '#theme';
import { rgba } from 'polished';

export const playerProportions = {
  "16:9": 56.25,
  "3:2": (2/3),
}

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;
  ${({ autoPlay, isClicked, poster }) => !autoPlay && css`
    &::before {
      content: "";
      ${expander}
      background-image: url(${poster});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 3;
      display: ${isClicked ? "none" : "block"}
    }
    &::after {
      content: "";
      ${expander}
      background-color: ${rgba(color.black, 0.5)};
      z-index: 3;
      display: ${isClicked ? "none" : "block"}
    }
  `}
`;
const PlayerWrapper = styled.div`
  height: 100%;
  ${({ proportions }) => proportions && css`
    padding-bottom: ${proportions}%;
    height: 0;
  `}
`;
const StyledPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  video {
    object-fit: cover;
  }
`;
const PlayButton = styled.button`
  ${clearBtn}
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${rgba(color.red, 0.65)};
  position: relative;
  z-index: 5;
  transition: background-color ${defTransition};
  &:hover {
    background-color: ${color.red};
  }
  @media ${bp.p} {
    width: 128px;
    height: 128px;
  }
`;
const Icon = styled(SvgPlay)`
  width: 28px;
  height: 28px;
  fill: ${color.lightestGrey};
  @media ${bp.p} {
    width: 56px;
    height: 56px;
  }
`;

export const Player = ({
  src,
  controls = false,
  volume = 0,
  poster,
  autoPlay = false,
  loop = false,
  proportions,
}) => {
  const [isClicked, setClicked] = useState(false);
  const handleClick = () => setClicked(true);
  const playerProps = {
    url: src,
    width: "",
    height: "",
    controls: controls,
    volume: volume,
    preload: "auto",
    muted: autoPlay,
    autoPlay: autoPlay,
    playing: autoPlay || isClicked,
    loop: loop,
    playsinline: autoPlay,
  };
  return (
    <Container
      poster={poster}
      isClicked={isClicked}
      autoPlay={autoPlay}
    >
      <PlayerWrapper proportions={proportions}>
        <StyledPlayer { ...playerProps } />
      </PlayerWrapper>
      {(!autoPlay && !isClicked) && (
        <PlayButton onClick={handleClick}>
          <Icon />
        </PlayButton>
      )}
    </Container>
  );
};

Player.propTypes = {
  src: PropTypes.string.isRequired,
  poster: PropTypes.string,
};