import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import theme from 'styled-theming';
import styled, { css, ThemeContext } from 'styled-components/macro';
import SwipeableViews from 'react-swipeable-views';
import { useMediaQuery } from 'react-responsive';

import { Wrapper, Headline, ButtonDefault } from '#ui';
import { color, bp, SvgSliderArrow, defTransition, xz, SvgPlus } from '#theme';
import { rgba } from 'polished';
import { themeKeys } from '#helpers';
import { useKeyPress } from '#hooks';

export const galleryLayoutTypes = {
  default: "default",
  full: "full",
  lightbox: "lightbox",
}

const Container = styled.div`
  overflow: hidden;
  height: 100%;
`;

const Box = styled.div`
  position: relative;
  height: 100%;
  display: flex;
`;
const Controls = styled.div`
  height: 0;
  padding-bottom: 56.25%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  ${theme(themeKeys.layout, {
    [galleryLayoutTypes.lightbox]: css`
      padding-bottom: 0;
      height: auto;
      bottom: 0;
    `,
  })}
  
`;
const Inner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Figure = styled.figure`
  margin: 0;
  padding: 0 1px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const ImageContainer = styled.div`
  position: relative;
  flex: 1 0 0%;
  ${theme(themeKeys.layout, {
    [galleryLayoutTypes.lightbox]: css`
      background-color: transparent;
      margin-top: ${xz(8)};
      @media ${bp.m} {
        margin-top: ${xz(3)};
      }
      @media ${bp.p} {
        margin-top: ${xz(4)};
      }
    `,
  })}
`;

const Image = styled.div`
  height: 0;
  padding-bottom: 56.25%;
  ${theme(themeKeys.layout, {
    [galleryLayoutTypes.lightbox]: css`
      padding-bottom: 0;
      height: auto;
      bottom: 0;
    `,
  })}
`;
const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`;
const Caption = styled.figcaption`
  font-size: 0.75rem;
  line-height: 1.16;
  margin-top: ${xz(2)};
  text-align: center;
  @media ${bp.p} {
    margin-top: ${xz(4)};
    font-size: 0.875rem;
    line-height: 1.3;
  }
  @media ${bp.d} {
    font-size: 1rem;
    line-height: 1.25;
  }
  ${theme(themeKeys.layout, {
    [galleryLayoutTypes.lightbox]: css`
      color: ${color.white};
    `,
  })}
`;
const Nav = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 8px;
  ${theme(themeKeys.layout, {
    [galleryLayoutTypes.lightbox]: css`
      position: static;
      padding: ${xz(4)} 0;
    `,
  })}
`;
const NavList = styled.ul`
  display: flex;
  margin: 0 -6px;
  padding: 0;
  justify-content: center;
  pointer-events: all;
`;
const NavItem = styled.li`
  display: block;
  padding: 6px;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    background-color: ${({ isActive }) => !isActive ? color.lightGrey : color.red };
    border: 2px solid ${({ isActive }) => !isActive ? color.white : color.red };
    border-radius: 50%;
    width: 8px;
    height: 8px;
    @media ${bp.p} {
      width: 12px;
      height: 12px;
    }
  }
  ${({ isActive }) => theme(themeKeys.layout, {
    [galleryLayoutTypes.lightbox]: css`
      &::after {
        background-color: ${!isActive ? rgba(color.white, 0.25) : color.red};
        border: 2px solid transparent;
      }
    `,
  })}
`;
const Arrow = styled(ButtonDefault)`
  width: 32px;
  height: 64px;
  background-color: ${rgba(color.white, 0.75)};
  display: none;
  pointer-events: all;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  transition: background-color ${defTransition};
  ${({ isNext }) => isNext
    ? 'right: 0;'
    : 'left: 0;'
  }
  @media ${bp.p} {
    display: flex;
  }
  &:hover {
    background-color: ${color.white};
  }
`;
const Icon = styled(({isNext, ...props}) => <SvgSliderArrow {...props} />)`
  width: 32px;
  height: 32px;
  fill: ${color.red};
  transition: fill ${defTransition};
  transform: rotate(${({ isNext }) => !isNext ? -180 : 0}deg);
`;

const LightBoxButtonContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: 16px;
  right: 16px;
  @media ${bp.p} {
    top: 24px;
    right: 24px;
  }
  @media ${bp.d} {
    top: 32px;
    right: 32px;
  }
`;
const ButtonLightBox = styled(ButtonDefault)`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: ${rgba(color.black, 0.1)};
  transition: background-color ${defTransition};
  &:hover {
    background-color: ${color.red};
  }
  @media ${bp.p} {
    width: 48px;
    height: 48px;
  }
  @media ${bp.d} {
    width: 64px;
    height: 64px;
  }
`;
const ButtonLightBoxIcon = styled(SvgPlus)`
  width: 16px;
  height: 16px;
  fill: ${color.white};
  @media ${bp.p} {
    width: 24px;
    height: 24px;
  }
  @media ${bp.d} {
    width: 32px;
    height: 32px;
  }
`;

let updateHeight;

const ListItem = ({
  image,
  caption,
  layout,
}) => (
  <Figure>
    <ImageContainer>
      <Image>
        <Img src={image} />
      </Image>
    </ImageContainer>
    <Caption>
      {layout === galleryLayoutTypes.default
        ? caption
        : (
            <Wrapper>{caption}</Wrapper>
        )}
      </Caption>
  </Figure>
);

ListItem.propTypes = {

};

export const Gallery = ({
  defaultIndex = 0,
  headline,
  list,
  withLightbox,
  showModal,
  showLightboxIndex = () => {},
}) => {
  const {
    layout = galleryLayoutTypes.default
  } = useContext(ThemeContext) || {
    layout: galleryLayoutTypes.default,
  };
  const [index, setIndex] = useState(defaultIndex);
  const isPhablet = useMediaQuery({ query: bp.p });
  const handleIndex = (nextIndex) => {
    setIndex(nextIndex);
    showLightboxIndex(nextIndex);
  };
  const handleNext = () => {
    const nextIndex = index >= list.length - 1 ? 0 : index + 1;
    handleIndex(nextIndex);
  };
  const handlePrev = () => {
    const nextIndex = index <= 0 ? list.length - 1 : index - 1;
    handleIndex(nextIndex);
  };
  
  const onPress = (callback) => {
    if (layout === galleryLayoutTypes.lightbox) {
      callback();
    }
  };
  const onPressNext = () => {
    onPress(handleNext);
  };
  const onPressPrev = () => {
    onPress(handlePrev);
  };
  useKeyPress('ArrowLeft', { onPressUp: onPressPrev });
  useKeyPress('ArrowRight', { onPressUp: onPressNext });
  
  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    return _ => {
      window.removeEventListener('resize', updateHeight);
    }
  });
  const renderNavItem = (_, key) => {
    const onClick = () => {
      handleIndex(key);
    }
    return (
      <NavItem
        key={key}
        isActive={key === index}
        onClick={onClick}
      />
    )
  };
  const renderNav = (
    <Nav>
      <NavList>
        {list.map(renderNavItem)}
      </NavList>
    </Nav>
  );
  const renderSwipeableViews = (
    <SwipeableViews
      index={index}
      onChangeIndex={(i) => setIndex(i)}
      action={actions => {
        updateHeight = actions.updateHeight;
      }}
      style={{
        flex: '1 0 0%'
      }}
      containerStyle={{
        height: '100%'
      }}
      springConfig={{
        duration: '0.5s',
        easeFunction: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
        delay: '0s'
      }}
    >
      {list.map((post, key) => (
        <ListItem
          key={key}
          {...post}
        />
      ))}
    </SwipeableViews>
  );
  const renderBox = (
    <Box>
      {withLightbox && (
        <LightBoxButtonContainer>
          <ButtonLightBox onClick={showModal}>
            <ButtonLightBoxIcon />
          </ButtonLightBox>
        </LightBoxButtonContainer>
      )}
      {layout === galleryLayoutTypes.lightbox
        ? (
          <Wrapper>
            <Inner>
              {renderSwipeableViews}
              {renderNav}
            </Inner>
          </Wrapper>
        )
        : renderSwipeableViews}
      <Controls>
        <Arrow onClick={() => handlePrev()}>
          <Icon />
        </Arrow>
        <Arrow onClick={() => handleNext()} isNext>
          <Icon isNext />
        </Arrow>
        {layout !== galleryLayoutTypes.lightbox && renderNav}
      </Controls>
    </Box>
  )
  const renderContent = layout === galleryLayoutTypes.default
    ? (
      <Wrapper>
        {renderBox}
      </Wrapper>
    )
    : renderBox;

  return (
    <Container>
      {headline && (
        <Wrapper
          left={!isPhablet ? 1 : 3}
          width={!isPhablet ? 4 : 8}
        >
          <Headline>
            {headline}
          </Headline>
        </Wrapper>
      )}
      {renderContent}
    </Container>
  );
};

Gallery.propTypes = {
  headline: PropTypes.string,
  layout: PropTypes.string,
  withLightbox: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape(ListItem.propTypes)
  ).isRequired,
};