import React from 'react';
import { Page, iconTypes } from '#ui';
import { sectionWrapper } from '#hocs';
import {
  KeyVisual,
  keyVisualPositionTypes,
  buttonThemeTypes,
  AlertBanner,
  TeaserList,
  teaserVisualTypes,
  Carousel,
  carouselLayoutTypes,
  carouselComponentTypes,
  TeaserListCombo,
} from '#components';
import { contextThemeTypes } from '#helpers';
import { images, video } from '#data';
import { WidgetsSection } from '#components/WidgetsSection/WidgetsSection';
import { widgetTypes } from '#components/widget/Widget/Widget';

const carouselList = [
  {
    type: carouselComponentTypes.keyvisual,
    props: {
      noWrapper: true,
      image: images.carousel01,
      link: "#",
      date: '29.11.2019',
      category: 'Mobilfunk',
      headline: "Neuer Entertainment-Partner „Hatch“ – Mehr als 100 Premium-Games",
      theme: {
        bg: contextThemeTypes.image,
        position: keyVisualPositionTypes.bottomLeftDoubleSlider,
      }
    },
  },
  {
    type: carouselComponentTypes.keyvisual,
    props: {
      noWrapper: true,
      image: images.carousel02,
      link: "#",
      date: '14.11.2019',
      category: 'Mobilfunk',
      headline: "Mehr Datenfreiheit für die Jugend",
      theme: {
        bg: contextThemeTypes.image,
        position: keyVisualPositionTypes.bottomLeftDoubleSlider,
      }
    },
  },
  {
    type: carouselComponentTypes.keyvisual,
    props: {
      noWrapper: true,
      image: images.carousel03,
      link: "#",
      date: '31.10.2019',
      category: 'GigaTV',
      headline: "GigaTV inkl. HD Premium zum Vorteilspreis",
      theme: {
        bg: contextThemeTypes.image,
        position: keyVisualPositionTypes.bottomLeftDoubleSlider,
      }
    },
  },
  {
    type: carouselComponentTypes.keyvisual,
    props: {
      noWrapper: true,
      image: images.carousel04,
      link: "#",
      date: '29.10.2019',
      category: 'Hardware',
      headline: "Verkaufsstart HUAWEI nova 5T",
      theme: {
        bg: contextThemeTypes.image,
        position: keyVisualPositionTypes.bottomLeftDoubleSlider,
      }
    },
  },
];
const teaserList = [
  {
    date: '12.12.2019',
    category: 'Hardware',
    headline: {
      text: 'Update: GigaGeschenk Basispreis-Rabatt Young',
    },
    visual: {
      type: teaserVisualTypes.image,
      src: images.teaser01,
    },
    href: "#",
  },
  {
    date: '27.11.2019',
    category: 'Mobilfunk',
    headline: {
      text: 'Anschlusspreis-Befreiung ab der 2. Aktivierung',
    },
    visual: {
      type: teaserVisualTypes.image,
      src: images.teaser02,
    },
    href: "#",
  },
  {
    date: '20.11.2019',
    category: 'IoT',
    headline: {
      text: 'Sicherheit für das Kind: V-Kids Watch',
    },
    visual: {
      type: teaserVisualTypes.image,
      src: images.teaser03,
    },
    href: "#",
  },
];
const teaserListCombo = [
  {
    date: '26.11.2019',
    category: 'Hardware',
    headline: {
      text: 'Wichtige Hinweise für Ship2Store',
    },
    href: "#",
  },
  {
    date: '22.11.2019',
    category: 'Zubehör',
    headline: {
      text: 'Giga Black Deals',
    },
    href: "#",
  },
  {
    date: '19.11.2019',
    category: 'Zubehör',
    headline: {
      text: 'Starter Kit für Samsung Galaxy A40/A50',
    },
    href: "#",
  },
  {
    date: '14.11.2019',
    category: 'Mobilfunk',
    headline: {
      text: '120 Euro geschenkt für die Red+ Allnet Zusatzkarte',
    },
    href: "#",
  },
  {
    date: '14.11.2019',
    category: 'Mobilfunk',
    headline: {
      text: 'Handyversicherung und Zubehör für 30 Euro',
    },
    href: "#",
  },
];
const teaserListBgImage = [
  {
    theme: {
      bg: contextThemeTypes.image,
    },
    date: '26.11.2019',
    category: 'Hardware',
    headline: {
      text: 'Samsung Fest der Sterne!',
    },
    visual: {
      type: teaserVisualTypes.image,
      src: images.quad01,
    },
    href: "#",
  },
  {
    theme: {
      bg: contextThemeTypes.image,
    },
    date: '24.11.2019',
    category: 'Mobilfunk',
    headline: {
      text: 'GigaGeschenke: Red oder Young ab 9,99 €',
    },
    visual: {
      type: teaserVisualTypes.image,
      src: images.quad02,
    },
    href: "#",
  },
  {
    theme: {
      bg: contextThemeTypes.image,
    },
    date: '15.10.2019',
    category: 'Hardware',
    headline: {
      text: 'Verkaufsstart Samsung Galaxy Fold 5G',
    },
    visual: {
      type: teaserVisualTypes.image,
      src: images.quad03,
    },
    href: "#",
  },
];

const widgetList = [
  {
    title: "Event",
    theme: {
      bg: contextThemeTypes.black,
    },
    list: [
      {
        type: widgetTypes.event,
        props: {
          type: iconTypes.webinar,
          typeLabel: "eLearning",
          places: {
            available: 9,
            total: 18,
          },
          headline: "Basiswissen Verkauf – Grundausbildung SOHO Außendienstmitarbeiter",
          copytext: `<p>Dieses Training dient als Vorbereitung auf das 3. Modul des Verkaufstrainings und soll Ihnen dabei helfen, Ihre tägliche Arbeit als SOHO-Außendienstmitarbeiter besser, einfacher und erfolgreicher erledigen zu können.</p><p>Es bietet Ihnen in Form von 8 Kollektionen eine Mischung aus „verkaufsspezifischem“ Wissen, das Ihnen besonders in der eigentlichen Verkaufssituation nützen wird, und aus Wissen, das Ihnen hilft, Ihren Berufsalltag auch außerhalb des Verkaufsgesprächs besser bewältigen zu können.</p>`,
          startDate: "2020-01-23T18:00:00",
          endDate: "2020-03-23T18:00:00",
          image: {
            src: images.widgetEvent1,
          },
        }
      },
      {
        type: widgetTypes.event,
        props: {
          type: iconTypes.workshop,
          typeLabel: "Seminar",
          headline: "Alles wird Festnetz",
          copytext: `<p>Mit der Produktwelt Vodafone Festnetz hat Vodafone absolute Top Angebote auf dem Markt und gibt Dir so die Möglichkeit ein konvergentes Kundenerlebnis zu schaffen.</p><p>Wie Du diese Vermarktungspotentiale für Dich und Deine Kollegen optimal nutzt und neue Erlebniswelten für und mit Deinen Kunden schaffst, erarbeiten wir gemeinsam in diesem Workshop.</p><p>Natürlich sind Networking und der Austausch von Best Practice in der Teilnehmergruppe ebenfalls Teil der Workshop Agenda.</p><p>Wir unterstützen Dich, die Premium Produkte Kabel, DSL und TV zukünftig, erfolgreich und mit Freude zu vermarkten.</p>`,
          startDate: "2020-01-23T18:00:00",
          endDate: "2020-03-18T18:00:00",
        }
      },
      {
        type: widgetTypes.event,
        props: {
          type: iconTypes.livestream,
          typeLabel: "eLearning",
          places: {
            available: 9,
            total: 18,
          },
          headline: "Reklamation als Chance",
          copytext: `<p>Im Training gewinnen Sie ein tieferes Verständnis für Reklamationen, um für den Kunden und Sie einen Erfolg daraus zu machen. Sie lernen Reklamationen vorzubeugen und so maximale Kundenzufriedenheit zu erzielen.</p><ul><li>Sieben Punkte  für ein erfolgreiches Reklamationsmanagement</li><li>Umgang mit schwierigen Kunden</li><li>Reklamationsbehandlung – Kommunikation und Wirkung</li><li>Reklamationsbehandlung – Bearbeitung in den Vodafone Systemen</li><li>Erfolgreiches Reklamationsmanagement</li></ul>`,
          startDate: "2020-01-23T18:00:00",
          endDate: "2020-03-01T18:00:00",
        }
      },
    ],
  },
  {
    title: "Frage",
    theme: {
      bg: contextThemeTypes.lightestGrey,
    },
    list: [
      {
        type: widgetTypes.poll,
        props: {
          headline: "Welches Gerät würdest Du Deinen Kunden uneingeschränkt empfehlen?",
          name: "poll1",
          list: [
            {
              label: "Huawei Matte",
              id: "option1",
              value: "15",
            },
            {
              label: "LG G7",
              id: "option2",
              value: "25",
            },
            {
              label: "Samsung Galaxy S9",
              id: "option3",
              value: "10",
            },
            {
              label: "Huawei Matte",
              id: "option4",
              value: "50",
            },
            {
              label: "LG G7",
              id: "option5",
              value: "10",
            },
            {
              label: "Samsung Galaxy S9",
              id: "option6",
              value: "23",
            },
            {
              label: "Apple iPhone Xs Space Grey 256GB, Dual Camera, A12 Bionic Prozessor",
              id: "option7",
              value: "15",
            },
          ]
        }
      },
      {
        type: widgetTypes.poll,
        props: {
          headline: "Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit Sed Do Eiusmod?",
          name: "poll2",
          list: [
            {
              label: "Huawei Matte",
              id: "option1",
              value: "75",
            },
            {
              label: "LG G7",
              id: "option2",
              value: "25",
            },
          ]
        }
      },
    ],
  },
  {
    title: "Mein shop",
    theme: {
      bg: contextThemeTypes.black,
    },
    list: [
      {
        type: widgetTypes.score,
        props: {
          
        }
      },
    ],
  }
];

export const Magazine = () => {
  return (
    <React.Fragment>
      <Page>
        {sectionWrapper({ list: [
          <KeyVisual
            image={images.kvPlaceholder}
            video={video.kv}
            badge="Gewinne 500€ pro Person"
            badgeTheme={contextThemeTypes.red}
            category="Incentive"
            date="18.11.2019"
            headline="Q3 Incentive: Erfülle Dir einen Herzenswunsch!"
            copytext="Gib alles, denn nur die Besten genießen unvergessliche Abenteuer. Von Fallschirmspringen über Bungee-Jumping bis hin zu einer Fahrt mit Deinem Traum-Auto, Du wirst zum Adrenalin-Junkie! "
            theme={{
              bg: contextThemeTypes.image,
              position: keyVisualPositionTypes.center
            }}
            buttons={[
              {
                children: "Mehr erfahren",
                theme: buttonThemeTypes.red,
              },
            ]}
          />,
          <Carousel
            list={carouselList}
            layout={carouselLayoutTypes.double}
          />
        ], homeGrid: true })}
        {sectionWrapper({ list: [
          <AlertBanner
            category="Mobilfunk"
            date="06.12.2019"
          >
            Positive Beratungseinwilligung: Voraussetzung für die erfolgreiche Kundenbindung
          </AlertBanner>,
          <TeaserList
            list={teaserList}
          />,
          <TeaserListCombo
            list={teaserListCombo}
          />,
          <TeaserList
            list={teaserListBgImage}
          />,
        ] })}
      </Page>
      <WidgetsSection list={widgetList} />
    </React.Fragment>
  );
};

Magazine.propTypes = {
  // match: ReactRouterPropTypes.match.isRequired,
}
