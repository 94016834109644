import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import theme from 'styled-theming';
import styled, { css } from 'styled-components/macro';

import { xz, bp, color, SvgLinkPdf, SvgLinkInternal, SvgLinkExternal, animatedUnderline, defTransition } from '#theme';
import { Grid, Col, Wrapper, Headline } from '#ui';
import { contextThemeTypes, themeKeys } from '#helpers';
import { getGridListChunk } from '#utils';

const Item = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${xz(4)} 0 ${xz(6)};
`;

export const downloadLinkTypes = {
  pdf: "pdf",
  external: "external",
  internal: "internal",
}

const linkIcons = {
  [downloadLinkTypes.pdf]: SvgLinkPdf,
  [downloadLinkTypes.external]: SvgLinkExternal,
  [downloadLinkTypes.internal]: SvgLinkInternal,
};

const Container = styled(Wrapper)`
  
`;

const IconContainer = styled.svg`
  width: 64px;
  height: 64px;
  fill: none;
  stroke: ${color.red};
  ${theme(themeKeys.bg, {
    [contextThemeTypes.red]: css`
      stroke: ${color.white};
    `,
  })}
`;

const Caption = styled.div`
  margin-top: ${xz(3)};
  font-weight: bold;
`;
const Text = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  @media ${bp.p} {
    font-size: 1.125rem;
  }
  @media ${bp.d} {
    font-size: 1.25rem;
  }
`;

const Label = styled.div`
  color: ${color.red};
  transition: color ${defTransition};
  ${Item}:hover & {
    color: ${color.maroon};
  }
  ${animatedUnderline(Item)}
  ${theme('bg', {
    [contextThemeTypes.red]: css`
      color: ${color.white};
      ${Item}:hover & {
        color: ${color.white};
        &::after {
          border-bottom-color: ${color.white};
        }
      }
    `,
  })}
`;

const ListItem = ({ type, label, caption, text, link }) => {
  const Icon = linkIcons[type];
  return (
    <Item href={link}>
      <IconContainer>
        <Icon />
      </IconContainer>
      <Caption>{caption}</Caption>
      {text && (
        <Text>
          {text}
        </Text>
      )}
      <Label>{label}</Label>
    </Item>
  );
};

ListItem.propTypes = {
  type: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  text: PropTypes.string,
  link: PropTypes.string.isRequired,
};

export const Downloads = ({
  headline,
  list,
}) => {
  const isMobile = useMediaQuery({ query: bp.m });
  const isPhablet = useMediaQuery({ query: bp.p });
  const isDesktop = useMediaQuery({ query: bp.d });

  const itemsInChunk = !isMobile
    ? 1
    : !isPhablet
      ? 2
      : !isDesktop
        ? 4
        : 6;
  const chunkedList = getGridListChunk(list, itemsInChunk);
  const gridColumns = itemsInChunk * 2;
  return (
    <Container>
      {headline && <Headline>{headline}</Headline>}
      <Grid columns={gridColumns} rowGap={"0px"}>
        {chunkedList.map((row, rowKey) => {
          const left = (gridColumns - row.length * 2) / 2;
          const width = gridColumns - left * 2;
          return (
            <Col key={rowKey} left={left + 1} width={width}>
              <Grid columns={row.length}>
                {row.map((item, key) => (
                  <Col key={key}>
                    <ListItem {...item}/>
                  </Col>
                ))}
              </Grid>
            </Col>
          );
        })}
      </Grid>
    </Container>
  );
};

Downloads.propTypes = {
  headline: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape(ListItem.propTypes)
  ).isRequired,
};