import React, { useState } from 'react';
import styled from "styled-components/macro";
import { rgba } from 'polished';
import useDimensions from "react-use-dimensions";

import { bp, clearBtn, color, defTransition, xz } from '#theme';
import { Scrollbars, Icon, iconTypes } from '#ui';
import { Button } from '../../Button/Button';
import { Countdown } from './Countdown';
import { Rte } from '../../Rte/Rte';
import { contextLevelTypes } from '#helpers';

const Container = styled.div`
  padding: ${xz(1)} ${xz(3)} ${xz(4)};
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
`;

const Body = styled.div`
  height: ${({ height, isExpanded}) => !isExpanded ? `${height}px` : 0};
  overflow: hidden;
  transition: height ${defTransition};
  @media ${bp.m} {
    padding-left: ${xz(3)};
    padding-right: ${xz(3)};
  }
`;
const BodyInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PreHeadline = styled.h4`
  font-size: 1.125rem;
  line-height: 1.22;
  color: ${color.middleGrey};
  margin-top: 0;
  margin-bottom: ${xz(2)};
`;
const BodyCountdown = styled.div`
  flex: 1 0 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Attrs = styled.div`
  display: flex;
  margin-top: ${xz(2)};
  margin-bottom: ${xz(4)};
  padding-bottom: ${xz(3)};
  border-bottom: 2px solid ${rgba(color.white, 0.25)};
`;
const AttrsItem = styled.div`
  flex: 1 0 0%;
  padding: 0 ${xz(2)};
`;

const Attr = styled.div`
  width: 96px;
`;
const AttrFigure = styled.div`
  color: ${color.middleGrey};
  font-size: 1.5rem;
  line-height: 1;
  height: 32px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;
const FreePlaces = styled.span`
  color: ${color.red};
`;
const AttrCaption = styled.div`
  color: ${color.red};
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.28;
  margin-top: ${xz(1)};
`;

const Headline = styled.div`
  font-size: 1.25rem;
  line-height: 1.2;
  color: ${color.white};
  font-weight: bold;
  @media ${bp.m} {
    font-size: 1.5rem;
    padding-left: ${xz(6)};
    padding-right: ${xz(6)};
  }
`;

const More = styled.button`
  ${clearBtn}
  color: ${color.red};
  border-bottom: 1px dashed ${color.red};
  margin: auto;
  margin-top: ${xz(1)};
  font-size: 0.875rem;
  line-height: 1.28;
  padding-bottom: 4px;
  font-weight: bold;
  transition: color ${defTransition}, border-bottom-color ${defTransition};
  &:hover {
    color: ${color.white};
    border-bottom-color: ${color.white};
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FooterButton = styled.div`
  margin-top: ${xz(4)};
`;

const Entry = styled.div`
  margin-top: ${xz(2)};
  width: 100%;
  height: ${({ height, isExpanded}) => isExpanded ? `${height}px` : 0};
  overflow: hidden;
  transition: height ${defTransition};
`;
const Inner = styled.div`
  color: ${color.lightGrey};
  text-align: left;
  padding: ${xz(2)};
  @media ${bp.m} {
    padding-left: ${xz(6)};
    padding-right: ${xz(6)};
  }
`;
const Image = styled.img`
  max-width: 100%;
  display: block;
  margin-bottom: ${xz(4)};
`;

export const WidgetEvent = ({
  headline,
  copytext,
  image,
  startDate,
  endDate,
  type = iconTypes.livestream,
  typeLabel,
  places,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!isExpanded);
  };
  const [bodyRef, { height }] = useDimensions({ liveMeasure: false });
  return (
    <React.Fragment>
      <Container>
        <Body height={height} isExpanded={isExpanded} >
          <BodyInner
            ref={bodyRef}
          >
            <PreHeadline>Anmeldeschluss in</PreHeadline>
            <BodyCountdown>
              <Countdown startDate={startDate} endDate={endDate} />
              <Attrs>
                <AttrsItem>
                  <Attr>
                    <AttrFigure><Icon type={type} color={color.red} /></AttrFigure>
                    <AttrCaption>{typeLabel}</AttrCaption>
                  </Attr>
                </AttrsItem>
                {places && (
                  <AttrsItem>
                    <Attr>
                      <AttrFigure><FreePlaces>{places.available}</FreePlaces>/{places.total}</AttrFigure>
                      <AttrCaption>Plätze frei</AttrCaption>
                    </Attr>
                  </AttrsItem>
                )}
              </Attrs>
            </BodyCountdown>
          </BodyInner>
        </Body>
        <Footer>
          <Headline>{headline}</Headline>
          <Entry height={height} isExpanded={isExpanded}>
            <Scrollbars>
              <Inner>
                {image && <Image src={image.src} />}
                <Rte level={contextLevelTypes.inside}>{copytext}</Rte>
              </Inner>
            </Scrollbars>
          </Entry>
          <More onClick={toggleExpanded}>{!isExpanded ? "mehr" : "weniger"}</More>
          <FooterButton>
            <Button to="#" isLink>Anmelden</Button>
          </FooterButton>
        </Footer>
      </Container>
    </React.Fragment>
  )
};