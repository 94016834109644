import { css } from 'styled-components/macro';
export const gridCols = 12;
const precision = 100000;
const gridLineColor = "pink";
export const gapMobile = 12;
export const gapDesktop = 24;

export const col = (n, b = gridCols) => `${Math.round(n / b * 100 * precision) / precision}%`;

export const grid = (cols = 12) => css`
	display: grid;
	grid-auto-flow: row dense;
	grid-auto-rows: minmax(20px,auto);
	grid-template-columns: repeat(${cols},1fr);
	grid-gap: 12px;
`;

export const gridOverlay = (gap = 12, cols = gridCols) => css`
  margin-left: -${gap}px;
	background-image:
		repeating-linear-gradient(90deg,
			${gridLineColor} 0,
			${gridLineColor} ${gap}px,
			transparent 0,
			transparent ${col(1, cols)}
		);
`;