import React from 'react';
import theme from 'styled-theming';
import styled, { css } from 'styled-components/macro';
import { color, bp, xz, SvgLinkPdf, SvgLinkInternal, SvgLinkExternal, defTransition, animatedUnderline } from '#theme';
import { contextThemeTypes, themeKeys } from '#helpers';

export const linkTypes = {
  pdf: "pdf",
  internal: "internal",
  external: "external",
}

const linkIcons = {
  [linkTypes.pdf]: SvgLinkPdf,
  [linkTypes.internal]: SvgLinkInternal,
  [linkTypes.external]: SvgLinkExternal,
}

const A = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${color.red};
  stroke: ${color.red};
  transition: color ${defTransition}, stroke ${defTransition};
  line-height: 1.25;
  @media ${bp.d} {
    font-size: 1.125rem;
  }
  & + & {
    margin-top: ${xz(1)};
  }
  &:hover {
    stroke: ${color.maroon};
    color: ${color.maroon};
  }
  ${theme(themeKeys.bg, {
    [contextThemeTypes.red]: css`
      color: ${color.white};
      stroke: ${color.white};
      &:hover {
        color: ${color.white};
        stroke: ${color.white};
      }
    `,
  })}
`;

const LinkIcon = styled.span`
  width: 32px;
  height: 32px;
  margin-right: 12px;
`;

const LinkLabel = styled.span`
  ${animatedUnderline(A)}
  ${theme(themeKeys.bg, {
    [contextThemeTypes.red]: css`
      &::after {
        border-color: ${color.white};
        ${A}:hover & {
          border-color: ${color.white};
        }
      }
    `,
  })}
`;

export const Link = ({ href, label, type }) => {
  const Icon = linkIcons[type];
  return (
    <A href={href}>
      <LinkIcon>
        <Icon />
      </LinkIcon>
      <LinkLabel>{label}</LinkLabel>
    </A>
  );
};

  

