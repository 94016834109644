import { createGlobalStyle } from 'styled-components/macro';
import { font, fontFace, maxWidth, screenSize, color, bp } from '#theme';
import { normalize } from 'polished';
import { simplebarCSS } from './simplebar';

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${fontFace}
  * {
    box-sizing: border-box;
  }
  :root {
    --X: 8px;
    @media ${maxWidth(screenSize.d)} {
      --X: 7px;
    }
    @media ${maxWidth(screenSize.p)} {
      --X: 6px;
    }
  }
  html {
    font-size: 16px;
    height: 100%; 
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    font-family: ${font.body};
    color: ${color.textGrey};
    line-height: 1.5;
    font-size: 1rem;
    height: 100%;
    @media ${bp.p} {
      font-size: 1.125rem;
    }
  }
  a, input, button {
    outline: none;
    color: inherit;
    text-decoration: inherit;
  }
  ${simplebarCSS}
  #root {
    height: 100%;
  }
`;