export const bpTypes = {
  s: "s",
  m: "m",
  p: "p",
  t: "t",
  d: "d",
  h: "h",
};
export const screenSize = {
  [bpTypes.s]: 480,
  [bpTypes.m]: 720,
  [bpTypes.p]: 960,
  [bpTypes.t]: 1200,
  [bpTypes.d]: 1536,
  [bpTypes.h]: 1900,
};

export const minWidth = (size) => `(min-width: ${size}px)`;
export const maxWidth = (size) => `(max-width: ${size - 1}px)`;

const bp = {};

for (const [name, value] of Object.entries(screenSize)) {
    bp[name] = minWidth(value);
}

export { bp };

export const maxWrapperWidth = 1152;
export const maxWrapperWidthWide = 1366;