import { chunk } from 'lodash-es';

export const getGridListChunk = (list, itemsInChunk) => {
  const tileHasOneElement = itemsInChunk >= 4 && list.length > itemsInChunk && list.length % itemsInChunk === 1;
  if (!tileHasOneElement) {
    return chunk(list, itemsInChunk);
  }
  const slicePosition = list.length - (itemsInChunk + 1);
  const part1 = list.slice(0, slicePosition);
  const part2 = list.slice(slicePosition);
  const chunkedList = chunk(part1, itemsInChunk).concat(chunk(part2, itemsInChunk - 1));
  return chunkedList;
};