import React from 'react';
import styled from 'styled-components/macro';
import { clearBtn, color, bp } from "#theme";

const Container = styled.div`
  display: flex;
  margin: 0 -4px;
  padding: 0;
  @media ${bp.m} {
    justify-content: center;
  }
`;
const Dot = styled.button`
  ${clearBtn}
  padding: 4px;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    background-color: ${props => props.isActive
      ? color.red
      : color.lightestGrey };
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }
`;
export const Dots = ({
  length,
  index,
  handleIndex,
}) => {
  const dots = [];
  for (let i = 0; i < length; i++) dots.push(
    <Dot key={i} isActive={i === index} onClick={() => handleIndex(i)} />
  );
  return (
    <Container>
      {dots}
    </Container>
  )
};