import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { useModal } from 'react-modal-hook';
import { getLightboxComponent } from '#ui';
import { Gallery } from '../Gallery/Gallery';
import { galleryLayoutTypes } from '../Gallery/Gallery';

export const LightboxGallery = ({ list, defaultIndex, children, ...restProps }) => {
  const [index, setIndex] = useState(defaultIndex);
  const showLightboxIndex = (i) => setIndex(i);
  const [showModal, hideModal] = useModal(
    () => getLightboxComponent((
      <ThemeProvider theme={{
        layout: galleryLayoutTypes.lightbox,
      }}>
        <Gallery
          list={list}
          defaultIndex={index}
          {...restProps}
        />
      </ThemeProvider>
    ), hideModal),
    [index]
  );
  
  return children({ list, showModal, showLightboxIndex });
};