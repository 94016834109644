import React from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';

import { color, xz, screenSize, maxWidth, expander, bp, overlay, easeInOutCubic, fadeIn, fadeOut, defTransition } from '#theme';
import { rgba } from 'polished';
import { H3, Meta, H2 } from '#ui';
import { themeKeys, contextThemeTypes } from '#helpers';
import { teaserLayoutTypes, teaserVisualTypes } from './teaser.types';

const Link = styled.a`
  display: block;
`;
const Container = styled.div`
  position: relative;
`;
const Visual = styled.div`
  height: 0;
  overflow: hidden;
  position: relative;
  ${({ src }) => overlay(`
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${src});
    background-size: cover;
    transition: transform .5s ${easeInOutCubic};
  `)}
  ${Container}:hover &::after {
    transform: scale(1.1);
    @media (hover: none) {
      transform: none;
    }
  }
  ${theme(themeKeys.bg, {
    [contextThemeTypes.default]: css`
      padding-bottom: ${2/3*100}%;
    `,
    [contextThemeTypes.image]: css`
      padding-bottom: 100%;
    `,
  })}
`;
const Content = styled.div`
  padding: ${xz(3)} ${xz(3)} ${xz(2)};
  ${theme(themeKeys.bg, {
    [contextThemeTypes.default]: css`
      background: linear-gradient(180deg, ${color.lightestGrey} 0%, ${rgba(color.lightestGrey, 0)} 100%);
      padding: ${xz(3)} ${xz(3)} ${xz(4)};
    `,
    [contextThemeTypes.image]: css`
      ${expander}
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background: linear-gradient(180deg, ${rgba(color.black, 0)} 50%, ${rgba(color.black, 0.85)} 100%);
      padding: 0 ${xz(3)} ${xz(4)};
      @media ${bp.d} {
        padding: 0 ${xz(4)} ${xz(4)};
      }
      ${fadeIn}
      ${Container}:hover & {
        ${fadeOut}
        @media (hover: none) {
          ${fadeIn}
        }
      }
    `,
  })}
`;
const StyledMeta = styled(Meta)`
  margin-bottom: ${xz(1)};
  ${theme(themeKeys.layout, {
    [teaserLayoutTypes.four]: css`
      @media ${maxWidth(screenSize.m)} {
        display: none;
      }
    `,
  })}
`;

const StyledH2 = styled(H2)`
  font-weight: bold;
`;
export const headlineTypes = {
  h2: "h2",
  h3: "h3",
}
const headlineComponents = {
  [headlineTypes.h2]: StyledH2,
  [headlineTypes.h3]: H3,
}
const getHeadline = (type) => styled(headlineComponents[type])`
  margin-top: 0;
  transition: color ${defTransition};
  ${Container}:hover & {
    color: ${color.red};
  }
  ${theme(themeKeys.bg, {
    [contextThemeTypes.image]: css`
      ${Container}:hover & {
        color: ${color.white};
      }
    `,
  })}
  ${theme(themeKeys.layout, {
    [teaserLayoutTypes.four]: css`
      @media ${maxWidth(screenSize.m)} {
        font-size: 0.75rem;
      }
    `,
  })}
`;

export const Teaser = ({
  visual,
  href,
  date,
  headline,
  category,
}) => {
  const { type = headlineTypes.h3, text } = headline;
  const Headline = getHeadline(type);
  return (
    <Link href={href}>
      <Container>
        {visual && (
          visual.type === teaserVisualTypes.icon ? (
            <Visual dangerouslySetInnerHTML={{ __html: visual.src }} />
          ) : (
            <Visual src={visual.src} />
          )
        )}
        <Content>
          <StyledMeta date={date} category={category} />
          <Headline className="textGrey">{text}</Headline>
        </Content>
      </Container>
    </Link>
  );
}