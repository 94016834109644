import React from 'react';
import styled from 'styled-components/macro';

import { color } from '#theme';
import { SvgCheck } from '#svg';

const Span = styled.span`
  display: block;
  visibility: ${({ isViewed }) => isViewed ? "visible" : "hidden"};
  fill: ${color.green};
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ViewStatus = ({ ...props }) => (
  <Span {...props}>
    <SvgCheck />
  </Span>
);