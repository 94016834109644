import React from 'react';
import styled from 'styled-components/macro';
import { useMediaQuery } from 'react-responsive';
import theme from 'styled-theming';

import { bp } from '#theme';
import { Grid, Col } from '../Grid/Grid';
import { wrapperProps } from '#theme/vars';
import { themeKeys } from '#helpers';

export const wrapperTypes = {
  default: "default",
  wide: "wide",
}

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  flex: 1 0 0%;
  ${wrapperProps.paddings}
  ${wrapperProps.maxWidth}
  ${theme(themeKeys.wrapper, {
    [wrapperTypes.wide]: wrapperProps.maxWidthWide,
  })}
`;
export const Wrapper = ({
  left,
  width,
  children,
  className,
  noGrid,
}) => {
  const isPhablet = useMediaQuery({ query: bp.p });
  const colWidth = !isPhablet ? 4 : 12;
  return (
    <Container {...{ className }}>
      {noGrid
        ? children
        : (
          <Grid>
            <Col left={left} width={width || colWidth}>
              {children}
            </Col>
          </Grid>
        )
      }
    </Container>
  );
};