import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { clearBtn, color, defTransition } from '#theme';

export const buttonThemeTypes = {
  default: "default",
  ghost: "ghost",
  white: "white",
  redGhost: "redGhost",
  clean: "clean",
  lightGrey: "lightGrey",
};

const buttonThemes = {
  [buttonThemeTypes.default]: `
    background-color: ${color.red};
    color: ${color.white};
    &:hover {
      background-color: ${color.maroon};
    }
  `,
  [buttonThemeTypes.ghost]: `
    border: 2px solid ${color.white};
    &:hover {
      background-color: ${color.white};
      color: ${color.red};
    }
  `,
  [buttonThemeTypes.white]: `
    background-color: ${color.white};
    color: ${color.red};
    &:hover {
      background-color: ${color.maroon};
      color: ${color.white};
    }
  `,
  [buttonThemeTypes.redGhost]: `
    border: 2px solid ${color.red};
    color: ${color.red};
    &:hover {
      background-color: ${color.red};
      color: ${color.white};
    }
  `,
  [buttonThemeTypes.lightGrey]: `
    background-color: ${color.lightGrey};
    color: ${color.white};
  `,
  [buttonThemeTypes.clean]: `
    color: ${color.red};
  `,
}

const InitButton = styled.button`
  ${clearBtn}
  display: flex;
  min-width: 160px;
  height: 40px;
  padding-bottom: 1px;
  color: ${color.white};
  transition: background-color ${defTransition},
              color ${defTransition};
  font-size: 14px;
  font-weight: bold;
  &[disabled] {
    cursor: default;
  }
`;
const StyledButton = styled(({ themeStyles, isLink, ...restProps }) => <InitButton as={isLink && Link} {...restProps} />)`
  ${({ themeStyles }) => themeStyles}
`;

export const ButtonPropTypes = {
  theme: PropTypes.string,
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  to: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export const Button = ({ theme = buttonThemeTypes.default, children, ...restProps }) => {
  return (
    <StyledButton themeStyles={buttonThemes[theme]} {...restProps}>
      {children}
    </StyledButton>
  );
};

Button.propTypes = ButtonPropTypes;
