
import React from 'react';
import styled from 'styled-components/macro';
import { UserMenuList } from '../UserMenuList/UserMenuList';

const Container = styled.div`

`;

export const UserSettings = ({ list }) => {
  return (
    <Container>
      <UserMenuList list={list} />
    </Container>
  );
};