import { color, colorEnums } from './color';
import { rgba } from 'polished';

export const gradientTypes = {
  red: {
    type: 'linear',
    deg: 90,
    colors: [
      {
        color: colorEnums.darkRed,
        opacity: 1,
      },
      {
        color: colorEnums.red,
        opacity: 1,
      },
    ],
  },
  red45: {
    type: 'linear',
    deg: 135,
    colors: [
      {
        color: colorEnums.darkRed,
        opacity: 1,
      },
      {
        color: colorEnums.red,
        opacity: 1,
      },
    ],
  },
  lightGrey: {
    type: 'linear',
    deg: 180,
    colors: [
      {
        color: colorEnums.lightestGrey,
        opacity: 1,
      },
      {
        color: colorEnums.lightestGrey,
        opacity: 0,
      },
    ],
  },
  blackTransparent: {
    type: 'linear',
    deg: 180,
    colors: [
      {
        color: colorEnums.black,
        opacity: 0,
      },
      {
        color: colorEnums.black,
        opacity: 0.85,
      },
    ],
  },
  blackTransparentHalf: {
    type: 'linear',
    deg: 180,
    colors: [
      {
        color: colorEnums.black,
        opacity: 0,
        position: 50,
      },
      {
        color: colorEnums.black,
        opacity: 0.85,
        position: 100,
      },
    ],
  },
  whiteToRight: {
    type: 'linear',
    deg: 90,
    colors: [
      {
        color: colorEnums.white,
        opacity: 0,
      },
      {
        color: colorEnums.white,
        opacity: 1,
      },
    ],
  },
  redToRight: {
    type: 'linear',
    deg: 90,
    colors: [
      {
        color: colorEnums.red,
        opacity: 0,
      },
      {
        color: colorEnums.red,
        opacity: 1,
      },
    ],
  },
  lightestGreyToRight: {
    type: 'linear',
    deg: 90,
    colors: [
      {
        color: colorEnums.lightestGrey,
        opacity: 0,
      },
      {
        color: colorEnums.lightestGrey,
        opacity: 1,
      },
    ],
  },
};

const gradient = {};

Object.keys(gradientTypes).forEach((gradientKey) => {
  const { type, deg, colors } = gradientTypes[gradientKey];
  gradient[gradientKey] = `${type}-gradient(${deg}deg,
    ${rgba(color[colors[0].color], colors[0].opacity)} ${colors[0].position || 0}%,
    ${rgba(color[colors[1].color], colors[1].opacity)} ${colors[1].position || 100}%)
  `;
});

export { gradient };