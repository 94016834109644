import React from 'react';
import styled from 'styled-components/macro';

import { color, font } from '#theme';

const Input = styled.input`
  border: 1px solid ${color.white};
  background-color: ${color.white};
  width: 100%;
  display: block;
  padding: 12px 16px;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  font-family: ${font.body};
  color: ${color.textGrey};
  &::placeholder {
    color: ${color.textGrey};
  }
`;

export const SearchField = ({ ...props }) => (
  <Input
    type="text"
    {...props}
  />
);