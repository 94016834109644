import React from 'react';
import { Page, wrapperTypes } from '#ui';
import { SvgAppsMid, SvgBillPayPhoneMid, SvgBiometricAuthenticationMid, SvgBonusMid, SvgBraceletActiveMid, SvgConnectivityMid } from '#theme';
import { Rte, Placeholder, Accordion, Video, Tabs, Iconbox, MultiTeasers, Downloads, downloadLinkTypes, KeyFigures, Gallery, LightboxGallery, TextMedia, textMediaImageSizeTypes, textMediaLayoutTypes, textMediaItemLayoutTypes, linkTypes, Table, ArticleHeader, articleHeaderLayoutTypes } from '#components';
import { videoPoster } from '#data/images';
import { images } from '#data';
import { contextThemeTypes, themeKeys } from '#helpers';
import { sectionWrapper } from '../../hocs/sectionWrapper';
import { TableExample } from './examples/TableExample';
import { TableExample3 } from './examples/TableExample3';
import { TableExample4 } from './examples/TableExample4';
import { TableExample5 } from './examples/TableExample5';
import { tableExample2Data } from './examples/tableExample2';

const RteSample = (props) => (
  <Rte {...props}>
    {`<h2>Headline</h2>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum.
    </p>`}
  </Rte>
)
const accordionBodySample = [
  <Placeholder />,
  <RteSample />
];

const tabsBodySample = [
  <Placeholder />,
  <RteSample tab={true} />
];

export const Components = () => {
  const galleryList = [
    {
      caption: "Bildunterschrift: Mit dem Zusammenschluss von Unitymedia und Vodafone haben wir den größten Schritt .",
      image: images.galleryTestImage01,
    },
    {
      caption: "Bildunterschrift: Mit dem Zusammenschluss von Unitymedia und Vodafone haben wir den größten Schritt .",
      image: images.galleryTestImage02,
    },
    {
      caption: "Bildunterschrift: Mit dem Zusammenschluss von Unitymedia und Vodafone haben wir den größten Schritt .",
      image: images.galleryTestImage03,
    },
    {
      caption: "Bildunterschrift: Mit dem Zusammenschluss von Unitymedia und Vodafone haben wir den größten Schritt .",
      image: images.galleryTestImage04,
    },
    {
      caption: "Bildunterschrift: Mit dem Zusammenschluss von Unitymedia und Vodafone haben wir den größten Schritt .",
      image: images.galleryTestImage05,
    },
    {
      caption: "Bildunterschrift: Mit dem Zusammenschluss von Unitymedia und Vodafone haben wir den größten Schritt .",
      image: images.galleryTestImage06,
    },
    {
      caption: "Bildunterschrift: Mit dem Zusammenschluss von Unitymedia und Vodafone haben wir den größten Schritt .",
      image: images.galleryTestImage07,
    },
    {
      caption: "Bildunterschrift: Mit dem Zusammenschluss von Unitymedia und Vodafone haben wir den größten Schritt .",
      image: images.galleryTestImage08,
    },
    {
      caption: "Bildunterschrift: Mit dem Zusammenschluss von Unitymedia und Vodafone haben wir den größten Schritt .",
      image: images.galleryTestImage09,
    },
    {
      caption: "Bildunterschrift: Mit dem Zusammenschluss von Unitymedia und Vodafone haben wir den größten Schritt .",
      image: images.galleryTestImage10,
    },
    {
      caption: "Bildunterschrift: Mit dem Zusammenschluss von Unitymedia und Vodafone haben wir den größten Schritt .",
      image: images.galleryTestImage11,
    },
    {
      caption: "Bildunterschrift: Mit dem Zusammenschluss von Unitymedia und Vodafone haben wir den größten Schritt .",
      image: images.galleryTestImage12,
    },
  ];
  return (
    <Page>
      {sectionWrapper({ list: [
        <ArticleHeader
          noPadding
          theme={{
            bg: contextThemeTypes.lightestGrey,
            layout: articleHeaderLayoutTypes.B,
            badgeTheme: contextThemeTypes.red,
            boxTheme: contextThemeTypes.lightestGrey,
          }}
          image={images.vfTestimage03}
          badge="NEU"
          category="Category"
          date="12.08.2019"
          headline="GigaTV inkl. HD Premium zum Vorteilspreis"
          subheadline="Biete Deinem Kunden GigaTV und Super HD Premium für 14,99 €"
          copytext={`
            <ul>
              <li><b>NEU</b>: TV Content Cirkus - 3 Monate gratis</li>
              <li>Für KIP-Bestandskunden: 0 €</li>
              <li>Bereitstellungsentgelt bei GigaTV Buchung</li>
              <li>Monatlich 4,99 € sparen, mit GigaTV Cable inklusive HD Premium für nur <b>14,99 €</b></li>
            </ul>
          `}
        />,
        <Rte>{`
          <h1>Positive Beratungseinwilligung: Voraussetzung für die erfolgreiche Kundenbindung</h1>
          <h2 class="textGrey">Standort-Erkennung und Ortung zu jeder Zeit via GPS, Wifi und Mobilfunk.</h2>
          <h3>Zusammenschluss von Unitymedia</h3>
          <p>Copytext. Es ist soweit: Mit dem Zusammenschluss von Unitymedia und Vodafone haben wir den Schritt zur GigabitCompany gemacht. Ab dem 2. September können wir endlich auch unseren Kunden in Hessen Kabel anbieten.</p>
          <p>
            <b>Bold Copytext with &lt;br&gt; after.</b><br /> Es ist soweit: Mit dem Zusammenschluss von Unitymedia und <span class="highlight">Vodafone</span> haben wir den Schritt zur <a href="#GigabitCompany">GigabitCompany</a> gemacht. Ab dem 2. September können wir endlich auch unseren Kunden <del>in Hessen</del> Kabel anbieten.
             
            <svg width="16" height="16"><path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zM7 3h2v6.5H7V3zm1 10.25a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5z" /></svg>
             
            <svg width="16" height="16"><path d="M7 12.061L2.934 7.995a.75.75 0 1 1 1.061-1.061L7 9.939l7.104-7.104A7.98 7.98 0 0 0 8 0a8 8 0 1 0 8 8 7.953 7.953 0 0 0-1.026-3.913L7 12.061z"/></svg>
             
            <svg width="16" height="16"><path d="M3.995 6.934a.75.75 0 1 0-1.061 1.061L7 12.061l7.974-7.974a7.973 7.973 0 0 0-.87-1.252L7 9.939 3.995 6.934zm11.624-1.371l-1.235 1.235c.073.39.116.791.116 1.202 0 3.584-2.916 6.5-6.5 6.5S1.5 11.584 1.5 8 4.416 1.5 8 1.5a6.46 6.46 0 0 1 3.961 1.358l1.073-1.073a8 8 0 1 0 2.585 3.778z"/></svg>
             
            <svg width="16" height="16"><path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 14.5c-3.584 0-6.5-2.916-6.5-6.5S4.416 1.5 8 1.5s6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zM7 6h2v7H7V6zm2.25-2a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0z"/></svg>
             
            <svg width="16" height="16"><path d="M15.77 12.443L9.488 1.953c-.33-.551-.909-.826-1.489-.826s-1.16.275-1.489.826L.232 12.443C-.435 13.553.42 15 1.717 15h12.559c1.302 0 2.157-1.447 1.494-2.557zM7 4.5h2V10H7V4.5zm1 9.25a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5z"/></svg>
             
            <svg width="16" height="16"><path d="M8.119.047c.679.246 1.507.809 1.507 2.095C9.625 3.548 8.828 6 8.828 6h5.578C15.284 6 16 6.716 16 7.594v.75c0 .327-.068.651-.202.946l-2.391 4.823a1.5 1.5 0 0 1-1.366.887H5.657C4.776 15 4 14.287 4 13.406V6.048c0-.392.146-.772.411-1.065l2.204-2.4L7.06.635A.809.809 0 0 1 8.119.047zM3 14V7a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1z"/></svg>
          </p>
          <ul>
            <li>500 MB mehr für Smartphone Special, 1 GB mehr für Smartphone Allnet Flat</li>
            <li>Bis zu 25 % mehr Datenvolumen in den Internet-Optionen</li>
            <ul>
              <li>Anrufe und SMS von Deutschland in die EU kostenlos inklusive</li>
              <li>In weitere 40 Länder (z. B. USA, Kanada, Brasilien) für nur 9 Cent pro Minute telefonieren</li>
              <ul>
                <li>Für nur 14,99 € GigaTV inklusive HD Premium erleben</li>
                <li>Dein Kunde spart monatlich 4,99 €</li>
              </ul>
            </ul>
          </ul>
          <ul type="square">
            <li>HD Premium: 22 zusätzliche PayTV-Sender in HD</li>
            <li>Wechselgarantie: 12 Monate ohne Risiko testen und bei Bedarf kostenlos downgraden</li>
            <ul>
              <li>Nutze auch die Kombivorteile für GigaTV mit KIP und der GigaKombi</li>
              <li>Vorteilspreis 14,99 € in den ersten 12 Monaten, danach 19,99 €</li>
            </ul>
          </ul>
          <ol>
            <li><b>HD Premium bietet</b></li>
            <ul>
              <li>über 4000 zusätzliche Inhalte auf 22 Pay-TV Sendern in HD Qualität: (z.B. Fox HD, National Geographic HD, TNT HD, Sport1+ HD, AXN HD)</li>
              <li>Beliebte Serien wie:</li>
              <ul>
                <li>Walking Dead</li>
                <li>Game of Thrones</li>
                <li>Chigaco Fire</li>
                <li>Power</li>
                <li>4 Blocks</li>
              </ul>
              <li>Zugriff auf 23 Mediatheken</li>
              <li>Keine Werbeunterbrechungen</li>
            </ul>
            <li>Dein Kunde spart monatlich 4,99 €, gegenüber der Einzelbuchung</li>
            <li>Highlights 5G-Highspeed-Datenverbindung für flüssiges Live-Entertainment</li>
            <li>Beeindruckendes Design mit Infinity-U Display und Super AMOLED-Technologie</li>
            <li>Vielseitige Aufnahmemöglichkeiten mit der Triple-Hauptkamera</li>
            <li>Lange Laufzeit mit dem leistungsstarken 4.500 mAh2Akku</li>
            <li>Komfortables Entsperren mit optischem Fingerabdruckscanner im Display</li>
            <li>500 MB mehr für Smartphone Special, 1 GB mehr für Smartphone Allnet Flat</li>
            <li>Bis zu 25 % mehr Datenvolumen in den Internet-Optionen</li>
            <li>Anrufe und SMS von Deutschland in die EU kostenlos inklusive</li>
            <li>In weitere 40 Länder (z. B. USA, Kanada, Brasilien) für nur 9 Cent pro Minute telefonieren</li>
            <li>Für nur 14,99 € GigaTV inklusive HD Premium erleben</li>
          </ol>
          `}
        </Rte>,
        <Accordion
          headline="Headline"
          list={[
            {
              label: "Accordion Title",
              components: accordionBodySample,
            },
            {
              label: "Accordion Title",
              components: accordionBodySample,
            },
            {
              label: "Accordion Title",
              components: accordionBodySample,
            },
            {
              label: "Accordion Title",
              components: accordionBodySample,
            },
            {
              label: "Accordion Title",
              components: accordionBodySample,
            },
          ]}
        />,
        <Video
          headline={"Headline"}
          poster={videoPoster}
          src={"https://player.vimeo.com/video/354326902"}
          caption="Bildunterschrift: Mit dem Zusammenschluss von Unitymedia und Vodafone haben wir den größten Schritt ."
          theme={{
            [themeKeys.wrapper]: wrapperTypes.wide,
          }}
        />,
        <Tabs
          theme={{
            bg: contextThemeTypes.red
          }}
          headline="Headline"
          list={[
            {
              label: "Das ist die GigaKombi",
              components: tabsBodySample,
            },
            {
              label: "Diese Tarife sind Bestandteil",
              components: tabsBodySample,
            },
            {
              label: "Vorteile & Promotions",
              components: tabsBodySample,
            },
            {
              label: "Buchung",
              components: tabsBodySample,
            },
            {
              label: "Tutorial",
              components: tabsBodySample,
            },
            {
              label: "Werbemittel & Rechtstexte",
              components: tabsBodySample,
            },
          ]}
          defaultIndex={0}
        />,
        <Iconbox
          headline="Headline"
          list={[
            {
              Icon: SvgAppsMid,
              caption: "Das ist die GigaKombi",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
            },
            {
              Icon: SvgBillPayPhoneMid,
              caption: "Diese Tarife sind Bestandteil",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
            },
            {
              Icon: SvgBiometricAuthenticationMid,
              caption: "Vorteile & Promotions",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
            },
            {
              Icon: SvgBonusMid,
              caption: "Buchung",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
            },
            {
              Icon: SvgBraceletActiveMid,
              caption: "Tutorial",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
            },
            {
              Icon: SvgConnectivityMid,
              caption: "Werbemittel & Rechtstexte",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
            },
          ]}
        />,
        <MultiTeasers
          headline="Headline"
          theme={{
            bg: contextThemeTypes.lightestGrey
          }}
          list={[
            {
              image: images.magazin1,
              title: "Das ist die GigaKombi",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
              links: [{
                href: "#one",
                type: linkTypes.pdf,
                label: "Download PDF-Document",
              }, {
                href: "#two",
                type: linkTypes.internal,
                label: "Interne Unterseite besuchen",
              }]
            },
            {
              image: images.magazin2,
              title: "Das ist die GigaKombi",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
              links: [{
                href: "#one",
                type: linkTypes.pdf,
                label: "Download PDF-Document",
              }, {
                href: "#two",
                type: linkTypes.external,
                label: "Externe Website",
              }]
            },
            {
              image: images.magazin3,
              title: "Das ist die GigaKombi",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
              links: [{
                href: "#one",
                type: linkTypes.pdf,
                label: "Download PDF-Document",
              }]
            },
            {
              image: images.magazin4,
              title: "Das ist die GigaKombi",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
              links: [{
                href: "#one",
                type: linkTypes.pdf,
                label: "Download PDF-Document",
              }, {
                href: "#two",
                type: linkTypes.internal,
                label: "Interne Unterseite besuchen",
              }]
            },
            {
              image: images.magazin5,
              title: "Das ist die GigaKombi",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
              links: [{
                href: "#two",
                type: linkTypes.external,
                label: "Externe Website",
              }]
            },
            {
              image: images.magazin6,
              title: "Das ist die GigaKombi",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
              links: [{
                href: "#one",
                type: linkTypes.pdf,
                label: "Download PDF-Document",
              }, {
                href: "#two",
                type: linkTypes.external,
                label: "Externe Website",
              }]
            },
            {
              Icon: SvgBillPayPhoneMid,
              title: "Diese Tarife sind Bestandteil",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
              links: [{
                href: "#one",
                type: linkTypes.pdf,
                label: "Download PDF-Document",
              }, {
                href: "#two",
                type: linkTypes.external,
                label: "Externe Website",
              }]
            },
            {
              Icon: SvgBiometricAuthenticationMid,
              title: "Vorteile & Promotions",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
              links: [{
                href: "#one",
                type: linkTypes.pdf,
                label: "Download PDF-Document",
              }]
            },
            {
              Icon: SvgBonusMid,
              title: "Buchung",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
              links: [{
                href: "#one",
                type: linkTypes.external,
                label: "Externe Website",
              }]
            },
            {
              Icon: SvgBraceletActiveMid,
              title: "Tutorial",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
              links: [{
                href: "#two",
                type: linkTypes.internal,
                label: "Interne Unterseite besuchen",
              }]
            },
            {
              Icon: SvgConnectivityMid,
              title: "Werbemittel & Rechtstexte",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
              links: [{
                href: "#one",
                type: linkTypes.pdf,
                label: "Download PDF-Document",
              }, {
                href: "#two",
                type: linkTypes.external,
                label: "Externe Website",
              }]
            },
            {
              Icon: SvgConnectivityMid,
              title: "Werbemittel & Rechtstexte",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
              links: [{
                href: "#one",
                type: linkTypes.pdf,
                label: "Download PDF-Document",
              }, {
                href: "#two",
                type: linkTypes.external,
                label: "Externe Website",
              }]
            },
            {
              title: "Tutorial",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
              links: [{
                href: "#two",
                type: linkTypes.internal,
                label: "Interne Unterseite besuchen",
              }]
            },
            {
              title: "Werbemittel & Rechtstexte",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
              links: [{
                href: "#one",
                type: linkTypes.pdf,
                label: "Download PDF-Document",
              }, {
                href: "#two",
                type: linkTypes.external,
                label: "Externe Website",
              }]
            },
            {
              title: "Werbemittel & Rechtstexte",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen. Manchmal benutzt man Worte",
              links: [{
                href: "#one",
                type: linkTypes.pdf,
                label: "Download PDF-Document",
              }, {
                href: "#two",
                type: linkTypes.external,
                label: "Externe Website",
              }]
            },
          ]}
        />,
        <Downloads
          headline="Headline"
          theme={{
            bg: contextThemeTypes.red
          }}
          list={[
            {
              type: downloadLinkTypes.pdf,
              caption: "Preisliste November 2019",
              text: "Stand: 05.11.2019",
              link: "#d1",
            },
            {
              type: downloadLinkTypes.pdf,
              caption: "Preisliste November 2019",
              text: "Stand: 05.11.2019",
              link: "#d1",
            },
            {
              type: downloadLinkTypes.pdf,
              caption: "Preisliste November 2019",
              text: "Stand: 05.11.2019",
              link: "#d1",
            },
            {
              type: downloadLinkTypes.pdf,
              caption: "Preisliste November 2019",
              text: "Stand: 05.11.2019",
              link: "#d1",
            },
            {
              type: downloadLinkTypes.pdf,
              caption: "Preisliste November 2019",
              text: "Stand: 05.11.2019",
              link: "#d1",
            },
            {
              type: downloadLinkTypes.pdf,
              caption: "Preisliste November 2019",
              text: "Stand: 05.11.2019",
              link: "#d1",
            },
          ]}
        />,
        <KeyFigures
          headline="Headline"
          theme={{
            bg: contextThemeTypes.lightestGrey
          }}
          list={[
            {
              value: "1234567",
              caption: "Viel schneller",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen.",
            },
            {
              value: "256 KBit",
              caption: "Viel schneller",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen.",
            },
            {
              value: "2 GB",
              caption: "Viel schneller",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen.",
            },
            {
              value: "8,5 h",
              caption: "Viel schneller",
              text: "Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle Buchstaben da sind und wie sie aussehen.",
            },
          ]}
        />,
        <LightboxGallery
          list={galleryList}
          theme={{
            [themeKeys.wrapper]: wrapperTypes.wide,
          }}
        >
          {({ list, showModal, showLightboxIndex }) => (
            <Gallery
              headline={"Headline"}
              withLightbox
              showModal={showModal}
              showLightboxIndex={showLightboxIndex}
              list={list}
            />
          )}
        </LightboxGallery>,
        <TextMedia
          list={[
            {
              images: [images.tmDummy01],
              imageSize: textMediaImageSizeTypes.s,
              text: `
                <h3>Headline</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum.
                </p>
              `
            },
            {
              images: [images.tmDummy02],
              imageSize: textMediaImageSizeTypes.l,
              layout: textMediaItemLayoutTypes.imageLeft,
              text: `
                <h3>Headline</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum.
                </p>
              `
            },
            {
              images: [images.tmDummy03],
              imageSize: textMediaImageSizeTypes.l,
              text: `
                <h3>Headline</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum.
                </p>
              `
            },
            {
              images: [images.tmDummy04],
              imageSize: textMediaImageSizeTypes.s,
              layout: textMediaItemLayoutTypes.imageLeft,
              text: `
                <h3>Headline</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum.
                </p>
              `
            }
          ]}
        />,
        <TextMedia
          theme={{
            layout: textMediaLayoutTypes.columns
          }}
          list={[
            {
              images: [images.tmDummy05],
              layout: textMediaItemLayoutTypes.imageTop,
              text: `
                <h3>Headline</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum.
                </p>
              `
            },
            {
              images: [images.tmDummy06],
              layout: textMediaItemLayoutTypes.imageTop,
              text: `
                <h3>Headline</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum.
                </p>
              `
            }
          ]}
        />,
        <TextMedia
          theme={{
            layout: textMediaLayoutTypes.columns
          }}
          list={[
            {
              images: [images.tmDummy07],
              layout: textMediaItemLayoutTypes.imageTop,
              text: `
                <h3>Headline</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum.
                </p>
              `
            },
            {
              images: [images.tmDummy08],
              layout: textMediaItemLayoutTypes.imageTop,
              text: `
                <h3>Headline</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum.
                </p>
              `
            },
            {
              images: [images.tmDummy09],
              layout: textMediaItemLayoutTypes.imageTop,
              text: `
                <h3>Headline</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum.
                </p>
              `
            }
          ]}
        />,
        <TextMedia
          list={[
            {
              images: [
                images.tmDummy10,
                images.tmDummy11,
              ],
              imageSize: textMediaImageSizeTypes.l,
              layout: textMediaItemLayoutTypes.imageRightMultiple,
              text: `
                <h3>Headline</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum.
                </p>
              `
            },
            {
              images: [
                images.tmDummy09,
                images.tmDummy08,
              ],
              layout: textMediaItemLayoutTypes.imageTopMultiple,
              text: `
                <h3>Headline</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum.
                </p>
              `
            },
          ]}
        />,
        <TextMedia
          list={[
            {
              images: [
                images.tmDummy10,
                images.tmDummy13,
                images.tmDummy12,
                images.tmDummy11,
              ],
              layout: textMediaItemLayoutTypes.imageTopMultiple,
              text: `
                <h3>Headline</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum.
                </p>
              `
            },
            {
              images: [
                images.tmDummy04,
                images.tmDummy05,
                images.tmDummy06,
              ],
              layout: textMediaItemLayoutTypes.imageTopMultiple,
              text: `
                <h3>Headline</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Donec elementum ligula eu sapien consequat eleifend. Donec nec dolor erat, condimentum sagittis sem. Praesent porttitor porttitor risus, dapibus rutrum ipsum.
                </p>
              `
            },
          ]}
        />,
        <TableExample />,
        <Table data={tableExample2Data} theme={{
          [themeKeys.wrapper]: wrapperTypes.wide,
        }} />,
        <TableExample3 />,
        <TableExample4 />,
        <TableExample5 />,
      ] })}
    </Page>
  );
};

