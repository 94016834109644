import React, { useContext } from 'react';
import theme from 'styled-theming';
import styled, { css, ThemeContext } from 'styled-components/macro';

import { xz, gradient, color } from '#theme';
import { contextThemeTypes, contextLevelTypes, themeKeys } from '#helpers';

export const Container = styled.section`
  position: relative;
  padding: ${({ noPadding }) => noPadding
		? 0
		: `${xz(8)} 0`};
	/* &:first-of-type {
		padding-top: 0;
	} */
	${theme(themeKeys.level, {
		[contextLevelTypes.inside]: css`
			padding-top: ${xz(3)};
			padding-bottom: ${xz(3)};
		`
	})}
`;
const SectionWhite = styled(Container)`
	background-color: transparent;
	& + & {
		padding-top: 0;
	}
`;
const SectionRed = styled(Container)`
	background-color: ${color.red};
	color: ${color.white};
	& + & {
		padding-top: 0;
	}
`;
const SectionRed45 = styled(Container)`
	background-image: ${gradient.red45};
	color: ${color.white};
	& + & {
		padding-top: 0;
	}
`;
const SectionLightestGrey = styled(Container)`
	background-color: ${color.lightestGrey};
	& + & {
		padding-top: 0;
	}
`;
const SectionImage = styled(Container)`
	background-color: transparent;
	color: ${color.white};
	padding-top: 0;
	padding-bottom: 0;
`;

const sections = {
	[contextThemeTypes.default]: SectionWhite,
	[contextThemeTypes.red]: SectionRed,
	[contextThemeTypes.red45]: SectionRed45,
	[contextThemeTypes.lightestGrey]: SectionLightestGrey,
	[contextThemeTypes.image]: SectionImage,
};

export const Section = ({
	noPadding,
	children,
}) => {
	const {
		bg = contextThemeTypes.default,
	} = useContext(ThemeContext);
	
	const SectionContainer = sections[bg];
  return (
    <SectionContainer noPadding={noPadding}>
      {children}
    </SectionContainer>
  );
};

