import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { color, bp, defTransition } from '#theme';
import { rgba } from 'polished';
import { Wrapper } from '#ui';

const Container = styled.div`
  background-color: ${color.textGrey};
  color: ${rgba(color.white, 0.75)};
  padding: 16px 0;
  font-size: 0.75rem;
  font-weight: bold;
  @media ${bp.m} {
    font-size: 0.875rem;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${bp.m} {
    flex-direction: row;
    justify-content: space-between;
  }
`;
const Links = styled.div`
  display: flex;
`;
const LinksItem = styled.div`
  & + &::before {
    content: "|";
    margin-left: 0.5em;
    margin-right: 0.5em;
    color: ${color.white};
  }
`;
const StyledLink = styled(Link)`
  color: ${color.white};
  transition: color ${defTransition};
  &:hover {
    color: ${color.middleGrey};
  }
`;
const Copy = styled.div`
  color: ${color.white};
  font-weight: normal;
`;

export const Footer = () => (
  <Container>
    <Wrapper noGrid>
      <Content>
        <Links>
          <LinksItem>
            <StyledLink to={"#"}>Impressum</StyledLink>
          </LinksItem>
          <LinksItem>
            <StyledLink to={"#"}>Datenschutz</StyledLink>
          </LinksItem>
        </Links>
        <Copy>
          © 2020 Vodafone GmbH
        </Copy>
      </Content>
    </Wrapper>
  </Container>
);
