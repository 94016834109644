import React, { useRef, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components/macro';

import { color, slideInFromRight, slideOutToRight, fadeIn, fadeOut, bp } from '#theme';
import { rgba } from 'polished';
import { Scrollbars, ButtonIcon, iconTypes, UserSettings } from '#ui';
import { Bookmarks } from '../Bookmarks/Bookmarks';
import { useOnClickOutside } from '#hooks';

const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: ${rgba(color.black, 0.5)};
  ${props => props.isOpened ? fadeIn : fadeOut}
  
`;
const Inner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 320px;
  max-width: 360px;
  background-color: ${color.white};
  display: flex;
  flex-direction: column;
  box-shadow: 0px 24px 48px ${rgba(color.black, 0.5)};
  ${props => props.isOpened ? slideInFromRight : slideOutToRight}
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px 20px;
  @media ${bp.m} {
    padding-top: 32px;
  }
`;
const Group = styled.div`
  display: flex;
`;
const Action = styled.div`
  padding-bottom: 6px;
  border-bottom: 2px solid ${({ isActive }) => isActive ? color.red : "transparent"};
  & + & {
    margin-left: 12px;
  }
`;
const Content = styled.div`
  flex: 1 0 0%;
  position: relative;
  display: flex;
`;
const Box = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;


export const userMenuComponentsTypes = {
  bookmarks: "bookmarks",
  userSettings: "userSettings",
};
const userMenuComponents = {
  [userMenuComponentsTypes.bookmarks]: Bookmarks,
  [userMenuComponentsTypes.userSettings]: UserSettings,
}

export const UserMenu = ({ user, isOpened, handleClose }) =>  {
  const innerRef = useRef();
  useOnClickOutside(innerRef, () => {
    if(isOpened) {
      handleClose();
    }
  });
  const [index, setIndex] = useState(0);
  const handleSetIndex = (itemKey) => {
    if (itemKey !== index) {
       setIndex(itemKey);
    }
  };
  const actionOnClick = () => {
    handleClose();
  };
  return (
    <Sidebar isOpened={isOpened}>
      <Inner isOpened={isOpened} ref={innerRef}>
        <Top>
          <Group>
            {user.tabs.map((tab, key) => (
              <Action isActive={key === index} onClick={() => handleSetIndex(key)} key={key}>
                <ButtonIcon type={tab.iconType} isActive={key === index} />
              </Action>
            ))}
          </Group>
          <Group>
            <Action onClick={actionOnClick}>
              <ButtonIcon type={iconTypes.close} isActive={false} />
            </Action>
          </Group>
        </Top>
        <Content>
          <SwipeableViews
            style={{ flex: "1 0 0%" }}
            containerStyle={{ height: '100%' }}
            index={index}
            onChangeIndex={handleSetIndex}
          >
            {user.tabs.map((tab, key) => {
              const Component = userMenuComponents[tab.component];
              return (
                <Box key={key}>
                  <Scrollbars>
                    <Component {...tab.props} />
                  </Scrollbars>
                </Box>
              );
            })}
          </SwipeableViews>
        </Content>
      </Inner>
    </Sidebar>
  );
};