import React from 'react';
import styled from 'styled-components/macro';
import {
  SvgCheck,
  SvgClose,
  SvgSearch,
  SvgKebab,
  SvgBookmark,
  SvgUser,
  SvgBurger,
  SvgBurgerClose,
  SvgDelete,
  SvgLivestream,
  SvgTraining,
  SvgWebinar,
  SvgWorkshop,
 } from '#theme';

export const iconTypes = {
  check: "check",
  close: "close",
  search: "search",
  bookmark: "bookmark",
  user: "user",
  kebab: "kebab",
  burger: "burger",
  burgerClose: "burgerClose",
  delete: "delete",
  livestream: "livestream",
  training: "training",
  webinar: "webinar",
  workshop: "workshop",
};
const icons = {
  [iconTypes.check]: SvgCheck,
  [iconTypes.close]: SvgClose,
  [iconTypes.search]: SvgSearch,
  [iconTypes.kebab]: SvgKebab,
  [iconTypes.bookmark]: SvgBookmark,
  [iconTypes.user]: SvgUser,
  [iconTypes.burger]: SvgBurger,
  [iconTypes.burgerClose]: SvgBurgerClose,
  [iconTypes.delete]: SvgDelete,
  [iconTypes.livestream]: SvgLivestream,
  [iconTypes.training]: SvgTraining,
  [iconTypes.webinar]: SvgWebinar,
  [iconTypes.workshop]: SvgWorkshop,
};
const StyledIcon = styled(({ SvgIcon, fill, ...props }) => <SvgIcon {...props} />)`
  width: 32px;
  height: 32px;
  fill: ${({ fill }) => fill};
`;

export const Icon = ({ type, color }) => {
  return (
    <StyledIcon SvgIcon={icons[type]} fill={color} />
  );
};