export const colorEnums = {
  red: "red",
  darkRed: "darkRed",
  maroon: "maroon",
  aubergine: "aubergine",
  redViolet: "redViolet",
  turquoise: "turquoise",
  aquaBlue: "aquaBlue",
  lightGrey: "lightGrey",
  middleGrey: "middleGrey",
  textGrey: "textGrey",
  grey: "grey",
  lightestGrey: "lightestGrey",
  white: "white",
  black: "black",
  green: "green",
  gold: "gold",
};

export const color = {
  [colorEnums.red]: "#E60000",
  [colorEnums.darkRed]: "#BD0000",
  [colorEnums.maroon]: "#900",
  [colorEnums.aubergine]: "#5E2750",
  [colorEnums.redViolet]: "#9C2AA0",
  [colorEnums.turquoise]: "#007C92",
  [colorEnums.aquaBlue]: "#00B0CA",
  [colorEnums.lightGrey]: "#CCC",
  [colorEnums.middleGrey]: "#999",
  [colorEnums.textGrey]: "#333",
  [colorEnums.grey]: "#4A4D4E",
  [colorEnums.lightestGrey]: "#F4F4F4",
  [colorEnums.white]: "#fff",
  [colorEnums.black]: "#000",
  [colorEnums.green]: "#19964B",
  [colorEnums.gold]: "#FFC800",
};

export const colorsTooBright = [
  colorEnums.lightestGrey,
  colorEnums.white,
];