import { color } from "./color";
import { defStyle } from "./vars";

export const xz = (n) => `calc(${n} * var(--X))`;

export const clearIframe = `
  border: 0;
`;

export const clearFigure = `
  margin: 0;
`;

export const clearFix = `
  &::before, &::after {
    display: table;
    content: " ";
  }
  &::after {
    clear: both;
  }
`;

export const clearBtn = `
  -webkit-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: 0;
  padding: 0;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
`;

export const clearList = `
  margin: 0;
  padding: 0;
`;
export const clearListItem = `
  display: block;
`;

export const fadeIn = `
  opacity: 1;
  visibility: visible;
  transition: opacity ${defStyle.duration} ${defStyle.easing}, visibility 0s linear;
`;
export const fadeOut = `
  opacity: 0;
  visibility: hidden;
  transition: opacity ${defStyle.duration} ${defStyle.easing}, visibility 0s linear ${defStyle.duration};
`;

export const slideInFromRight = `
  visibility: visible;
  transform: translateX(0);
  transition: transform ${defStyle.duration} ${defStyle.easing}, visibility 0s linear;
`;
export const slideOutToRight = `
  visibility: hidden;
  transform: translateX(100%);
  transition: transform ${defStyle.duration} ${defStyle.easing}, visibility 0s linear ${defStyle.duration};
`;

export const defTransition = `${defStyle.duration} ${defStyle.easing}`;

export const expander = `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const overlay = (ext) => `
  &::after {
    content: "";
    ${expander}
    ${ext}
  }
`;

export const animatedUnderline = (Parent, props = { c: color.red, chover: color.maroon }) => `
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${props.c};
    transition: transform ${defTransition};
    transform: translateX(calc(-100% - 1px));
  }
  ${Parent
    ? `${Parent}:hover &::after`
    : `&:hover::after`
  } {
    border-bottom-color: ${props.chover};
    transform: translateX(0);
  }
`;