import React, { useMemo } from 'react';
import { Table, Rte, Image, Link, linkTypes } from '#components';
import { H1, Text } from '#ui';
import { SvgAppsMid } from '#theme';
import { images } from '#data';
import { contextThemeTypes } from '#helpers';

export const TableExample = () => {
  const data = useMemo(
    () => ({
      thTheme: contextThemeTypes.red,
      thead: [
        [
          {
            component: Text,
            children: 'Tarif M',
            cellProps: {
              as: "th",
              theme: {
                bg: contextThemeTypes.red,
              },
            },
          },
          {
            component: Text,
            children: 'Tarif L',
            cellProps: {
              as: "th",
              theme: {
                bg: contextThemeTypes.red,
              },
            },
          },
          {
            component: Text,
            children: 'Tarif XL',
            cellProps: {
              as: "th",
              theme: {
                bg: contextThemeTypes.red,
              },
            },
          },
        ]
      ],
      tbody: [
        [
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
              as: "th",
            },
            props: {
              className: "textGrey",
            },
            children: `Young`,
          },
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
              as: "th",
            },
            props: {
              className: "textGrey",
            },
            children: `Not So Young`,
          },
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
              as: "th",
            },
            props: {
              className: "textGrey",
            },
            children: `Senior`,
          },
        ],
        [
          {
            component: H1,
            children: "256 GB",
          },
          {
            component: H1,
            children: "512 GB",
          },
          {
            component: H1,
            children: "1024 GB",
          },
        ],
        [
          {
            component: SvgAppsMid,
            props: {
              style: {
                width: 96,
                height: 96,
                display: 'block',
              }
            },
          },
          {
            component: SvgAppsMid,
            props: {
              style: {
                width: 96,
                height: 96,
                display: 'block',
              }
            },
          },
          {
            component: SvgAppsMid,
            props: {
              style: {
                width: 96,
                height: 96,
                display: 'block',
              }
            },
          },
        ],
        [
          {
            component: Rte,
            cellProps: {
              style: {
                verticalAlign: 'top',
              },
            },
            children: "Et adipiscing viverra aliquet tincidunt volutpat diam venenatis. Arcu dui, quam nisi, sed auctor aliquam. Dolor feugiat ornare pulvinar sed ultrices nibh. Nulla dui dui amet, vel donec integer malesuada pretium, ut. Ipsum faucibus eget sit sed massa.",
          },
          {
            component: Rte,
            cellProps: {
              style: {
                verticalAlign: 'top',
              },
            },
            children: "Et adipiscing viverra aliquet tincidunt volutpat diam venenatis. Arcu dui, quam nisi.",
          },
          {
            component: Rte,
            cellProps: {
              style: {
                verticalAlign: 'top',
              },
            },
            children: "Et adipiscing viverra aliquet tincidunt volutpat diam venenatis. Arcu dui, quam nisi, sed auctor aliquam. Dolor feugiat ornare pulvinar sed ultrices nibh. Nulla dui dui amet, vel donec integer malesuada pretium,",
          },
        ],
        [
          {
            component: Image,
            innerProps: {
              style: {
                height: 256,
              },
            },
            props: {
              src: images.galleryTestImage01,
            }
          },
          {
            component: Image,
            innerProps: {
              style: {
                height: 256,
              },
            },
            props: {
              src: images.galleryTestImage02,
            }
          },
          {
            component: Image,
            innerProps: {
              style: {
                height: 256,
              },
            },
            props: {
              src: images.galleryTestImage03,
            }
          },
        ],
        [
          {
            component: Link,
            props: {
              type: linkTypes.pdf,
              label: "Preisliste 2019",
              href: "#link-to-pdf",
            }
          },
          {
            component: Link,
            props: {
              type: linkTypes.external,
              label: "Visit website",
              href: "/link-to-pdf",
            }
          },
          {
            component: Link,
            props: {
              type: linkTypes.pdf,
              label: "Preisliste 2019",
              href: "#link-to-pdf",
            }
          },
        ],
      ]
    }),
    []
  );
  return (
    <Table data={data} />
  );
};