import React from 'react';
import PropTypes from 'prop-types';

import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import { GridOverlay } from '../GridOverlay/GridOverlay';
import { site, search, user } from '#data';
import styled from 'styled-components/macro';

const Container = styled.div`
  overflow: hidden;
`;

export const Root = ({ children, nav }) => (
  <Container>
    <Header site={site} search={search} user={user} nav={nav} />
    {children}
    <Footer />
    <GridOverlay />
  </Container>
);

Root.propTypes = {
  children: PropTypes.any.isRequired,
};