import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const Img = styled.div`
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ src }) => src});
`;

export const Image = ({ className, src, children }) => (
  <Container className={className}>
    {src && <Img src={src} />}
    {children}
  </Container>
);