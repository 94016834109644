import React from 'react';
import { ModalProvider } from "react-modal-hook";

import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { GlobalStyle } from '#ui';
import { Root, BuilderWrapper } from '#components';
import { Components, HomeComponents, nav } from '#pages';

const renderNav = (page, key) => {
  return (
    <Route key={key} path={"/" + page.pageId} component={page.component} />
  );
};

const RouteRoot = ({ match }) => (
  <BuilderWrapper>
    <Root nav={nav}>
      {nav.map(renderNav)}
      <Route path="/components" component={Components} />
      <Route path="/homecomponents" component={HomeComponents} />
      {/* {!nav.find((navItem) => navItem.pageId === match.params.pageId || navItem.pageId !== "components") && (
        <Redirect to="/magazine"/>
      )} */}
    </Root>
  </BuilderWrapper>
);

export const App = () => {
  return (
    <Router>
      <GlobalStyle />
      <ModalProvider>
        <Switch>
          <Route path="/:pageId" component={RouteRoot} />
          <Redirect from="/" to="/magazine"/>
        </Switch>
      </ModalProvider>
    </Router>
  );
};
