import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider, css } from 'styled-components/macro';
import theme from 'styled-theming';

import { Rte } from '../Rte/Rte';
import { H1, Image, H2, Meta, Badge } from '#ui';
import { color, bp, xz, grid, maxWrapperWidth, wrapperProps, maxWrapperWidthWide } from '#theme';
import { contextThemeTypes, contextLevelTypes, themeKeys } from '#helpers';

export const articleHeaderLayoutTypes = {
  A: "A",
  B: "B",
};

const Container = styled.div`
  ${theme('layout', {
    [articleHeaderLayoutTypes.B]: css`
      @media ${bp.p} {
        display: flex;
        justify-content: center;
      }
    `,
  })}
`;

const Visual = styled.div`
  ${theme('layout', {
    [articleHeaderLayoutTypes.A]: css`
      height: 480px;
      @media ${bp.t} {
        height: 556px;
      }
      @media ${bp.d} {
        height: 684px;
      }
    `,
    [articleHeaderLayoutTypes.B]: css`
      height: 480px;
      @media ${bp.p} {
        flex: 1 0 0%;
        height: auto;
        min-height: 480px;
      }
      @media ${bp.t} {
        min-height: 556px;
      }
      @media ${bp.d} {
        min-height: 684px;
      }
      
    `,
  })}
`;

const Attribute = styled.div`
  position: absolute;
  z-index: 1;
  top: ${xz(3)};
  right: ${xz(3)};
  @media ${bp.p} {
    top: ${xz(4)};
    right: ${xz(4)};
  }
  @media ${bp.t} {
    top: ${xz(6)};
    right: ${xz(6)};
  }
  @media ${bp.d} {
    top: ${xz(8)};
    right: ${xz(8)};
  }
`;

const Main = styled.div`
  position: relative;
  @media ${bp.m} {
    margin-top: ${xz(-8)};
    ${wrapperProps.paddings}
  }
  ${theme( 'layout', {
    [articleHeaderLayoutTypes.A]: css`
      ${wrapperProps.maxWidthWide}
      @media ${bp.p} {
        margin-left: auto;
        margin-right: auto;
      }
    `,
    [articleHeaderLayoutTypes.B]: css`
      @media ${bp.p} {
        margin-top: 0;
        flex: 1 0 0%;
        padding-left: 0;
        padding-right: 0;
      }
    `,
  })}
`;
const Box = styled.div`
  padding-top: ${xz(6)};
  padding-bottom: ${xz(8)};
  ${theme( 'layout', {
    [articleHeaderLayoutTypes.B]: css`
      @media ${bp.p} {
        height: 100%;
      }
    `
  })}
  ${theme('bg', {
    [contextThemeTypes.lightestGrey]: css`
      background-color: ${color.lightestGrey};
    `,
    [contextThemeTypes.red]: css`
      background-color: ${color.red};
    `,
  })}
`;

const Content = styled.div`
  ${wrapperProps.paddings}
  @media ${bp.m} {
    padding-left: ${xz(6)};
    padding-right: ${xz(6)};
  }
  @media ${bp.d} {
    padding-left: ${xz(8)};
    padding-right: ${xz(8)};
  }
  ${theme(themeKeys.layout, {
    [articleHeaderLayoutTypes.B]: css`
      @media ${bp.p} {
        margin-left: 0;
      }
    `,
  })}
`;
const MetaData = styled(Meta)`
  margin-bottom: ${xz(3)};
  @media ${bp.p} {
    margin-bottom: ${xz(4)};
  }
  ${theme( 'layout', {
    [articleHeaderLayoutTypes.A]: css`
      @media ${bp.t} {
        text-align: center;
      }
    `,
  })}
`;
const Headline = styled(H1)`
  font-size: 2rem;
  line-height: 1;
  margin-bottom: ${xz(4)};
  @media ${bp.m} {
    font-size: 2.625rem;
  }
  @media ${bp.p} {
    font-size: 3.25rem;
  }
  @media ${bp.t} {
    grid-column-start: 1;
    grid-column-end: span 6;
    font-size: 3.875rem;
  }
  ${({ hasText }) => theme('layout', {
    [articleHeaderLayoutTypes.A]: hasText && css`
      @media ${bp.t} {
        margin-bottom: 0;
        padding-right: ${xz(6)};
      }
    `,
  })}
`;
const Entry = styled.div`
  ${({ hasText }) => theme('layout', {
    [articleHeaderLayoutTypes.A]: css`
      @media ${bp.t} {
        max-width: ${maxWrapperWidth}px;
        margin-left: auto;
        margin-right: auto;
        ${hasText
          ? grid(12)
          : css`
            text-align: center;
          `
        }
      }
    `,
    [articleHeaderLayoutTypes.B]: css`
      @media ${bp.p} {
        display: block;
        max-width: ${maxWrapperWidthWide / 2}px;
      }
    `,
  })}
`;
const Desc = styled.div`
  @media ${bp.t} {
    grid-column-start: 7;
    grid-column-end: span 6;
  }
`;
const Subheadline = styled(H2)`
  margin-bottom: ${xz(6)};
`;

export const ArticleHeader = ({
  image,
  category,
  date,
  badge,
  headline,
  subheadline,
  copytext,
  boxTheme = contextThemeTypes.lightestGrey,
  badgeTheme = contextThemeTypes.red,
}) => {
  return (
    <Container>
      <Visual>
        <Image src={image}>
          {badge && (
            <Attribute>
              <ThemeProvider theme={{
                badgeTheme,
              }}>
                <Badge>
                  {badge}
                </Badge>
              </ThemeProvider>
            </Attribute>
          )}
        </Image>
      </Visual>
      <Main>
        <ThemeProvider theme={{
          [themeKeys.bg]: boxTheme,
        }}>
          <Box>
            <Content>
              <MetaData
                date={date}
                category={category}
              />
              <Entry hasText={Boolean(copytext)}>
                <Headline hasText={Boolean(copytext)}>
                  {headline}
                </Headline>
                <Desc>
                  <Subheadline className="textGrey">{subheadline}</Subheadline>
                  {copytext && <Rte level={contextLevelTypes.inside}>{copytext}</Rte>}
                </Desc>
              </Entry>
            </Content>
          </Box>
        </ThemeProvider>
      </Main>
    </Container>
  )

};

ArticleHeader.propTypes = {
  boxTheme: PropTypes.string,
  image: PropTypes.string,
  category: PropTypes.string,
  date: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  copytext: PropTypes.string.isRequired,
};