import React from 'react';
import styled from 'styled-components/macro';
import { bp } from '#theme';
import { H1 } from '../H1/H1';

export const StyledH3 = styled(H1)`
  font-weight: bold;
  line-height: 1.2;
  font-size: 1.125rem;
  @media ${bp.p} {
    font-size: 1.375rem;
  }
  @media ${bp.d} {
    font-size: 1.5rem;
  }
`;

export const H3 = ({ children, ...restProps }) => {
  return (
    <StyledH3 as="h3" {...restProps}>{children}</StyledH3>
  );
};
