import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';

import { rgba } from 'polished';
import { Link } from 'react-router-dom';

import {  InputDefault, Wrapper, Grid, iconTypes, ButtonIcon } from "#ui";
import { color, bp, fadeOut, fadeIn, defTransition, maxWidth, screenSize, xz } from '#theme';
import { useOnClickOutside } from '#hooks';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  ${({isActive }) => !isActive && css`
    pointer-events: none;
  `}
  @media ${maxWidth(screenSize.p)} {
    ${({ isActive }) => !isActive
      ? fadeOut
      : fadeIn
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${rgba(color.black, 0.5)};
    ${({ isActive }) => !isActive
      ? fadeOut
      : fadeIn
    }
  }
`;

const Content = styled.div`
  grid-column-end: 4 span;
  position: relative;
  z-index: 3;
  margin-top: 16px;
  transition: border-bottom-color ${defTransition};
  height: 40px;
  ${({ isActive }) => isActive && css`
    background-color: ${color.white};
    box-shadow: 0px 24px 48px ${rgba(color.black, 0.15)};
  `}
  @media ${bp.m} {
    height: 55px;
  }
  @media ${bp.p} {
    border-bottom-color: ${({ isActive }) => !isActive ? color.red : color.white};
    grid-column-start: 5;
    pointer-events: all;
  }
  @media ${bp.d} {
    grid-column-start: 4;
    grid-column-end: 6 span;
  }
`;

const SearchField = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  height: 100%;
  border-bottom: 1px solid transparent;
  ${({ isActive }) => isActive && css`
    border-bottom-color: ${color.white};
  `}
  @media ${bp.p} {
    border-bottom-color: ${({ isActive }) => !isActive ? color.red : color.white};
    padding: 4px 16px;
  }
`;
const SearchInput = styled.div`
  overflow: hidden;
  height: 100%;
  flex: 1 0 0%;
  @media ${bp.p} {
    width: 100%;
  }
`;
const Input = styled(InputDefault)`
  height: 100%;
  color: ${color.red};
  font-size: 1.5rem;
  font-weight: 300;
  width: 100%;
  padding-top: 4px;
  @media ${bp.m} {
    font-size: 1.75rem;
  }
  &::placeholder {
    transition: color ${defTransition};
    color: ${({ isActive }) => !isActive ? rgba(color.black, 0.4) : color.middleGrey};
  }
`;

const Icon = ({ isActive, onClick }) => {
  return !isActive ? (
    <ButtonIcon type={iconTypes.search} onClick={onClick} />
  ) : (
    <ButtonIcon type={iconTypes.close} isActive={isActive} onClick={onClick} />
  );
};

const Suggestions = styled.div`
  background-color: ${color.white};
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: ${xz(2)} 4px 0;
  font-size: 0.875rem;
  @media ${bp.m} {
    font-size: 1rem;
    padding: ${xz(4)} 8px 0;
  }
`;
const SuggestionsItem = styled.div`
  cursor: pointer;
  padding: 8px 12px;
  @media ${bp.m} {
    padding-left: 8px;
    padding-right: 8px;
  }
  mark {
    background-color: transparent;
    color: ${color.red};
  }
  &:hover {
    background-color: ${color.lightestGrey};
  }
`;


const Trending = styled.div`
  padding-bottom: 24px;
  position: relative;
  z-index: 1;
  font-size: 0.625rem;
  grid-column-end: 4 span;
  @media ${bp.m} {
    font-size: 0.875rem;
    padding-bottom: ${xz(6)};
  }
  @media ${bp.p} {
    grid-column-start: 3;
    grid-column-end: 8 span;
  }
  ${({ isActive }) => isActive ? fadeIn : fadeOut}
`;
const TrendingHeader = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 32px;
  color: ${color.maroon};
  @media ${bp.m} {
    height: ${xz(6)};
  }
`;
const TrendingList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const TrendingItem = styled.li`
  display: block;
  margin-bottom: 8px;
`;
const TrendingLink = styled(Link)`
  display: block;
  color: ${color.white};
  height: 20px;
  font-weight: bold;
  line-height: 19px;
  padding: 0 8px;
  margin: 0 2px;
  background-color: ${color.darkRed};
  border-radius: 16px;
  text-decoration: none;
  transition: background-color ${defTransition}, color ${defTransition};
  &:hover {
    background-color: ${color.white};
    color: ${color.red};
  }
  @media ${bp.m} {
    height: 28px;
    line-height: 26px;
    padding: 0 16px;
    margin: 0 4px;
  }
`;
const Inner = styled.div`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${color.red};
    z-index: ${({ isActive }) => !isActive ? -1 : 1};
    ${({ isActive }) => !isActive
      ? fadeOut
      : fadeIn
    }
  }
`;
const Box = styled.div`
  position: relative;
  z-index: 2;
`;


export const SearchForm = ({
  search,
  isOpened,
  handleOpen,
  handleClose,
  fieldId,
}) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState(search.suggestions);
  const handleChange = ({ target }) => {
    setValue(target.value);
    const filteredSuggestions = [];
    search.suggestions.forEach((item) => {
      if (item.indexOf(target.value) === -1) return;
      const rxp = new RegExp(target.value);
      const filteredItem = item.replace(rxp, `<mark>${target.value}</mark>`);
      filteredSuggestions.push(filteredItem);
    });
    setSuggestions(filteredSuggestions);
  }
  const innerRef = useRef();
  const handleClick = () => {
    setValue("");
    !isOpened ? handleOpen() : handleClose();
  }
  useOnClickOutside(innerRef, () => {
    if(isOpened) {
      handleClick();
    }
  });
  return (
    <Container isActive={isOpened}>
      <Inner isActive={isOpened} ref={innerRef}>
        <Box>
          <Wrapper noGrid>
            <Grid>
              <Content isActive={isOpened}>
                <SearchField isActive={isOpened}>
                  <SearchInput>
                    <Input
                      placeholder="Suche"
                      onFocus={() => handleOpen()}
                      onChange={handleChange}
                      isActive={isOpened}
                      value={value}
                      id={fieldId}
                    />
                  </SearchInput>
                  <Icon isActive={isOpened} onClick={handleClick} />
                </SearchField>
                {value && (
                  <Suggestions>
                    {suggestions.map((item, key) => (
                      <SuggestionsItem key={key} dangerouslySetInnerHTML={{ __html: item }} />
                    ))}
                  </Suggestions>
                )}
              </Content>
            </Grid>
            <Grid>
              <Trending isActive={isOpened}>
                <TrendingHeader>Trending Searches</TrendingHeader>
                <TrendingList>
                  {search.trending.map((item, key) => (
                    <TrendingItem key={key}>
                      <TrendingLink to={item}>
                        {item}
                      </TrendingLink>
                    </TrendingItem>
                  ))}
                </TrendingList>
              </Trending>
            </Grid>
          </Wrapper>
        </Box>
      </Inner>
    </Container>
  );
}