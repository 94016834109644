import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Slider from "react-slick";
import { ThemeProvider } from '#helpers';
import { screenSize, bp, xz, color, SvgSliderArrow, defTransition } from '#theme';

import { KeyVisual } from '../KeyVisual/KeyVisual';
import { slickStyles } from './slickStyles';
import { Dots, ButtonDefault } from '#ui';
import { rgba } from 'polished';

export const carouselComponentTypes = {
  keyvisual: "keyvisual",
};
export const carouselComponent = {
  [carouselComponentTypes.keyvisual]: KeyVisual,
};

export const carouselLayoutTypes = {
  default: "default",
  double: "double",
};

const Container = styled.div`
  position: relative;
  overflow: hidden;
  ${slickStyles}
`;
const StyledSlider = styled(Slider)`
  @media ${bp.p} {
    margin: 0 -6px;
  }
  @media ${bp.t} {
    margin: 0 -12px;
  }
`;
const Slide = styled.div`
  @media ${bp.p} {
    padding: 0 6px;
  }
  @media ${bp.t} {
    padding: 0 12px;
  }
`;
const Nav = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  padding-bottom: ${xz(5)};
  padding-left: ${xz(6)};
  @media ${bp.p} {
    display: none;
  }
`;
const Btn = styled(ButtonDefault)`
  width: 32px;
  height: 64px;
  background-color: ${rgba(color.white, 0.75)};
  display: none;
  position: absolute;
  margin: auto 0;
  top: 0;
  bottom: 0;
  transition: background-color ${defTransition};
  ${({ isNext }) => isNext
    ? `right: 0;` 
    : `left: 0;`
  }
  @media ${bp.p} {
    display: flex;
  }
  &:hover {
    background-color: ${color.white};
  }
`;
const ArrowPrev = styled(SvgSliderArrow)`
  width: 32px;
  height: 32px;
  transform: rotate(180deg);
  fill: ${color.red};
`;
const ArrowNext = styled(ArrowPrev)`
  transform: rotate(0deg);
`;


export const Carousel = ({ list, layout }) => {
  const [index, setIndex] = useState(0);
  const slider = useRef(null);
  const slidesToShow = layout === carouselLayoutTypes.double ? 2 : 1;
  const handleIndex = (nextIndex) => {
    slider.current.slickGoTo(nextIndex);
  };
  const handleNext = () => {
    slider.current.slickNext();
  };
  const handlePrev = () => {
    slider.current.slickPrev();
  };
  return (
    <Container>
      <StyledSlider
        dots={false}
        speed={500}
        slidesToShow={slidesToShow}
        arrows={false}
        draggable={false}
        ref={slider}
        beforeChange={(_, next) => setIndex(next)}
        responsive={[
          {
            breakpoint: screenSize.p,
            settings: {
              slidesToShow: 1,
            }
          },
        ]}
      >
        {list.map(({ type, props}, key) => {
          const SlideComponent = carouselComponent[type];
          const { theme, ...restProps } = props;
          return (
            <Slide key={key}>
              <ThemeProvider theme={theme}>
                <SlideComponent {...restProps} />
              </ThemeProvider>
            </Slide>
          );
        })}
      </StyledSlider>
      <Nav>
        <Dots
          length={list.length}
          index={index}
          handleIndex={handleIndex}
        />
      </Nav>
      <React.Fragment>
        <Btn onClick={() => handlePrev()}>
          <ArrowPrev />
        </Btn>
        <Btn onClick={() => handleNext()} isNext>
          <ArrowNext />
        </Btn>
      </React.Fragment>
    </Container>
  );
};