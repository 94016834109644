import React from 'react';
import theme from 'styled-theming';
import styled, { css } from 'styled-components/macro';

import { H2 } from '../H2/H2';
import { xz } from '#theme';
import { themeKeys } from '#helpers';

export const headlineLayoutType = {
  default: "default",
  compact: "compact",
};

const StyledHeadline = styled(H2)`
  text-align: center;
  &, &:first-child, &:last-child {
    margin: 0 0 ${xz(4)};
  }
  ${theme(themeKeys.layout, {
    [headlineLayoutType.compact]: css`
      &, &:first-child, &:last-child {
        margin: ${xz(2)} 0;
      }
    `,
  })}
  
`;

export const Headline = ({
  children,
  as,
}) => {
  return (
    <StyledHeadline as={as}>
      {children}
    </StyledHeadline>
  );
};