import React from 'react';
import styled from 'styled-components/macro';
import { color, clearBtn, defTransition } from '#theme';
import { Icon } from '../Icon/Icon';

const Button = styled.button`
  ${clearBtn}
  transition: fill ${defTransition};
  fill: ${({ isActive }) => isActive ? color.red : color.middleGrey};
  &:hover {
    fill: ${color.red};
  }
`;

export const ButtonIcon = ({ type, ...props }) => {
  return (
    <Button {...props}>
      <Icon type={type} />
    </Button>
  );
};