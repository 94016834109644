import React, { useRef } from 'react';
import useComponentSize from '@rehooks/component-size';
import theme from 'styled-theming';
import styled, { css, ThemeProvider } from 'styled-components/macro';
import { color, xz } from '#theme';
import { Wrapper, Scrollbars } from '#ui';
import { contextLevelTypes, themeKeys, contextThemeTypes } from '#helpers';

const Box = styled.div`
  display: flex;
`;

const Container = styled.div`
  margin: 0 ${xz(-1)} 10px;
`;

const TableTag = styled.table`
  border-spacing: ${xz(1)} 0;
  table-layout: fixed;
  width: 100%;
`;

const Td = styled.td`
  text-align: left;
  padding: ${xz(1.5)} ${xz(2)};
  width: 200px;
  border-bottom: 1px solid ${color.red};
  thead tr:first-child & {
    border-top: 1px solid ${color.red};
  }
  thead tr:last-child & {
    border-bottom: 0;
  }
  tbody tr:first-child & {
    border-top: 1px solid ${color.red};
  }
  ${({ isHidden }) => isHidden && css`
    visibility: hidden;
    thead tr:first-child & {
      border-top: 0;
    }
  `}
  ${({ removeBorder }) => removeBorder && css`
    border-bottom: 0;
  `}
  ${theme(themeKeys.bg, {
    [contextThemeTypes.red]: css`
      background-color: ${color.red};
    `,
    [contextThemeTypes.lightestGrey]: css`
      background-color: ${color.lightestGrey};
    `
  })}
`;

const TdInner = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
`;

export const Table = ({ data }) => {
  const boxRef = useRef(null);
  const size = useComponentSize(boxRef);
  const { thead, tbody } = data;
  const renderCell = (cell, key) => {
    const { component, cellProps = {} } = cell;
    if (!component) {
      return (
        <Td key={key} isHidden {...cellProps} />
      );
    }
    const { theme, ...otherCellProps } = cellProps;
    return (
      <ThemeProvider
        key={key}
        theme={{
          ...theme,
          level: contextLevelTypes.inside,
      }}>
        <Td
          key={key}
          {...otherCellProps}
        >
          <TdInner {...cell.innerProps}>
            {!cell.component
              ? cell.children
              : (
                <cell.component
                  {...cell.props}
                >
                  {cell.children}
                </cell.component>
              )
            }
          </TdInner>
        </Td>
      </ThemeProvider>
    );
  };
  return (
    <Scrollbars style={{height: size.height}}>
      <Box ref={boxRef}>
        <Wrapper noGrid>
          <Container>
            <TableTag>
              {thead && (
                <thead>
                  {thead.map((tr, key) => (
                    <tr key={key}>
                      {tr.map(renderCell)}
                    </tr>
                  ))}
                </thead>
              )}
              {tbody && (
                <tbody>
                  {tbody.map((tr, key) => (
                    <tr key={key}>
                      {tr.map(renderCell)}
                    </tr>
                  ))}
                </tbody>
              )}
            </TableTag>
          </Container>
        </Wrapper>
      </Box>
    </Scrollbars>
  );
};