import React, { useMemo } from 'react';
import { Table, Image, Link, linkTypes } from '#components';
import { images } from '#data';
import { H1, Text } from '#ui';
import { contextThemeTypes } from '#helpers';

export const TableExample5 = () => {
  const data = useMemo(
    () => ({
      thead: [
        [
          {},
          {
            component: Image,
            innerProps: {
              style: {
                height: 268,
              },
            },
            props: {
              src: images.tableIphone1,
            }
          },
          {
            component: Image,
            innerProps: {
              style: {
                height: 268,
              },
            },
            props: {
              src: images.tableIphone2,
            }
          },
          {
            component: Image,
            innerProps: {
              style: {
                height: 268,
              },
            },
            props: {
              src: images.tableIphone3,
            }
          },
        ],
        [
          {},
          {
            component: Text,
            children: 'Space Grau',
            cellProps: {
              as: "th",
              theme: {
                bg: contextThemeTypes.red,
              },
            },
          },
          {
            component: Text,
            children: 'Silber',
            cellProps: {
              as: "th",
              theme: {
                bg: contextThemeTypes.red,
              },
            },
          },
          {
            component: Text,
            children: 'Gold',
            cellProps: {
              as: "th",
              theme: {
                bg: contextThemeTypes.red,
              },
            },
          },
        ],
        [
          {},
          {
            component: Text,
            props: {
              className: "textGrey",
            },
            children: 'Single Camera',
            cellProps: {
              as: "th",
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
          },
          {
            component: Text,
            props: {
              className: "textGrey",
            },
            children: 'Dual Camera',
            cellProps: {
              as: "th",
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
          },
          {
            component: Text,
            props: {
              className: "textGrey",
            },
            children: 'Dual Camera',
            cellProps: {
              as: "th",
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
          },
        ],
        [
          {},
          {
            component: H1,
            children: "8 MB",
          },
          {
            component: H1,
            children: "8 MB",
          },
          {
            component: H1,
            children: "12 MB",
          },
        ],
      ],
      tbody: [
        [
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
              as: "th",
            },
            innerProps: {
              style: {
                alignItems: 'flex-end',
                textAlign: 'right',
              }
            },
            props: {
              className: "textGrey",
            },
            children: "64 GB",
          },
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
            props: {
              className: "textGrey",
            },
            children: "301737",
          },
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
            props: {
              className: "textGrey",
            },
            children: "301738",
          },
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
            props: {
              className: "textGrey",
            },
            children: "301739",
          },
        ],
        [
          {
            component: Text,
            cellProps: {
              as: "th",
            },
            innerProps: {
              style: {
                alignItems: 'flex-end',
                textAlign: 'right',
              }
            },
            props: {
              className: "textGrey",
            },
            children: "256 GB",
          },
          {
            component: Text,
            props: {
              className: "textGrey",
            },
            children: "301737",
          },
          {
            component: Text,
            props: {
              className: "textGrey",
            },
            children: "301738",
          },
          {
            component: Text,
            props: {
              className: "textGrey",
            },
            children: "301739",
          },
        ],
        [
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
              as: "th",
            },
            innerProps: {
              style: {
                alignItems: 'flex-end',
                textAlign: 'right',
              }
            },
            props: {
              className: "textGrey",
            },
            children: "512 GB",
          },
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
            props: {
              className: "textGrey",
            },
            children: "301737",
          },
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
            props: {
              className: "textGrey",
            },
            children: "301738",
          },
          {
            component: Text,
            cellProps: {
              theme: {
                bg: contextThemeTypes.lightestGrey,
              },
            },
            props: {
              className: "textGrey",
            },
            children: "301739",
          },
        ],
        [
          {},
          {
            component: Link,
            props: {
              type: linkTypes.pdf,
              label: "Preisliste 2019",
              href: "#link-to-pdf",
            }
          },
          {
            component: Link,
            props: {
              type: linkTypes.external,
              label: "Visit website",
              href: "/link-to-pdf",
            }
          },
          {
            component: Link,
            props: {
              type: linkTypes.pdf,
              label: "Preisliste 2019",
              href: "#link-to-pdf",
            }
          },
        ],
        
      ]
    }),
    []
  );
  return (
    <Table data={data} />
  );
};