import React from 'react';
import styled, { ThemeProvider, css } from 'styled-components/macro';
import theme from 'styled-theming';

import { bp, color, xz } from '#theme';
import { contextThemeTypes, themeKeys } from '#helpers';
import { Wrapper, Grid, Headline, headlineLayoutType, Meta } from '#ui';

const Container = styled(Grid)`
  display: grid;
  padding: ${xz(3)};
  box-shadow: 0 0 0 2px ${color.red} inset;
  text-align: center;
  line-height: 1.16666667;
  @media ${bp.p} {
    padding-left: 0;
    padding-right: 0;
  }
  ${theme('bg', {
    [contextThemeTypes.red]: css`
      background-color: ${color.red};
      color: ${color.white};
    `,
  })}
`;
const Content = styled.div`
  grid-column-start: 1;
  grid-column-end: span 4;
  @media ${bp.p} {
    grid-column-start: 2;
    grid-column-end: span 10;
  }
`;
const MetaData = styled.div`
  text-align: center;
`;

export const AlertBanner = ({
  category,
  date,
  ownTheme = contextThemeTypes.default,
  children,
  href,
}) => {
  const container = (
    <Container>
      <Content>
        {(date || category) && (
          <MetaData>
            <Meta
              date={date}
              category={category}
            />
          </MetaData>
        )}
        <ThemeProvider theme={{
          [themeKeys.layout]: headlineLayoutType.compact,
        }}>
          <Headline>{children}</Headline>
        </ThemeProvider>
      </Content>
    </Container>
  );
  return (
    <ThemeProvider theme={{
      bg: ownTheme,
    }}>
      <Wrapper noGrid>
        {href ? (
          <a href={href}>
            {container}
          </a>
        ) : container}
      </Wrapper>
    </ThemeProvider>
  );
};