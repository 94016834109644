import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import theme from 'styled-theming';
import styled, { css, ThemeProvider, ThemeContext } from 'styled-components/macro';

import { Button, ButtonPropTypes } from '../Button/Button';
import { Rte } from '../Rte/Rte';
import { Wrapper, Badge, H1, Meta, Grid, Player } from '#ui';
import { overlay, clearListItem, clearList, bp, gradient, xz, expander, maxWidth, screenSize, color, easeInOutCubic } from '#theme';
import { contextThemeTypes, contextLevelTypes } from '#helpers';
import { rgba } from 'polished';

export const keyVisualPositionTypes = {
  center: "center",
  topLeft: "topLeft",
  bottomLeft: "bottomLeft",
  bottomLeftDoubleSlider: "bottomLeftDoubleSlider",
};

const Container = styled.div`
  position: relative;
  min-height: 480px;
  display: flex;
  @media ${bp.t} {
    min-height: 0;
    height: 556px;
  }
  @media ${bp.d} {
    height: 684px;
  }
  @media ${bp.h} {
    height: 0;
    padding-bottom: 36%;
    ${theme('position', {
      [keyVisualPositionTypes.bottomLeftDoubleSlider]: css`
        padding-bottom: 72%;
      `,
    })}
  }
`;
const Visual = styled.div`
  ${expander}
  overflow: hidden;
  z-index: -1;
  &::before {
    content: "";
    ${expander}
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${({ image }) => image});
    background-size: cover;
    transition: transform .5s ${easeInOutCubic};
    ${({ hasHover }) => hasHover && css`
      ${Container}:hover & {
        transform: scale(1.1);
        @media (hover: none) {
          transform: none;
        }
      }
    `}
  }
  ${({ isVideo }) => overlay(`
    z-index: 2;
    background-image: ${gradient.blackTransparentHalf};
    background-color: ${rgba(color.black, isVideo ? 0.5 : 0.1)};
  `)}
`;
const Inner = styled.div`
  padding-top: ${xz(6)};
  padding-bottom: ${xz(6)};
  flex: 1 0 0%;
  display: flex;
  flex-direction: column;
  @media ${bp.m} {
    padding-top: ${xz(8)};
    padding-bottom: ${xz(8)};
  }
  @media ${bp.t} {
    padding-top: ${xz(10)};
    padding-bottom: ${xz(10)};
  }
  @media ${bp.h} {
    ${expander}
  }
  ${theme('position', {
    [keyVisualPositionTypes.center]: css`
      text-align: center;
      align-content: flex-end;
      justify-content: flex-end;
    `,
    [keyVisualPositionTypes.topLeft]: css`
      text-align: left;
      align-content: flex-start;
      justify-content: flex-start;
    `,
    [keyVisualPositionTypes.bottomLeft]: css`
      text-align: left;
      align-content: flex-end;
      justify-content: flex-end;
    `,
    [keyVisualPositionTypes.bottomLeftDoubleSlider]: css`
      text-align: left;
      align-content: flex-end;
      justify-content: flex-end;
      padding-left: ${xz(6)};
      padding-right: ${xz(6)};
      @media ${maxWidth(screenSize.p)} {
        padding-top: ${xz(8)};
        padding-bottom: ${xz(10)};
      }
    `,
  })}
`;
const Box = styled.div`
  
`;
const Attribute = styled.div`
  position: relative;
  z-index: 1;
  flex: 1 0 80px;
  padding: ${xz(3)} ${xz(3)} ${xz(2)};
  display: flex;
  justify-content: flex-end;
  margin-top: ${xz(-6)};
  @media ${bp.m} {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0;
  }
  @media ${bp.p} {
    padding-top: ${xz(4)};
    padding-right: ${xz(4)};
  }
  @media ${bp.t} {
    padding-top: ${xz(6)};
    padding-right: ${xz(6)};
  }
  @media ${bp.d} {
    padding-top: ${xz(8)};
    padding-right: ${xz(8)};
  }
  ${theme('position', {
    [keyVisualPositionTypes.topLeft]: css`
      flex: none;
    `,
  })}
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  grid-column-start: 1;
  grid-column-end: span 4;
  ${theme('position', {
    [keyVisualPositionTypes.center]: css`
      @media ${bp.m} {
        padding-left: ${xz(14)};
        padding-right: ${xz(14)};
      }
      @media ${bp.p} {
        padding-left: 0;
        padding-right: 0;
        grid-column-start: 3;
        grid-column-end: span 8;
      }
    `,
    [keyVisualPositionTypes.topLeft]: css`
      @media ${bp.m} {
        grid-column-end: span 3;
      }
      @media ${bp.p} {
        grid-column-start: 2;
        grid-column-end: span 7;
      }
    `,
    [keyVisualPositionTypes.bottomLeft]: css`
      @media ${bp.m} {
        grid-column-end: span 3;
      }
      @media ${bp.p} {
        grid-column-start: 2;
        grid-column-end: span 7;
      }
    `,
  })}
`;

const Headline = styled(H1)`
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.1;
  margin-top: ${xz(1)};
  margin-bottom: ${xz(2)};
  @media ${bp.m} {
    font-size: 2.625rem;
  }
  @media ${bp.p} {
    font-size: 3.25rem;
  }
  @media ${bp.t} {
    font-size: 3.875rem;
  }
  ${theme('position', {
    [keyVisualPositionTypes.bottomLeftDoubleSlider]: css`
      @media ${bp.p} {
        font-size: 2.625rem;
      }
      @media ${bp.t} {
        font-size: 3rem;
      }
      @media ${bp.d} {
        font-size: 3.25rem;
      }
    `
  })}
`;

const Buttons = styled.div`
  ${clearList}
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${xz(3)};
  @media ${bp.m} {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  @media ${bp.p} {
    margin-top: ${xz(4)};
  }
  @media ${bp.t} {
    margin-top: ${xz(6)};
  }
  ${theme('position', {
    [keyVisualPositionTypes.center]: css`
      align-items: center;
      @media ${bp.m} {
        justify-content: center;
      }
    `,
    [keyVisualPositionTypes.topLeft]: css`
      align-items: flex-start;
      @media ${bp.m} {
        justify-content: flex-start;
      }
    `,
    [keyVisualPositionTypes.bottomLeft]: css`
      align-items: flex-start;
      @media ${bp.m} {
        justify-content: flex-start;
      }
    `,
    [keyVisualPositionTypes.bottomLeftDoubleSlider]: css`
      align-items: flex-start;
      @media ${bp.m} {
        justify-content: flex-start;
      }
    `,
  })}
`;

const ButtonsItem = styled.div`
  ${clearListItem}
  & + & {
    margin-top: ${xz(2)};
  }
  @media ${bp.m} {
    & + & {
      margin-top: 0;
      margin-left: ${xz(3)};
    }
  }
`;

export const KeyVisual = ({
  image,
  video,
  badge,
  link,
  badgeTheme = contextThemeTypes.red,
  category,
  date,
  headline,
  copytext,
  buttons,
}) => {
  const { position } = useContext(ThemeContext);
  const renderButtons = (btn, key) => (
    <ButtonsItem key={key}>
      <Button isLink={btn.to} {...btn} />
    </ButtonsItem>
  );
  const content = (
    <Content>
      <Meta
        date={date}
        category={category}
      />
      <Headline>{headline}</Headline>
      {copytext && (<Rte level={contextLevelTypes.inside}>{copytext}</Rte>)}
      {buttons && (
        <Buttons>
          {buttons.map(renderButtons)}
        </Buttons>
      )}
    </Content>
  );
  const container = (
    <Container>
      <Visual image={image} isVideo={Boolean(video)} hasHover={Boolean(link)}>
        {video && (
          <Player src={video} poster={image} autoPlay loop />
        )}
      </Visual>
      <Inner>
        {badge && (
          <ThemeProvider theme={{
            badgeTheme,
          }}>
            <Attribute>
              <Badge>{badge}</Badge>
            </Attribute>
          </ThemeProvider>
        )}
        <Box>
          {position === keyVisualPositionTypes.bottomLeftDoubleSlider
            ? content
            : (
              <Wrapper noGrid>
                <Grid>
                  {content}
                </Grid>
              </Wrapper>
            )
          }
        </Box>
      </Inner>
    </Container>
  );
  return link
    ? (
      <Link to={link}>
        {container}
      </Link>
    )
    : container;
};

KeyVisual.propTypes = {
  image: PropTypes.string,
  category: PropTypes.string,
  date: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  copytext: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.shape(ButtonPropTypes)),
};