import React, { useState } from 'react';
import styled, { css } from "styled-components/macro";
import { color, xz, expander, bp, defTransition } from '#theme';
import { Button, buttonThemeTypes } from '../../Button/Button';


const Container = styled.div`
  padding: ${xz(1)} ${xz(3)} ${xz(4)};
  display: flex;
  flex-direction: column;
  height: 100%;
  @media ${bp.m} {
    padding-left: ${xz(6)};
    padding-right: ${xz(6)};
  }
`;
const Headline = styled.h4`
  margin: 0 0 ${xz(3)};
  font-size: 1.125rem;
  line-height: 1.22;
  color: ${color.red};
  text-align: center;
`;
const Body = styled.div`
  flex: 1 0 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const List = styled.ul`
  padding: 0;
  margin: 0;
`;
const ListItem = styled.li`
  padding: 12px 0 8px;
  display: flex;
  align-items: flex-start;
  position: relative;
  font-size: 0.875rem;
  line-height: 1.3;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: ${color.white};
  }
  &::after {
    transition: width ${defTransition};
    width: ${({ value }) => value || 0}%;
    background-color: ${color.red};
  }
`;
const RadioContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-top: 2px;
  position: relative;
  border-radius: 50%;
  border: 2px solid ${color.lightGrey};
  cursor: pointer;
`;
const RadioInput = styled.input`
  ${expander}
  opacity: 0;
  z-index: 1;
  cursor: pointer;
`;
const RadioLabel = styled.span`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${color.red};
  opacity: 0;
  ${RadioInput}:checked + & {
    opacity: 1;
  }
`;
const Label = styled.label`
  flex: 1 0 0%;
  padding-left: 8px;
  cursor: pointer;
`;
const Value = styled.span`
  color: ${color.red};
  ${({ isChecked }) => isChecked && css`
    font-weight: bold;
  `}
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${xz(4)};
`;

const Radio = ({ checked, id, name, handleChange, disabled }) => (
  <RadioContainer>
    <RadioInput
      type="radio"
      defaultChecked={checked}
      id={id}
      name={name}
      onChange={handleChange}
      disabled={disabled}
    />
    <RadioLabel />
  </RadioContainer>
);

export const WidgetPoll = ({
  headline,
  list,
  name,
  isAnswered,
}) => {
  const [visibleResults, setVisibleResults] = useState(isAnswered);
  const [checkedId, setCheckedId] = useState(-1);
  const renderList = ({ checked, label, value, id}, key) => {
    const handleChange = () => setCheckedId(id);
    return (
      <ListItem key={key} value={visibleResults && value}>
        <Radio
          checked={checked}
          id={name+id}
          name={name}
          disabled={visibleResults}
          handleChange={handleChange}
        />
        <Label htmlFor={name+id}>{label}</Label>
        {visibleResults && (
          <Value isChecked={checked || checkedId === id}>{value}%</Value>
        )}
    </ListItem>
    );
  };
  const handleClick = () => {
    setVisibleResults(true);
  };
  const isButtonDisabled = checkedId < 0 || visibleResults;
  return (
    <Container>
      <Headline>{headline}</Headline>
      <Body>
        <List>
          {list.map(renderList)}
        </List>
      </Body>
      <Footer>
        <Button
          onClick={handleClick}
          disabled={isButtonDisabled}
          theme={isButtonDisabled ? buttonThemeTypes.lightGrey : buttonThemeTypes.red}>Abstimmen</Button>
      </Footer>
    </Container>
  )
};