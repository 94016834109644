import styled from 'styled-components/macro';
import { color } from '#theme';
import { rgba } from 'polished';

export const Dropdown = styled.div`
  background-color: ${color.white};
  border-radius: 4px;
  overflow: hidden;
  padding-bottom: 8px;
  box-shadow: 0px 4px 8px ${rgba(color.black, 0.25)};
`;