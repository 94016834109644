import React from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';

import { Grid, Wrapper } from '#ui';
import { bp } from '#theme';
import { Teaser } from '../Teaser/Teaser';
import { ThemeProvider, themeKeys, contextThemeTypes } from '#helpers';
import { teaserLayoutTypes } from '../Teaser/teaser.types';

const Item = styled.div`
  
  ${theme(themeKeys.layout, {
    [teaserLayoutTypes.three]: css`
      grid-column-end: 4 span;
      @media ${bp.m} {
        grid-column-end: 2 span;
        &:last-child {
          grid-column-start: 2;
        }
      }
      @media ${bp.p} {
        grid-column-end: 4 span;
        &:last-child {
          grid-column-start: auto;
        }
      }
    `,
    [teaserLayoutTypes.four]: css`
      grid-column-end: 2 span;
      @media ${bp.p} {
        grid-column-end: 3 span;
      }
    `,
  })}
`;
const Container = styled(Wrapper)`
`;

export const TeaserList = ({
  list,  
}) => {

  const layout = list.length === 3
    ? teaserLayoutTypes.three
    : teaserLayoutTypes.four;
  return (
    <ThemeProvider theme={{
      [themeKeys.layout]: layout,
    }}>
      <Container>
        <Grid>
          {list.map((item, key) => {
            const { theme = { bg: contextThemeTypes.default } } = item;
            return (
              <Item key={key}>
                <ThemeProvider theme={theme}>
                  <Teaser {...item} />
                </ThemeProvider>
              </Item>
            );
          })}
        </Grid>
      </Container>
    </ThemeProvider>
  )
};