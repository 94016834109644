import React from 'react';
import { ThemeProvider } from 'styled-components/macro';

import { Section, Grid } from '#ui';

export const sectionWrapper = ({
  list,
  isInside,
  homeGrid,
}) => {
  const content = list.map((component, key) => {
    const {
      noPadding,
      theme = {},
    } = component.props;
    return (
      <ThemeProvider
        theme={theme}
        key={key}
      >
        {homeGrid
          ? component
          : (
            <Section
              noPadding={noPadding}
              isInside={isInside}
            >
              {component}
            </Section>
          )
        }
      </ThemeProvider>
    );
  });
  return homeGrid
    ? (
      <Grid flow="row" columns={1}>
        {content}
      </Grid>
    ) : content;
};