import React from 'react';
import { Page, iconTypes } from '#ui';
import { sectionWrapper } from '#hocs';
import {
  KeyVisual,
  keyVisualPositionTypes,
  buttonThemeTypes,
  AlertBanner,
  TeaserList,
  teaserVisualTypes,
  Carousel,
  carouselLayoutTypes,
  carouselComponentTypes,
  TeaserListCombo,
} from '#components';
import { contextThemeTypes } from '#helpers';
import { timeline1, vfTestimage03 } from '#data/images';
import { images, video } from '#data';
import { WidgetsSection } from '#components/WidgetsSection/WidgetsSection';
import { widgetTypes } from '#components/widget/Widget/Widget';

const teaserListItem = {
  theme: {
    bg: contextThemeTypes.default,
  },
  date: '12.09.2019',
  category: 'Category',
  headline: {
    text: 'Headline',
  },
  visual: {
    type: teaserVisualTypes.image,
    src: timeline1,
  },
  href: "#",
};
const teaserListThree = [];
for (let i = 0; i < 3; i++) teaserListThree.push(teaserListItem);
const teaserListFive = [];
for (let i = 0; i < 5; i++) teaserListFive.push(teaserListItem);

const teaserListBgImageItem = {
  theme: {
    bg: contextThemeTypes.image,
  },
  date: '12.09.2019',
  category: 'Category',
  headline: {
    text: 'Headline',
  },
  visual: {
    type: teaserVisualTypes.image,
    src: timeline1,
  },
  href: "#",
};
const teaserListBgImageThree = [];
for (let i = 0; i < 3; i++) teaserListBgImageThree.push(teaserListBgImageItem);

const carouselListItem = {
  type: carouselComponentTypes.keyvisual,
  props: {
    noWrapper: true,
    image: images.timeline1,
    badge: "NEU",
    badgeTheme: contextThemeTypes.red,
    date: '12.09.2019',
    category: 'Category',
    headline: "Headline",
    theme: {
      bg: contextThemeTypes.image,
      position: keyVisualPositionTypes.bottomLeftDoubleSlider,
    }
  },
};
const carouselList = [];
for (let i = 0; i < 5; i++) carouselList.push(carouselListItem);

const widgetList = [
  {
    title: "Event",
    theme: {
      bg: contextThemeTypes.black,
    },
    list: [
      {
        type: widgetTypes.event,
        props: {
          type: iconTypes.webinar,
          typeLabel: "eLearning",
          places: {
            available: 9,
            total: 18,
          },
          headline: "Basiswissen Verkauf – Grundausbildung SOHO Außendienstmitarbeiter",
          copytext: `<p>Dieses Training dient als Vorbereitung auf das 3. Modul des Verkaufstrainings und soll Ihnen dabei helfen, Ihre tägliche Arbeit als SOHO-Außendienstmitarbeiter besser, einfacher und erfolgreicher erledigen zu können.</p><p>Es bietet Ihnen in Form von 8 Kollektionen eine Mischung aus „verkaufsspezifischem“ Wissen, das Ihnen besonders in der eigentlichen Verkaufssituation nützen wird, und aus Wissen, das Ihnen hilft, Ihren Berufsalltag auch außerhalb des Verkaufsgesprächs besser bewältigen zu können.</p>`,
          startDate: "2020-01-23T18:00:00",
          endDate: "2020-03-23T18:00:00",
          image: {
            src: images.widgetEvent1,
          },
        }
      },
      {
        type: widgetTypes.event,
        props: {
          type: iconTypes.workshop,
          typeLabel: "Seminar",
          headline: "Alles wird Festnetz",
          copytext: `<p>Mit der Produktwelt Vodafone Festnetz hat Vodafone absolute Top Angebote auf dem Markt und gibt Dir so die Möglichkeit ein konvergentes Kundenerlebnis zu schaffen.</p><p>Wie Du diese Vermarktungspotentiale für Dich und Deine Kollegen optimal nutzt und neue Erlebniswelten für und mit Deinen Kunden schaffst, erarbeiten wir gemeinsam in diesem Workshop.</p><p>Natürlich sind Networking und der Austausch von Best Practice in der Teilnehmergruppe ebenfalls Teil der Workshop Agenda.</p><p>Wir unterstützen Dich, die Premium Produkte Kabel, DSL und TV zukünftig, erfolgreich und mit Freude zu vermarkten.</p>`,
          startDate: "2020-01-23T18:00:00",
          endDate: "2020-03-18T18:00:00",
        }
      },
      {
        type: widgetTypes.event,
        props: {
          type: iconTypes.livestream,
          typeLabel: "eLearning",
          places: {
            available: 9,
            total: 18,
          },
          headline: "Reklamation als Chance",
          copytext: `<p>Im Training gewinnen Sie ein tieferes Verständnis für Reklamationen, um für den Kunden und Sie einen Erfolg daraus zu machen. Sie lernen Reklamationen vorzubeugen und so maximale Kundenzufriedenheit zu erzielen.</p><ul><li>Sieben Punkte  für ein erfolgreiches Reklamationsmanagement</li><li>Umgang mit schwierigen Kunden</li><li>Reklamationsbehandlung – Kommunikation und Wirkung</li><li>Reklamationsbehandlung – Bearbeitung in den Vodafone Systemen</li><li>Erfolgreiches Reklamationsmanagement</li></ul>`,
          startDate: "2020-01-23T18:00:00",
          endDate: "2020-03-01T18:00:00",
        }
      },
    ],
  },
  {
    title: "Frage",
    theme: {
      bg: contextThemeTypes.lightestGrey,
    },
    list: [
      {
        type: widgetTypes.poll,
        props: {
          headline: "Welches Gerät würdest Du Deinen Kunden uneingeschränkt empfehlen?",
          name: "poll1",
          list: [
            {
              label: "Huawei Matte",
              id: "option1",
              value: "15",
            },
            {
              label: "LG G7",
              id: "option2",
              value: "25",
            },
            {
              label: "Samsung Galaxy S9",
              id: "option3",
              value: "10",
            },
            {
              label: "Huawei Matte",
              id: "option4",
              value: "50",
            },
            {
              label: "LG G7",
              id: "option5",
              value: "10",
            },
            {
              label: "Samsung Galaxy S9",
              id: "option6",
              value: "23",
            },
            {
              label: "Apple iPhone Xs Space Grey 256GB, Dual Camera, A12 Bionic Prozessor",
              id: "option7",
              value: "15",
            },
          ]
        }
      },
      {
        type: widgetTypes.poll,
        props: {
          headline: "Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit Sed Do Eiusmod?",
          name: "poll2",
          list: [
            {
              label: "Huawei Matte",
              id: "option1",
              value: "75",
            },
            {
              label: "LG G7",
              id: "option2",
              value: "25",
            },
          ]
        }
      },
    ],
  },
  {
    title: "Mein shop",
    theme: {
      bg: contextThemeTypes.black,
    },
    list: [
      {
        type: widgetTypes.score,
        props: {
          
        }
      },
    ],
  }
];

export const HomeComponents = () => {
  return (
    <React.Fragment>
      <Page>
        {sectionWrapper({ list: [
          <KeyVisual
            image={vfTestimage03}
            video={video.watch}
            badge="NEU"
            badgeTheme={contextThemeTypes.red}
            category="Category"
            date="12.08.2019"
            headline="Headline"
            copytext="Es ist soweit: Mit dem Zusammenschluss von Unitymedia und Vodafone haben wir den Schritt zur GigabitCompany gemacht."
            theme={{
              bg: contextThemeTypes.image,
              position: keyVisualPositionTypes.center
            }}
            buttons={[
              {
                children: "Download PDF",
                theme: buttonThemeTypes.red,
              },
              {
                children: "All Informations",
                theme: buttonThemeTypes.ghost,
              }
            ]}
          />,
          <Carousel
            list={carouselList}
            layout={carouselLayoutTypes.double}
          />
        ], homeGrid: true })}
        {sectionWrapper({ list: [
          <AlertBanner
            category="Category"
            date="12.09.2019"
          >
            Positive Beratungseinwilligung: Voraussetzung für die erfolgreiche Kundenbindung
          </AlertBanner>,
          <TeaserListCombo
            list={teaserListFive}
          />,
          <TeaserList
            list={teaserListThree}
          />,
          <TeaserList
            list={teaserListBgImageThree}
          />,
        ] })}
      </Page>
      <WidgetsSection list={widgetList} />
    </React.Fragment>
  );
};

