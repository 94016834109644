import React from 'react';
import styled from 'styled-components/macro';
import { Parallax } from 'react-parallax';
import widgetBackground from './widget-background.jpg';
import { xz, bp, wrapperProps } from '#theme';
import { Widget } from '../widget/Widget/Widget';

export const Container = styled.div`
  background-image: url(${widgetBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const SectionWrapper = styled.div`
  ${wrapperProps.paddings}
  overflow: hidden;
  padding-top: ${xz(12)};
  padding-bottom: ${xz(24)};
`;
export const Inner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media ${bp.m} {
    margin: ${xz(-4)};
  }
  @media ${bp.p} {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    margin: ${xz(-3)};
  }
`;
const Item = styled.div`
  flex: 1 0 0%;
  width: 100%;
  min-width: 264px;
  max-width: 456px;
  margin: ${xz(4)} 0;
  @media ${bp.m} {
    margin: ${xz(4)};
  }
  @media ${bp.p} {
    margin: ${xz(3)};
    min-width: 400px;
  }
`;

export const WidgetsSection = ({ list }) => {
  const renderWidgets = (item, key) => {
    return (
      <Item key={key}>
        <Widget key={key} {...item} />
      </Item>
    );
  };
  return (
    <Container>
      <Parallax
        bgImage={widgetBackground}
        strength={500}
      >
        <SectionWrapper noGrid>
          <Inner>
            {list.map(renderWidgets)}
          </Inner>
        </SectionWrapper>
      </Parallax>
    </Container>
  );
}