import React from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';

import { UpdateStatus, ViewStatus, Grid, Wrapper, Text, H3, Meta, Select, ButtonIcon, iconTypes } from '#ui';
import { color, bp, defTransition, xz, maxWidth, screenSize } from '#theme';
import { rgba } from 'polished';
import { themeKeys, ThemeProvider, contextSizeTypes } from '#helpers';

const Container = styled.div`
  position: relative;
`;

// const Header = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// `;

const FiltersContainer = styled(Grid)`
  padding: ${xz(3)} 0;
  @media ${bp.p} {
    margin: 0 auto;
  }
`;
const Filter = styled.div`
  grid-column-end: 2 span;
  @media ${bp.p} {
    grid-column-end: 3 span;
    &:first-of-type {
      grid-column-start: 4;
    }
  }
`;

const List = styled.div`

`;
const Post = styled(Link)`
  display: block;
  background-color: ${color.white};
  text-decoration: none;
  padding: ${xz(1)};
  @media ${bp.m} {
    padding: ${xz(1.5)};
  }
  @media ${bp.p} {
    transition: box-shadow ${defTransition};
    &:hover {
      box-shadow: 0px 0px 24px ${rgba(color.textGrey, 0.25)};
    }
  }
  & + & {
    margin-top: ${xz(1)};
    @media ${bp.p} {
      margin-top: ${xz(2)};
    }
  }
`;

const Content = styled.div`
  display: flex;
`;

const Image = styled.div`
  position: relative;
  flex: 0 0 64px;
  @media ${bp.m} {
    flex: 0 0 104px;
  }
  @media ${bp.p} {
    flex: 0 0 120px;
  }
  @media ${bp.t} {
    flex: 0 0 132px;
  }
  @media ${bp.d} {
    flex: 0 0 156px;
  }
  ${theme(themeKeys.size, {
    [contextSizeTypes.m]: css`
      flex: 0 0 96px;
      @media ${bp.m} {
        flex: 0 0 164px;
      }
      @media ${bp.p} {
        flex: 0 0 200px;
      }
      @media ${bp.t} {
        flex: 0 0 224px;
      }
      @media ${bp.d} {
        flex: 0 0 256px;
      }
    `
  })}
`;
const Img = styled.div`
  height: 0;
  padding-bottom: ${2/3*100}%;
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
`;

const Entry = styled(Text)`
  margin-left: ${xz(1)};
  flex: 8 0 0%;
  @media ${bp.m} {
    margin-left: ${xz(2)};
  }
  @media ${bp.d} {
    margin-left: ${xz(3)};
  }
  ${theme(themeKeys.size, {
    [contextSizeTypes.m]: css`
      margin-left: ${xz(2)};
      @media ${bp.m} {
        margin-left: ${xz(3)};
      }
    `
  })}
`;

const StyledMeta = styled(Meta)`
  @media ${maxWidth(screenSize.m)} {
    font-size: 0.5625rem;
  }
`;

const TitleH3 = styled(H3)`
  margin: ${xz(1)} 0;
  @media ${maxWidth(screenSize.m)} {
    font-size: 0.8125rem;
  }
`;
const TitleCt = styled(Text)`
  line-height: 1.2;
  font-weight: bold;
  margin: ${xz(0.5)} 0 ${xz(1)};
  @media ${maxWidth(screenSize.m)} {
    font-size: 0.6875rem;  
  }
  @media ${bp.m} {
    margin-top: ${xz(1)};
  }
`;

const Status = styled.div`
  display: none;
  margin-left: ${xz(3)};
  @media ${bp.p} {
    display: flex;
    align-items: center;
    margin: 0 ${xz(4)};
  }
`;

const StatusUpdate = styled(Status)`

`;
const StatusBookmark = styled(Status)`

`;
const StatusView = styled(Status)`
  display: block;
`;
const StyledViewStatus = styled(ViewStatus)`
  width: 16px;
  height: 16px;
  @media ${bp.m} {
    width: 24px;
    height: 24px;
  }
  @media ${bp.p} {
    width: 32px;
    height: 32px;
  }
`;

const Heading = styled.div`
  position: relative;
  text-align: center;
  color: ${color.lightGrey};
  font-size: 0.75rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  /* height: 24px; */
  margin: ${xz(3)} 0;
  &::before,
  &::after {
    content: "";
    height: 2px;
    background-color: ${color.lightGrey};
    flex: 1 0 0%;
  }
  span {
    margin: 0 ${xz(2)};
  }
`;

// const Footer = styled.div`
//   display: flex;
//   justify-content: flex-end;
// `;

const Filters = ({ posts }) => {

  return (
    <FiltersContainer>
      <Filter>
        <Select list={posts.filters.theme} mediaQueryForNative={maxWidth(screenSize.m)} />
      </Filter>
      <Filter>
        <Select list={posts.filters.period} mediaQueryForNative={maxWidth(screenSize.m)} />
      </Filter>
    </FiltersContainer>
  );
};

export const Posts = ({
  list,
  theme = {
    [themeKeys.size]: contextSizeTypes.s,
  },
}) => {
  const Title = theme && theme[themeKeys.size] === contextSizeTypes.m ? TitleH3 : TitleCt;
  return (
    <List>
      {list.map((post, key) => (
        <ThemeProvider
          key={key}
          theme={theme}
        >
          <Post to={post.path}>
            <Content>
              {post.image && (
                <Image><Img src={post.image} /></Image>
              )}
              <Entry>
                <StyledMeta date={post.date} category={post.category} />
                <Title className="textGrey">{post.title}</Title>
              </Entry>
              <StatusUpdate>
                <UpdateStatus isUpdated={post.isUpdated} isCircle>
                  {"Updated"}
                </UpdateStatus>
              </StatusUpdate>
              <StatusBookmark>
                <ButtonIcon isActive={post.isBookmarked} type={iconTypes.bookmark} />
              </StatusBookmark>
              <StatusView>
                <StyledViewStatus isViewed={post.isViewed}/>
              </StatusView>
            </Content>
          </Post>
        </ThemeProvider>
      ))}
    </List>
  );
};
export const PostList = ({ posts, site }) => (
  <Wrapper noGrid>
    <Container>
      <Filters posts={posts} />
      <Posts list={posts.new} site={site} theme={{
        [themeKeys.size]: contextSizeTypes.m,
      }} />
      <Heading><span>Du bist auf dem neuesten Stand</span></Heading>
      <Posts list={posts.old} />
    </Container>
  </Wrapper>
);
