import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components/macro';

import { Rte } from '../Rte/Rte';
import { H1, Wrapper, Grid, H2, Meta, Badge } from '#ui';
import { bp, xz } from '#theme';
import { contextThemeTypes, contextLevelTypes } from '#helpers';

export const articleHeaderLayoutTypes = {
  A: "A",
  B: "B",
};

export const articleHeaderImageLayoutTypes = {
  default: "default",
  full: "full",
};

const Container = styled.div`
  position: relative;
`;

const Attribute = styled.div`
  padding: ${xz(3)} ${xz(3)} ${xz(2)};
  display: flex;
  justify-content: flex-end;
  @media ${bp.m} {
    position: absolute;
    z-index: 1;
    top: ${xz(3)};
    right: ${xz(3)};
    padding: 0;
  }
  @media ${bp.p} {
    top: ${xz(4)};
    right: ${xz(4)};
  }
  @media ${bp.t} {
    top: ${xz(6)};
    right: ${xz(6)};
  }
  @media ${bp.d} {
    top: ${xz(8)};
    right: ${xz(8)};
  }
`;

const Content = styled.div`
  padding-top: ${xz(6)};
  padding-bottom: ${xz(8)};
  grid-column-end: span 4;
  @media ${bp.m} {
    grid-column-start: 1;
    grid-column-end: span 3;
  }
  @media ${bp.p} {
    grid-column-end: span 7;
  }
  @media ${bp.d} {
    grid-column-end: span 8;
  }
`;
const MetaData = styled(Meta)`
  margin-bottom: ${xz(3)};
  @media ${bp.p} {
    margin-bottom: ${xz(4)};
  }
`;
const Headline = styled(H1)`
  font-size: 2rem;
  line-height: 1;
  margin-bottom: ${xz(4)};
  @media ${bp.m} {
    font-size: 2.625rem;
  }
  @media ${bp.p} {
    font-size: 3.25rem;
  }
  @media ${bp.t} {
    font-size: 3.875rem;
  }
`;

const Subheadline = styled(H2)`
  margin-bottom: ${xz(6)};
`;

export const ArticleHeaderFlat = ({
  badge,
  badgeTheme = contextThemeTypes.red,
  category,
  date,
  headline,
  subheadline,
  copytext,
}) => {
  return (
    <Container>
      {badge && (
        <Attribute>
          <ThemeProvider theme={{
            badgeTheme,
          }}>
            <Badge>
              {badge}
            </Badge>
          </ThemeProvider>
        </Attribute>
      )}
      <Wrapper noGrid>
        <Grid>
          <Content>
            <MetaData
              date={date}
              category={category}
            />
            <Headline>
              {headline}
            </Headline>
            <Subheadline className="textGrey">{subheadline}</Subheadline>
            {copytext && <Rte level={contextLevelTypes.inside}>{copytext}</Rte>}
          </Content>
        </Grid>
      </Wrapper>
    </Container>
  )

};

ArticleHeaderFlat.propTypes = {
  boxTheme: PropTypes.string,
  image: PropTypes.string,
  category: PropTypes.string,
  date: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  copytext: PropTypes.string.isRequired,
};