import React from 'react';
import styled from 'styled-components/macro';
import { useMediaQuery } from 'react-responsive';

import { Grid, Wrapper, Col } from '#ui';
import { bp, gridCols } from '#theme';
import { TeaserOld, teaserThemes } from '../Teaser/TeaserOld';
import { teaserGradient } from '../Teaser/TeaserOld';

const teaserListThemes = {
  [teaserThemes.image]: {
    width: {
      m: 12,
      p: 4,
    },
  },
  [teaserThemes.fullImage]: {
    width: {
      m: 12,
      p: 4,
    },
  },
  [teaserThemes.text]: {
    width: {
      m: 12,
      p: 4,
    },
  },
  [teaserThemes.combo]: {
    width: {
      m: 6,
      p: 3,
    },
    height: {
      m: 1,
      p: 3,
    },
    widthFirst: {
      m: 12,
      p: 3,
    },
  }
};

const Container = styled(Grid)`
  ${props => props.theme === teaserThemes.combo && teaserGradient}
`;

const ContainerWrapper = styled(Wrapper)`
  ${({ theme }) => theme === teaserThemes.combo && `
    @media ${bp.m} {
      padding-bottom: 24px;
    }
  `}
`;

const getThemeWidth = ({
  isPhabletPortrait,
  isPhablet,
  theme,
  isFirst = false,
}) => {
  return isFirst
    ? teaserListThemes[theme].widthFirst[isPhablet ? 'p' : isPhabletPortrait && 'm'] || 4
    : teaserListThemes[theme].width[isPhablet ? 'p' : isPhabletPortrait && 'm'] || 4;
}

export const TeaserListOld = ({ cols = 3, list, theme = teaserThemes.image, }) => {
  const isPhabletPortrait = useMediaQuery({ query: bp.m });
  const isPhablet = useMediaQuery({ query: bp.p });
  const themeWidth = getThemeWidth({
    isPhabletPortrait,
    isPhablet,
    theme,
  });
  const isThemeCombo = theme === teaserThemes.combo;
  const content = (
    <Container theme={theme}>
      {list.map((post, key) => {
        const isFirst= key === 0;
        const height = isThemeCombo && isPhablet && isFirst ? 2 : 1;
        const width = isThemeCombo
          ? getThemeWidth({
              isPhabletPortrait,
              isPhablet,
              theme,
              isFirst
            })
          : themeWidth
        return (
          <Col
            key={key}
            width={!isPhablet ? width : gridCols / cols}
            height={height}
          >
            <TeaserOld {...post} key={key} theme={theme} isFirst={isFirst} />
          </Col>
        )
      })}
    </Container>
  );
  return !isPhabletPortrait
    ? content
    : (
      <ContainerWrapper theme={theme}>
        {content}
      </ContainerWrapper>
    )
};