import React from 'react';
// import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import { posts, site, user } from '#data';
import { PostList } from '#components';
import { Body, Page } from '#ui';
import { themeKeys, contextThemeTypes, ThemeProvider } from '#helpers';

export const Timeline = () => {
  return (
    <Page>
      <ThemeProvider
        theme={{
          [themeKeys.bg]: contextThemeTypes.lightestGrey
        }}
      >
        <Body>
          <PostList {...{ posts, site, user }}  />
        </Body>
      </ThemeProvider>
      {/* <Widgets /> */}
    </Page>
  );
};

Timeline.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
}
