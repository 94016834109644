import React from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import { color } from "#theme";
import { contextThemeTypes } from '#helpers';

const Container = styled.div`
  font-size: 0.875rem;
`;

const Category = styled.span`
  font-weight: bold;
  color: ${color.red};
  ${theme('bg', {
    [contextThemeTypes.image]: css`
      color: ${color.white};
    `,
    [contextThemeTypes.red]: css`
      color: ${color.white};
    `,
  })}
`;

const Date = styled.span`
  &:not(:last-child)::after {
    content: "|";
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  ${theme('bg', {
    [contextThemeTypes.image]: css`
      color: ${color.white};
    `,
    [contextThemeTypes.red]: css`
      color: ${color.white};
    `,
  })}
`;

export const Meta = ({
  className,
  date,
  category,
}) => (
  <Container className={className}>
    {date && <Date>{date}</Date>}
    {category && (
      <Category>
        {category}
      </Category>
    )}
  </Container>
);