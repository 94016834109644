import React, { useEffect, useState } from 'react';

import styled from 'styled-components/macro';
import { fadeIn, fadeOut } from '#theme';

const Container = styled.div`
  ${({ isMount }) => isMount ? fadeIn : fadeOut}
`;
export const Page = ({ children }) => {
  const [isMount, setMount] = useState(false);
  useEffect(() => {
    if (!isMount) {
      setMount(true);
    }
  }, [ isMount ]);
  return (
    <Container isMount={isMount}>
      {children}
    </Container>
  )
};