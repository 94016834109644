import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { default as ReactSelect } from 'react-select';
import { useMediaQuery } from 'react-responsive';

import { color, defTransition, bp, SvgArrow } from '#theme';
import { rgba } from 'polished';

const Container = styled.div`
  position: relative;
`;
const StyledSelectContainer = styled.div`
  position: relative;
  background-color: transparent;
  width: 100%;
  height: 32px;
  font-size: 1rem;
  z-index: 3;
  @media ${bp.m} {
    height: 40px;
  }
`;
const SelectContainer = ({ children, innerRef, innerProps }) => (
  <StyledSelectContainer ref={innerRef} {...innerProps}>{children}</StyledSelectContainer>
);

const StyledControl = styled.div`
  background-color: ${({ menuIsOpen }) => menuIsOpen ? color.white : 'transparent'};
  border: 2px solid ${color.white};
  cursor: pointer;
  height: 32px;
  position: relative;
  border-radius: 4px;
  display: flex;
  transition: background-color ${defTransition};
  &:hover {
    background-color: ${color.white};
  }
  @media ${bp.m} {
    height: 40px;
  }
`;
const Control = ({ children, menuIsOpen, innerProps }) => (
  <StyledControl menuIsOpen={menuIsOpen} {...innerProps}>{children}</StyledControl>
);

const IndicatorSeparator = () => null;

// const StyledPlaceholder = styled(components.Placeholder)`
//   color: ${color.black} !important;
// `;
// const Placeholder = ({ children, innerProps }) => (
//   <StyledMenu {...innerProps}>{children}</StyledMenu>
// );

const StyledMenu = styled.div`
  box-shadow: 0px 24px 48px ${rgba(color.black, 0.5)};
  overflow: hidden;
  margin-top: 6px;
  border-radius: 4px;
  background-color: ${color.white};
`;

const Menu = ({ children, innerProps }) => (
  <StyledMenu {...innerProps}>{children}</StyledMenu>
);

const MenuList = ({ children, innerProps }) => (
  <div {...innerProps}>{children}</div>
);

const StyledOption = styled.div`
  line-height: 1.2;
  color: ${({ isSelected }) => isSelected ? color.red : color.textGrey};
  transition: background-color ${defTransition}, color ${defTransition};
  cursor: pointer;
  padding: 5px 10px;
  font-size: 0.75rem;
  @media ${bp.m} {
    font-size: 1rem;
    padding: 8px 12px;
  }
  &:hover {
    background-color: ${color.lightestGrey};
    color: ${color.red};
  }
`;
const Option = ({ children, innerProps, isSelected }) => (
  <StyledOption isSelected={isSelected} {...innerProps}>{children}</StyledOption>
);

const StyledValueContainer = styled.div`
  flex: 1 0 0%;
  padding: 4px 8px;
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  @media ${bp.m} {
    font-size: 1rem;
    padding: 8px 12px;
  }
`;
const ValueContainer = ({ children }) => (
  <StyledValueContainer>{children}</StyledValueContainer>
);

const SingleValue = ({ children }) => (
  <span>{children}</span>
);

const Arrow = styled(SvgArrow)`
  fill: ${color.red};
  width: 100%;
  height: 100%;
  transform: rotate(90deg);
`;
const IndicatorWrapper = styled.div`
  margin-right: 8px;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  display: none;
  @media ${bp.m} {
    display: flex;
  }
`;

const NativeSelect = styled.select`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  cursor: pointer;
`;
const NativeOption = styled.option`
`;
NativeOption.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
}

const DropdownIndicator = ({ innerRef, innerProps }) => (
  <IndicatorWrapper ref={innerRef} {...innerProps}>
    <Arrow />
  </IndicatorWrapper>
);

export const Select = ({
  list,
  defaultValue: defValue,
  mediaQueryForNative
}) => {
  const components = {
    SelectContainer,
    Option,
    Control,
    IndicatorSeparator,
    ValueContainer,
    Menu,
    MenuList,
    DropdownIndicator,
    SingleValue,
  };
  const defaultValue = defValue || list[0];
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const showNativeSelect = useMediaQuery({ query: mediaQueryForNative });
  const handleChange = (selected) => setSelectedOption(selected);
  const handleNativeChange = ({ text, value }) => {
    return handleChange({
      value,
      label: text,
    })
  };
  return (
    <Container>
      <ReactSelect
        options={list}
        components={components}
        defaultValue={list[0]}
        backspaceRemovesValue={false}
        isSearchable={false}
        onChange={handleChange}
        value={selectedOption}
        // menuIsOpen
      />
      {showNativeSelect && (
        <NativeSelect onChange={(e) => handleNativeChange(e.target.selectedOptions[0])} value={selectedOption.value}>
          {list.map(({ label, value }, key) => (
            <NativeOption value={value} key={key}>{label}</NativeOption>
          ))}
        </NativeSelect>
      )}
    </Container>
  );
};

Select.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(NativeOption.propTypes)).isRequired,
  defaultValue: PropTypes.string,
  mediaQueryForNative: PropTypes.string,
};